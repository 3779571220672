import React, { Component } from 'react'
import { connect } from 'react-redux'
import sndRingtone from '../../sounds/ringtone.mp3'
import sndNotification from '../../sounds/notification.mp3'
import mapStateToProps from '../../states/audio-manager'
import {publicUrl} from '../../configuration'

class AudioManager extends Component {

  state = {
    ringtone: null,
    notification: null
  }

  componentDidMount() {
    const ringtone = document.getElementById('ringtone')
    const notification = document.getElementById('notification')
    this.setState({ringtone, notification})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.playNotification) {
      this.state.notification.play()
    }
    if (this.props.playRingtone) {
      this.state.ringtone.play()
    } else {
      this.state.ringtone.pause()
      this.state.ringtone.currentTime = 0
    }
  }


  render = () => (
    <>
      <audio id='ringtone' src={publicUrl + sndRingtone} loop/>
      <audio id='notification' src={publicUrl + sndNotification}/>
    </>
  )

}

export default connect(mapStateToProps)(AudioManager)
