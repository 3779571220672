import FormData from 'form-data'
import axios from 'axios'
import { attachments } from '../configuration'

/**
 * Creates header object for axios syntax
 * @param token
 * @returns {{headers: {Authorization: string}}}
 */
const makeHeader = (token) => {
  return { headers: { Authorization: "Bearer " + token } }
}

export { makeHeader }

/**
 * Asks oc-core authorization to load a file
 * @async
 * @param chatID {string}
 * @param userID {string}
 * @returns {Promise<Object> Data from the call}
 */
export const getAuthorization = async (chatID, userID) => {

  const url = `${attachments.authUrl}?chatID=${chatID}&userID=${userID}`
  try {
    const response = await axios.get(url)
    return response.data
  } catch (e) {
    console.log(e)
    return { isAuthorized: false }
  }

}

/**
 * Sends the file to oc-media, that repsonds success or error
 * @param chatID {string}
 * @param userID {string}
 * @params token {string}
 * @returns {Promise<Object> Data from the call}
 */

export const sendFile = async (chatID, userID, token) => {
  const form = new FormData()
  const attachment = document.getElementById('file-input').files[0];
  form.append('chatID', chatID)
  form.append('userID', userID)
  form.append('attachment', attachment)

  try {
    const { data } = await axios.post(attachments.apiUrl, form, makeHeader(token))
    return data
  } catch (e) {
    console.log(e)
    return e.response.data
  }


}
