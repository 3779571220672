import React from 'react'
import styled from 'styled-components'
import Draggable from 'react-draggable'
import WidgetTitleBar from '../Widget/WidgetTitleBar'
import WidgetHeader from '../Widget/WidgetHeader'
import WidgetContent from '../Widget/WidgetContent'
import { CSSTransition } from 'react-transition-group'
import '../../css/transitions.css'
import '../../css/custom.css'


const ChatLayout = ({ children, isMounted, isMinimized }) => {
  const isDraggable = window['@draggable']
  return (
    <CSSTransition
      classNames="layout"
      mountOnEnter
      unmountOnExit
      in={isMounted}
      timeout={300}
    >
      {
        isDraggable
         ? (
            <Draggable handle="#bw-drag-here" bounds="body">
              <Layout isMinimized={isMinimized}>
                <WidgetTitleBar isDraggable={isDraggable}/>
                <WidgetHeader />
                <WidgetContent>
                  {children}
                </WidgetContent>
              </Layout>
            </Draggable>
         )
         : (
            <Layout isMinimized={isMinimized}>
              <WidgetTitleBar/>
              <WidgetHeader />
              <WidgetContent>
                {children}
              </WidgetContent>
            </Layout>
         )
      }


    </CSSTransition>

  )
}


/********** region STYLE */

const Layout = styled.div`  
  position: fixed;
  z-index: 10000;
  ${props => props.theme.layout.pos.bottom && `bottom: ${props.theme.layout.pos.bottom};`};
  ${props => props.theme.layout.pos.right && `right: ${props.theme.layout.pos.right};`};
  ${props => props.theme.layout.pos.top && `top: ${props.theme.layout.pos.top};`};
  ${props => props.theme.layout.pos.left && `left: ${props.theme.layout.pos.left};`};
  line-height: 15px !important;
  border: none;
  display: ${props => props.isMinimized ? 'block' : 'flex'};
  flex-direction: column;
  justify-content: flex-start;
  ${props => props.isMinimized ? 'overflow: hidden' : ''};
  font-family: ${props => props.theme.layout.fontFamily};
  height: ${props => props.isMinimized ? '0' : props.theme.layout.height};
  width: ${props => props.theme.layout.width};
  border-radius: ${props => props.theme.layout.borderRadius};
  -webkit-box-shadow: ${props => props.theme.layout.boxShadow};
  -moz-box-shadow: ${props => props.theme.layout.boxShadow};
  box-shadow: ${props => props.theme.layout.boxShadow};
  cursor: auto;

  @media(max-width: 430px) {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 95%;
    margin: auto;
  }

  @media(max-height: 530px) {
    height: 90%;
    margin: auto;
  }
`

/********** endregion */


export default ChatLayout
