import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { addFileUploadEvent } from '../../actions/socketActions'
import { getId } from '../../reducers/chat'
import { getUserId } from '../../reducers/user'
import { setShowAttachmentBox } from '../../actions/commonActions'
import imgAttachmentConfirm from '../../images/push-pin.svg'
import imgUpload from '../../images/upload.svg'
import imgCancel from '../../images/close.svg'
import imgErrorUpload from '../../images/warning.svg'
import imgUploaded from '../../images/uploaded.svg'
import imgSpinner from '../../images/file-loader.svg'
import { publicUrl } from '../../configuration'
import { getAuthorization, sendFile } from '../../api/file-upload'
import { TranslationContext } from '../../helpers'


const mapStateToProps = state => ({
  chatID : getId(state),
  userID : getUserId(state)
})

class AttachmentBox extends Component {

  state = {
    loading: false,
    error : null,
    uploaded : false
  }

  getAttachmentName = () => {
    const attachment = document.getElementById('file-input').files[0];
    return attachment.name
  }

  sendAttachment = async () => {
    this.setState({loading: true})
    // Asks for authorization to oc-core
    const authRequest = await getAuthorization(this.props.chatID, this.props.userID)
    if (authRequest.response !== 'success') {
      this.setState({loading : false, error : 'generic'})
      return
    }
    // The file upload request is authorized
    // then uploads the file to oc-media
    const sendResult = await sendFile(this.props.chatID, this.props.userID, authRequest.token)
    if (sendResult.result === 'error') {
      // There is an error
      this.setState({loading : false, error : sendResult.message})
      setTimeout(this.removeAttachment, 10 * 1000)
      return
    }
    if (sendResult.result === 'success') {
      // File upload success
      this.setState({loading : false, uploaded : true})
      setTimeout(this.removeAttachment, 2 * 1000)
      this.props.addFileUploadEvent({
        chat : this.props.chatID,
        filepath : sendResult.filepath,
        filename : this.getAttachmentName()
      })
      return
    }
    // If the web service return something different to
    // error or success, I show a generic error
    this.setState({loading : false, error : 'generic'})
  }

  removeAttachment = () => {
    this.props.setShowAttachmentBox(false)
  }

  toRender = ({ fileUpload }) => {
    if (this.state.loading) {
      return(
        <>
          { //<Spinner name="circle" color="#4FBA6F"/>
          }
          <Spinner src={publicUrl + imgSpinner} alt='error attachment'/>
          <AttachmentName>{this.getAttachmentName()}</AttachmentName>
          <ButtonContainer>
            <ImgButtonUpload src={publicUrl + imgUpload} onClick={this.sendAttachment}/>
            <ImgButtonCancel src={publicUrl + imgCancel} onClick={this.removeAttachment}/>
          </ButtonContainer>
        </>
      )
    } else if (this.state.error) {
      let error = fileUpload[this.state.error]
      if (!error) {
        error = fileUpload.genericError
      }
      return (
        <>
          <ImgAttachment src={publicUrl + imgErrorUpload} alt='error attachment'/>
          <AttachmentName>{error}</AttachmentName>
          <ButtonContainer>
            <ImgButtonCancel src={publicUrl + imgCancel} onClick={this.removeAttachment}/>
          </ButtonContainer>
        </>
      )
    } else if (this.state.uploaded) {
      return (
        <UploadedContainer>
          <ImgAttachment src={publicUrl + imgUploaded} alt='error attachment'/>
          <UploadedMessage>{fileUpload.uploadSuccess}</UploadedMessage>
        </UploadedContainer>
      )
    } else {
      return(
        <>
          <ImgAttachment src={publicUrl + imgAttachmentConfirm} alt='confirm attachment' />
          <AttachmentName>{this.getAttachmentName()}</AttachmentName>
          <ButtonContainer>
            <ImgButtonUpload src={publicUrl + imgUpload} onClick={this.sendAttachment}/>
            <ImgButtonCancel src={publicUrl + imgCancel} onClick={this.removeAttachment}/>
          </ButtonContainer>
        </>
      )
    }
  }


  render() {
    return(
      <TranslationContext.Consumer>
        {translation =>
          <AttachmentBoxContainer>
            { this.toRender(translation) }
          </AttachmentBoxContainer>
        }
      </TranslationContext.Consumer>
    )
  }
}

/********** region STYLE */

const AttachmentBoxContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0px 15px;
  background: #133553;
  margin: 10px 10px -15px 10px;
  border-radius: 5px;
`

const UploadedContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const AttachmentName = styled.div`
  font-size: 0.8rem;
  opacity: 0.8;
  color: #FFFFFF;
  display: inline-block;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden; 
`

const UploadedMessage = styled.div`
  font-size: 0.8rem;
  opacity: 0.8;
  color: #FFFFFF;
  display: inline-block;
  white-space: nowrap;
  width: 250px;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden; 
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImgAttachment = styled.img`
  width: 25px;
`

const Spinner = styled.img`
  width: 25px;
`

const ImgButtonUpload = styled.img`
  width: 25px;
  cursor: pointer;
`

const ImgButtonCancel = styled.img`
  cursor: pointer;
  margin-left: 5px;
  width: 25px;
`


/********** endregion */

export default connect(mapStateToProps, {
  setShowAttachmentBox,
  addFileUploadEvent
})(AttachmentBox)
