import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getOpeningTimes, getShowEmailForm, getIsClosedDueToExtra } from '../../../reducers'
import { leaveMessage } from '../../../actions/socketActions'
import OpeningTimes from './OpeningTimes'
import EmailForm from './EmailForm'


const mapStateToProps = state => ({
  openingTimes : getOpeningTimes(state),
  showEmailFormButton : getShowEmailForm(state),
  isClosedDueToExtra : getIsClosedDueToExtra(state)
})

class DepartmentClosed extends Component {

  state = {
    showEmailForm : false
  }

  componentDidMount() {
    // TODO: Se non sono presenti opening time, mostra la form
  }

  showEmailForm = () => {
    this.setState({showEmailForm : true})
  }

  sendEmail = (subject, body) => {
    if (subject.length === 0 || body.length === 0) return
    this.props.leaveMessage(subject, body)
  }

  toRender = () => {
    if (this.state.showEmailForm) {
      return(
        <EmailForm
          sendEmail={this.sendEmail}
        />
      )
    }
    return (
      <OpeningTimes
        openingTimes={this.props.openingTimes}
        showEmailForm={this.showEmailForm}
        showEmailFormButton={this.props.showEmailFormButton}
        isClosedDueToExtra={this.props.isClosedDueToExtra}
      />
    )
  }

  render() {
    return(
      this.toRender()
    )
  }
}

export default connect(mapStateToProps, { leaveMessage })(DepartmentClosed)

