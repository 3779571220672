import React from 'react'
import {publicUrl} from '../../configuration'
import imgError from '../../images/transcriptionSent.svg'
import styled from 'styled-components'

const TranscriptionPageError = () => (
  <>
    <img src={publicUrl + imgError} alt="transcription error"/>
    <Text>Purtroppo non é stato possibile generare e inviare la trascrizione.</Text>
  </>
)

export default TranscriptionPageError

// region STYLE

const Text = styled.div`
  margin-top: 10px;
  font-size: 1.05rem;
  opacity: 0.9;
`

//endregion
