import * as at from '../actions/types'
import { chatRequest, departmentInfo, departmentTransfer } from '../actions/socketActions'
import { getSessionUser, getUserId, getEvents, getJanusInfo, getId, getPage } from '../reducers'
import { handleOwnEvent, clearlocalStorage, filllocalStorage, clearForCorruptedResumedChat } from './helpers'
import { addEvent, setOperatorWriting, setPage, setChatStatus } from '../actions/commonActions'
import { janus, turn } from '../configuration'
import Janus from '../get-janus'
import {CHAT, FEEDBACK, PRIVACY, TRANSCRIPTION, DEPARTMENT_LIST} from '../constants/pages'
import { OPENED } from '../constants/statuses'
import { MEDIA_CALL_REQUEST, MEDIA_SCREEN_SHARE_REQUEST, MEDIA_VIDEO_REQUEST } from '../constants/chatStatuses'
import {chatStatuses, pages, statuses} from '../constants'


/********** SOCKET HANDLERS */

export const handleInitSocket = (dispatch, getState, socket, client_info) => {
  // Event listeners
  socket.on('connect', () => {
    // Retrieve the session storage information if they're present
    // to check if it's a resumed chat
    const localUserID = localStorage.getItem('userID')
    const localChatID = localStorage.getItem('chatID')
    const proactiveChatStarted = localStorage.getItem('proactiveChatStarted')
    const chatResumed = localUserID && localChatID
    // Create the payload
    const payload = {
      client_info,
      ...getSessionUser(getState())
    }
    if (chatResumed) {
      // If the chat is resumed, add to payload some informations
      payload.ID = localUserID
      payload.chat = localChatID
      if (proactiveChatStarted && proactiveChatStarted === 'true') {
        dispatch({type: at.SET_PROACTIVE_CHAT_STARTED, data: true})
      }
    }
    // Emit the customer Online

    socket.emit('chat:customer-online', payload, async res => {
      await handleCustomerOnlineResponse(dispatch, client_info.department, chatResumed, localChatID, res)
    })

  })
  socket.on('chat:event', data => {
    handleChatEvents(data, dispatch, getState)
  })
  socket.on('chat:sneakpeek', data => {
    handleChatSneakpeek(data, dispatch)
  })
  socket.on('chat:request-media', data => {
    handleRequestMedia(data, dispatch)
  })
  socket.on('chat:operator-transfer-complete', data => {
    handleOperatorTranfer(data, dispatch)
  })
  socket.on('chat:start-chat', data => {
    handleProactiveChat(data, dispatch, getState)
  })
}

/********** region EVENTS HANDLERS */

const handleChatEvents = (data, dispatch, getState) => {
  if (data.event.type === 'message') {
    handleChatEventTypeMessage(data.event, dispatch, getState)
  } else if (data.event.type === 'system') {
    handleChatEventTypeSystem(data.event, dispatch, getState)
  } else if (data.event.type === 'link') {
    handleChatEventTypeLink(data.event, dispatch)
  }
}

const handleChatEventTypeMessage = (event, dispatch, getState) => {
  if (event.user.ID === getUserId(getState())) {
    // If it's mine
    const events = handleOwnEvent(getEvents(getState()), event.tempID, event.timestamp)
    dispatch({type: at.ADD_EVENTS, data: events})
  } else {
    // If it's a operator's message
    dispatch({type: at.ADD_EVENT, data: event})
  }
}

const handleChatEventTypeSystem = (event, dispatch, getState) => {
  // If it's a operator join
  if (event.subtype === 'operator joined') {
    dispatch({type: at.OPERATOR_JOINED, data: event.user})
  } // If it's a chat transfer
  else if (event.subtype === 'department transfer') {
    dispatch(departmentTransfer(event.department))
  } // If operator has closed the chat
  else if (event.subtype === 'operator transfer complete') {
    dispatch({type: at.SET_CURRENT_OPERATOR, data: event.user})
  }
  else if (event.subtype === 'operator close' || event.subtype === 'system close') {
    handleCloseChatRequestOperator(dispatch, getState, event)
  } // If operator has closed media stream
  else if (event.subtype === 'media stream closed') {
    dispatch({type: at.CLOSE_MEDIA_REQUEST})
  }
  dispatch({type: at.ADD_EVENT, data: event})
}

const handleChatEventTypeLink = (event, dispatch) => {
  dispatch({type: at.ADD_EVENT, data: event})
}

export const handleAddEvent = (socket, payload, event, dispatch) => {
  dispatch(addEvent(event))
  socket.emit('chat:message', payload)
}

export const handleAddFileUploadEvent = (socket, payload, event, dispatch) => {
  socket.emit('chat:file-upload', payload)
}

const handleOperatorTranfer = (data, dispatch) => {
  if (!data || data.qualcosa) {

  }
}

//endregion

/********** region CHATS HANDLERS */

const handleCustomerOnlineResponse = async (dispatch, sessionDepartment, chatResumed, localChatID, res) => {
  // Mandatory fields
  if (!res.ID || !res.departments) {
    dispatch({type: at.SET_SHOW_ERROR_VIEW, data: true})
    return
  }
  dispatch({type: at.CUSTOMER_ONLINE_RESPONSE_RECEIVED, data: {
      departments: res.departments,
      userID: res.ID
    }})
  localStorage.setItem('userID', res.ID)
  // If is a resumed chat
  if (chatResumed && res.chat) {
    const events = res.chat.events.map(event => {
      event.received = true
      return event
    })
    // Set as department the last one from the departments array
    const department = res.chat.departments[res.chat.departments.length - 1]

    dispatch({type: at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED, data: {
        chatID: localChatID,
        operators: res.chat.operators,
        currentOperator: res.chat.operator,
        events,
        department
      }})
    return
  } else {
    if (chatResumed && !res.chat) {
      dispatch({ type: at.SET_PAGE, data: pages.DEFAULT })
      dispatch({ type: at.SET_STATUS, data: statuses.CIRCLE })
      dispatch({ type: at.SET_CHAT_STATUS, data: chatStatuses.DEFAULT })
      dispatch({ type: at.UPDATE_SESSION_USER, data: window['@user']})
      clearForCorruptedResumedChat()
    }
    // If it isn't a resumed chat
    if (sessionDepartment) {
      // If there is the department specified in the page
      // and I don't have the chatID and the userID (chat not resumed)
      dispatch(departmentInfo(sessionDepartment))
    }
    // Otherwise, I wait for the department choice to launch departmentInfo
  }
  // Init Janus
  await initJanus()
}

export const handleChatRequest = (socket, payload, dispatch) => {
  socket.emit('chat:request-chat', payload, (res) => {
    if (res.error) {
      // TODO: Error
      _handleError(dispatch, res.message || 'Error')
      return
    }

    const data = { department: res.department, chat: res.chat }

    dispatch({ type: at.HANDLE_CHAT_REQUEST, data })
    filllocalStorage(res.department.ID, res.department.Name, res.chat)
  })
}

export const handleDepartmentInfo = (socket, page, payload, dispatch) => {
  socket.emit('chat:department-info', payload, res => {
    if (res.error) {
      // The department are closed
      const openings = res.openings || null
      page === DEPARTMENT_LIST && dispatch({type: at.DEPARTMENT_CLOSED, data: {openings, isClosedDueToExtra : !!res.isClosedDueToExtra}})
      if (res.form) {
        // The department are close but there's the possibility
        // to send an email
        dispatch({type: at.SET_DEPARTMENT, data: res.department.ID})
        dispatch({type: at.SET_SHOW_EMAIL_FORM, data: true})
      }
      return
    }
    localStorage.setItem('departmentID', res.department.ID)
    if (page === DEPARTMENT_LIST && !res.department.PrivacyMessage) {
      // If there isn't a privacy message to show,
      // go ahead with the chatRequest on the socket
      dispatch(chatRequest(res.department.ID))
      return
    }
    // There are no errors and there is a privacy message to show
    dispatch({type: at.SET_DEPARTMENT_PRIVACY_MESSAGE, data: res.department.PrivacyMessage})
    dispatch({type: at.SET_DEPARTMENT, data: res.department.ID})
    // Go to PRIVACY page only if departmentInfo was called
    // from DEPARTMENT_LIST page, or it could skip something
    page === DEPARTMENT_LIST && dispatch(setPage(PRIVACY))
  })
}

export const handleCloseChatRequestOperator = (dispatch, getState, event) => {
  dispatch({ type: at.CLOSE_CHAT_REQUEST})
  localStorage.setItem('closed', 'true')
  // If there is a survey
  if (event.survey) {
    dispatch({ type: at.SET_FEEDBACK, data: event.survey })
    dispatch({ type: at.SET_PAGE, data: FEEDBACK })
  } else {
    getPage(getState()) === TRANSCRIPTION
      ? dispatch({ type: at.CLOSE_CHAT, data: window['@user']})
      : dispatch({ type: at.SET_PAGE, data: TRANSCRIPTION })
    clearlocalStorage()
  }

}

export const handleCloseChatRequestCustomer = (dispatch, getState, socket) => {
  // If there is an active chat
  if (getId(getState())) {
    try {
      socket.emit('chat:close', res => {
        handleCloseChatRequestOperator(dispatch, getState, res)
      })
    } catch (e) {
      console.log('Socket error')
      console.log(e)
    }
    return
  }
  //If I was in the feedback page, go to trascription page
  if (getPage(getState()) === FEEDBACK) {
    dispatch({type: at.SET_SHOW_TRANSCRIPTION_PAGE, data: true})
    dispatch({type: at.SET_FEEDBACK, data: null})
    dispatch(setPage(TRANSCRIPTION))
    return
  }

  dispatch({ type: at.CLOSE_CHAT, data: window['@user']})
  clearlocalStorage()

}

export const handleLeaveMessage = (dispatch, socket, email) => {
  socket.emit('chat:leave-message', email)
  dispatch({type : at.CLOSE_CHAT_REQUEST})
  dispatch({type : at.CLOSE_CHAT, data : window['@user']})
}

const handleProactiveChat = (data, dispatch, getState) => {
  dispatch({type: at.SET_DEPARTMENT, data: data.department.ID})
  dispatch({type: at.SET_DEPARTMENT_NAME, data: data.department.Name})
  dispatch({type: at.SET_CHAT_ID, data: data.chat.ID})
  dispatch({type: at.ADD_OPERATOR, data: data.chat.operator})
  dispatch({type: at.SET_CURRENT_OPERATOR, data: data.chat.operator})
  dispatch({type : at.SET_PROACTIVE_CHAT_STARTED, data: true})
  dispatch({type : at.SET_IS_MINIMIZED, data: false})
  dispatch({type : at.SET_SHOW_INTRO_WIDGET, data: false})
  dispatch({type : at.SET_SHOW_FULL_WIDGET, data: true})
  dispatch({type : at.SET_PAGE, data: CHAT})
  dispatch({type : at.SET_STATUS, data: OPENED})
  localStorage.setItem('proactiveChatStarted', 'true')
  filllocalStorage(data.department.ID, data.department.Name, data.chat.ID)
}

//endregion

/********** region SNEAKPEEK HANDLERS */

const handleChatSneakpeek = (data, dispatch) => {
  if (data.operators.length === 0) {
    dispatch(setOperatorWriting(null))
  } else {
    const last = data.operators.length - 1
    dispatch(setOperatorWriting(data.operators[last] ? data.operators[last].Name : ''))
  }
}

export const handleSendSneakPeek = (socket, textSneakPeek) => {
  const payload = {text : textSneakPeek}
  socket.emit('chat:sneakpeek', payload)
}

//endregion

//region SENDING HANDLER

export const handleTranscriptionRequest = (socket, chat, dispatch) => {
  socket.emit('chat:request-transcription', { chat }, ack => {
    if (ack) {
      dispatch({type: at.TRANSCRIPTION_PAGE_REQUEST_SUCCESS})
    } else {
      dispatch({type: at.TRANSCRIPTION_PAGE_REQUEST_FAIL})
    }
  })
}

export const handleSendSurvey = (dispatch, socket, survey) => {
  dispatch({type: at.SET_FEEDBACK, data: null})
  dispatch(setPage(TRANSCRIPTION))
  socket.emit('chat:survey', survey)
}

//endregion

/********** region MEDIA HANDLERS */

const _sleep = secs => new Promise( resolve => setTimeout(() => resolve(), secs * 1000))

const initJanus = async () => {
  try {
    await Janus.init(janus.host, turn, true)
  } catch (e) {
    console.log("Janus Init Failed")
    console.log(e)
  }
}

const handleRequestMedia = (data, dispatch) => {

  if (!data) {
    console.log("Request payload empty")
    return
  }
  dispatch({type : at.SET_JANUS_INFO, data})
  switch (data.type) {
    case 'audio' :
      dispatch(setChatStatus(MEDIA_CALL_REQUEST))
      break
    case 'video' :
      dispatch(setChatStatus(MEDIA_VIDEO_REQUEST))
      break
    case 'cobrowse' :
      dispatch(setChatStatus(MEDIA_SCREEN_SHARE_REQUEST))
      break
    default :
      console.log("Request type unknown")
  }
}

export const handleRequestMediaAccept = async (socket, dispatch, getState, accepted, type) => {

  if (!accepted) {
    socket.emit('chat:request-media-response', {response : false})
    return
  }

  // Get Video Room
  let publisherSession
  try {
    publisherSession = await Janus.getVideoroom()
  } catch (e) {
    console.log("Janus GetVideoRoom Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

  if (type === 'cobrowse' && !Janus.isExtensionEnabled()) {
    console.log("ESTENSIONE COBROWSE NON ABILITATO")
    dispatch({type : at.END_MEDIA_CHAT})
    socket.emit('chat:request-media-response', {response : false})
    alert(`
      Estensione screensharing non abilitata.\n\n
      Chrome necessita di un'estensione per abilitare la funzione di screensharing.\n
      Puoi installarla facilmente seguendo questo link:\n
      https://chrome.google.com/webstore/detail/janus-webrtc-screensharin/hapfgfdkleiggjjpfpenajgdnfckjpaj
     `)
    return
  }

  dispatch({type : at.SET_JANUS_INFO, data : {publisherSession}})

  // Event Listeners
  publisherSession.on('joined', async (data) => {
    janusEventJoined(dispatch, getState, publisherSession, data.private_id, data.publishers, type)
  })
  publisherSession.on('localstream', stream => {
    janusEventLocalStream(dispatch, stream, type)
  })
  publisherSession.on('publishers', publishers => {
    janusEventPublishers(dispatch, getState, type, publishers)
  })
  publisherSession.on('leaved', publisherId => {
    janusEventLeaved(socket, dispatch, getState, publisherId)
  })

  // Publisher Join
  const janusInfo = getJanusInfo(getState())
  const user = getSessionUser(getState())
  const payload = {
    room : janusInfo.room,
    pin : janusInfo.pin,
    id : janusInfo.customerID,
    displayName : `${user.name} ${user.surname}`
  }
  try {
    await publisherSession.join('publisher', payload)
  } catch (e) {
    console.log("Janus Join Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

  socket.emit('chat:request-media-response', {
    response : true,
    room : janusInfo.room,
    id : janusInfo.customerID,
    type
  })
}

const janusEventJoined = async (dispatch, getState, publisherSession, private_id, publishers, type) => {

  dispatch({type: at.SET_JANUS_INFO, data: {private_id}})

  try {
    if (type === 'video' || type === 'audio') {
      await publisherSession.sendStream({
        audio: true,
        video: type === 'video'
      })
    } else if (type === 'cobrowse') {
      console.log("JOINED COBROWSE")
      await publisherSession.sendCapture({
        video : 'screen'
      })
    }
    publishers.forEach(async publisher => {
      const janusInfo = getJanusInfo(getState())
      await createSubscriberSession(publisher, janusInfo, dispatch, type)
    })
  } catch (e) {
    console.log("Janus Event Joined Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

  try {
    await publisherSession.startRecording()
  } catch (e) {
    console.log("Janus Start Recording Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }
}

const janusEventLocalStream = (dispatch, stream, type) => {
  try {
    if (type === 'video') {
      Janus.attachMediaStream(document.getElementById('video-customer'), stream)
    }
  } catch (e) {
    console.log("Janus Attach Media Stream Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }
}

const janusEventPublishers = async (dispatch, getState, type, publishers) => {
  publishers.forEach(async publisher => {
    const janusInfo = getJanusInfo(getState())
    await createSubscriberSession(publisher, janusInfo, dispatch, type)
  })
}

const janusEventLeaved = async (socket, dispatch, getState, publisherId) => {
  destroySubscriberSession(socket, dispatch, getState, publisherId)
}

const createSubscriberSession = async (publisher, janusInfo, dispatch, type) => {

  if (!janusInfo.subscriberSessions) {
    janusInfo.subscriberSessions = {}
  }

  if (janusInfo.subscriberSessions[publisher.id]) {
    // If the publisher already exist
    return
  }

  let subscriberSession
  try {
    subscriberSession = await Janus.getVideoroom()
  } catch (e) {
    console.log("Janus Get Video Room Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

  janusInfo.subscriberSessions[publisher.id] = subscriberSession
  dispatch({type : at.SET_JANUS_INFO, data : janusInfo})

  subscriberSession.on('remotestream', stream => {
    try {
      let domElement
      if (type === 'video') {
        domElement = document.getElementById('video-operator')
      }
      if (type === 'audio' || type === 'cobrowse') {
        domElement = document.getElementById('audio-operator')
      }
      Janus.attachMediaStream(domElement, stream)
    } catch (e) {
      console.log("Attach Media Stream")
      console.log(e)
      dispatch({type : at.END_MEDIA_CHAT})
      return
    }
  })

  try {
    const payload = {
      room : janusInfo.room,
      pin : janusInfo.pin,
      publisher : publisher.id,
      subscriber : janusInfo.private_id
    }
    await subscriberSession.join('subscriber', payload)
  } catch (e) {
    console.log("Subscriber Join Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

}

const destroySubscriberSession = async (socket, dispatch, getState, publisherId) => {

  const janusInfo = getJanusInfo(getState())
  const subscriberSessions = janusInfo.subscriberSessions
  delete subscriberSessions[publisherId]

  dispatch({type : at.SET_JANUS_INFO, data : {subscriberSessions}})

  if (Object.keys(subscriberSessions).length === 0) {
    await handleCloseMediaCall(socket, getState, dispatch)
  }
}

export const handleCloseMediaCall = async (socket, getState, dispatch) => {

  const janusInfo = getJanusInfo(getState())

  // Leave on the publisherSession
  try {
    janusInfo.publisherSession.removeAllListeners()
    await janusInfo.publisherSession.leave()
  } catch (e) {
    console.log("PublisherSession Leave Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

  // Leave on all the subscriberSessions
  try {
    await Promise.all(Object.values(janusInfo.subscriberSessions).map(subscriberSession => {
      subscriberSession.removeAllListeners()
      return subscriberSession.leave()
    }))
  } catch (e) {
    console.log("Subscribers Session Leave Failed")
    console.log(e)
    dispatch({type : at.END_MEDIA_CHAT})
    return
  }

  // Store clearance and set Windows
  dispatch({type : at.END_MEDIA_CHAT})
  socket.emit('chat:stop-media')
}

//endregion

/********** region HELPERS */

const _handleError = (dispatch, errorMessage) => {
  dispatch({type : at.SET_SHOW_ERROR_VIEW, data : errorMessage})
  setTimeout(() => {
    dispatch({type : at.SET_SHOW_ERROR_VIEW, data : null})
    dispatch({type : at.CLOSE_CHAT_REQUEST})
    dispatch({type : at.CLOSE_CHAT, data : window['@user']})
  }, 5 * 1000)
}

/********** endregion */
