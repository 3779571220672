import React from 'react'
import styled from 'styled-components'

const ButtonRefuse = (props) => {

  const { children, size, onClick } = props

  return(
    <ButtonRefuseStyled
      onClick={onClick}
      size={size}
    >
      {children}
    </ButtonRefuseStyled>
  )
}

/********** region ~~~~~ STYLE ~~~~~ */

const ButtonRefuseStyled = styled.div `
  padding: 10px;
  color: ${props => props.theme.mediaChat.buttonRefuseColor};
  border-radius: 4px;
  font-size: ${props => props.theme.mediaChat.buttonRefuseFontSize};
  text-align: center;
  cursor: pointer;
  background: ${props => props.theme.mediaChat.buttonRefuseBackground};
  width: ${props => props.theme.mediaChat.buttonRefuseWidth};
  font-weight: ${props => props.theme.mediaChat.buttonRefuseFontWeight};
`

/********** endregion */

export { ButtonRefuse }
