import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Line } from 'rc-progress'
import styled from 'styled-components'
import { getFeedback, getId } from '../../reducers'
import { sendSurvey } from '../../actions/socketActions'
import { setPage } from '../../actions/commonActions'
import { TRANSCRIPTION } from '../../constants/pages'
import QuestionRating5 from './QuestionRating5'
import QuestionRating10 from './QuestionRating10'
import QuestionFreeText from './QuestionFreeText'


const mapStateToProps = state => ({
  feedback : getFeedback(state),
  chatID : getId(state)
})

const Feedback = ({ feedback, chatID, sendSurvey, setPage }) => {

  const [questionIndex, setQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!feedback || feedback.questions.length === 0) {
      setPage(TRANSCRIPTION)
    } else {
      setLoading(false)
    }
  }, [])

  const nextQuestion = async (answer) => {
    // Create the answer just completed
    const newAnswer = {
      question : feedback.questions[questionIndex].ID,
      answer
    }
    if (questionIndex === feedback.questions.length - 1) {
      // The last question
      await setAnswers([...answers, newAnswer])
      surveyCompleted([...answers, newAnswer])
    } else {
      // Other questions
      setQuestionIndex(questionIndex + 1)
      setAnswers([...answers, newAnswer])
    }
  }

  const surveyCompleted = (fullAnswers) => {
    const survey = {
      chat : chatID,
      survey : feedback.ID,
      answers : fullAnswers
    }
    sendSurvey(survey)
  }

  return(
   <>
     { loading && null }
     { !loading && feedback &&
        <FeedbackContainer>
          <ProgressLabel>
            Domanda {questionIndex + 1}/{feedback.questions.length}
          </ProgressLabel>
          <Line
            percent={questionIndex / feedback.questions.length * 100}
            strokeWidth="3"
            trailWidth="3"
            strokeColor="#60C6F6"
            trailColor="#D9D9D9"
          />
          { feedback.questions[questionIndex].type.name === 'rating5' &&
          <QuestionRating5
            question={feedback.questions[questionIndex].question}
            nextQuestion={nextQuestion}
          /> }
          { feedback.questions[questionIndex].type.name === 'rating10' &&
          <QuestionRating10
            question={feedback.questions[questionIndex].question}
            nextQuestion={nextQuestion}
          /> }
          { feedback.questions[questionIndex].type.name === 'freetext' &&
          <QuestionFreeText
            question={feedback.questions[questionIndex].question}
            nextQuestion={nextQuestion}
          /> }
        </FeedbackContainer>
     }
   </>
  )
}

// region STYLE

const FeedbackContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: 20px;
`

const ProgressLabel = styled.div`
  opacity: 0.8;
  font-size: 0.9rem;
  margin: 10px 0px;
`

//endregion

export default connect(mapStateToProps, { sendSurvey, setPage })(Feedback)


