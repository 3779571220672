import React, {Component} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {getCurrentMessage} from '../../reducers/chat'

const mapStateToProps = state => ({
  message: getCurrentMessage(state)
})

class NotificationsCounter extends Component {

  state = {
    counter: 0
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.message !== this.props.message && this.props.message.type === 'message') {
      this.setState({counter: this.state.counter + 1})
    }
  }

  render = () => (
    <>
    { this.state.counter !== 0 &&
      <NotificationsCounterBox>{this.state.counter}</NotificationsCounterBox>
    }
    </>
  )


}

export default connect(mapStateToProps)(NotificationsCounter)

const NotificationsCounterBox = styled.div`
  font-family: ${props => props.theme.layout.fontFamily};
  position: fixed;
  right: 22px;
  bottom: 72px;
  background: ${props => props.theme.notifications.counter.background};
  border-radius: ${props => props.theme.notifications.counter.borderRadius};
  width: ${props => props.theme.notifications.counter.width};
  height: ${props => props.theme.notifications.counter.height};
  color: ${props => props.theme.notifications.counter.color};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 110000;
  font-size: 0.9rem;
  overflow: hidden;
`
