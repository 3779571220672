import React from 'react'
import styled, {keyframes, css} from 'styled-components'
import {delayUnmounting} from '../helpers'
import {TranslationContext} from '../helpers'


const IntroWidget = ({ isMounted, onClick }) => {
  return (
    <TranslationContext.Consumer>
      { translation =>
        <BarWidgetStyled isMounted={isMounted} onClick={onClick}>
          <TextContainer>
            <TextUpper>{translation.introWidget.helperTop}</TextUpper>
            <TextBottom>{translation.introWidget.helperBottom}</TextBottom>
          </TextContainer>
          <ClickHere>
            {translation.introWidget.clickHere}
          </ClickHere>
        </BarWidgetStyled>
      }
    </TranslationContext.Consumer>
  )
}

// region STYLE

const mount = keyframes`
  0% {
    opacity: 0;
    transform: scaleX(0);
    transform: translateX(382px);
  }
  20% {
    transform: scaleX(1);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`

const unmount = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0) ;
  }
  100% {
    opacity: 0;
    transform: translateX(382px);
  }
`

const BarWidgetStyled = styled.div`
  ${props => props.isMounted ? css`animation: ${mount} 1s` : css`animation: ${unmount} 0.4s`};
  z-index: 99000;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  font-family: ${props => props.theme.layout.fontFamily};
  bottom: ${props => props.theme.barWidget.pos.bottom};
  right: ${props => props.theme.barWidget.pos.right};
  width: ${props => props.theme.barWidget.width};
  height: ${props => props.theme.barWidget.height};
  border-radius:  ${props => props.theme.barWidget.borderRadius};
  background: ${props => props.theme.barWidget.background};
  padding: ${props => props.theme.barWidget.padding};
  -webkit-box-shadow: ${props => props.theme.barWidget.boxShadow};
  -moz-box-shadow: ${props => props.theme.barWidget.boxShadow};
  box-shadow: ${props => props.theme.barWidget.boxShadow};
  ${props => props.theme.barWidget.customCss ? props.theme.barWidget.customCss : ''};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  margin-left: ${props => props.theme.barWidget.textContainer.marginLeft};
`

const TextUpper = styled.div`
  font-size: ${props => props.theme.barWidget.textContainer.textUpper.fontSize};
  color: ${props => props.theme.barWidget.textContainer.textUpper.color};
`

const TextBottom = styled.div`
  font-size: ${props => props.theme.barWidget.textContainer.textBottom.fontSize};
  font-weight: ${props => props.theme.barWidget.textContainer.textBottom.fontWeight};
  color: ${props => props.theme.barWidget.textContainer.textBottom.color};
`

const ClickHere = styled.div`
  text-transform: uppercase;
  background: ${props => props.theme.barWidget.clickHereButton.background};
  color: ${props => props.theme.barWidget.clickHereButton.color};
  padding: ${props => props.theme.barWidget.clickHereButton.padding};
  border-radius: ${props => props.theme.barWidget.clickHereButton.borderRadius};
  font-size: ${props => props.theme.barWidget.clickHereButton.fontSize};
  margin-left: ${props => props.theme.barWidget.clickHereButton.marginLeft};
  font-weight: ${props => props.theme.barWidget.clickHereButton.fontWeight};

`

// endregion

export default delayUnmounting(IntroWidget)