import React, { Component } from 'react'
import styled from 'styled-components'
import { Input } from '../../common'

class EmailForm extends Component {

  state = {
    subject : '',
    body : ''
  }

  render() {
    return(
      <EmailFormContainer>
        <Title>Lascia un messaggio</Title>
        <FormContainer>
          <Input
            placeholder='Oggetto'
            value={this.state.subject}
            onChange={(e) => this.setState({subject : e.target.value})}
            width='100%'
          />
          <BodyMessage
            placeholder='Messaggio'
            value={this.state.body}
            onChange={(e) => this.setState({body : e.target.value})}
          />
        </FormContainer>
        <Submit disabled={this.state.subject.length === 0 || this.state.body.length === 0} onClick={() => this.props.sendEmail(this.state.subject, this.state.body)}>Invia</Submit>
      </EmailFormContainer>
    )
  }
}

/********** region STYLE */

const EmailFormContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Title = styled.div`
  margin-top: 20px;
  padding: 12px;
  background: #133553;
  align-self: center;
  width: 90%;
  box-sizing: border-box;
  border-radius: 5px;
  color: #FFFFFF;
  font-size: 0.80rem;
  opacity: 0.98;
`

const FormContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`

const BodyMessage = styled.textarea`
  border: 1px solid #BBBBBB;
  height: 200px;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  font-family: 'Open Sans',sans-serif !important;
  opacity: 0.9;
  resize: none;
`

const Submit = styled.div`
  width: 100px;
  height: 25px;
  background: ${ props => props.disabled ? '#b4b4b4' : '#0173c7'};
  color: #FFFFFF;
  font-size: 0.85rem;
  padding: 6px;
  text-align: center;
  line-height: 25px;
  margin-top: 15px;
  border-radius: 5px;
  cursor: ${ props => props.disabled ? 'not-allowed' : 'pointer'};  
`

/********** endregion */

export default EmailForm
