export const INITIALIZE = 'INITIALIZE'

// Session actions
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const UPDATE_SESSION_USER = 'UPDATE_SESSION_USER'
export const UPDATE_SESSION_DEPARTMENT = 'UPDATE_SESSION_DEPARTMENT'
export const UPDATE_SESSION_MANDATORY = 'UPDATE_SESSION_MANDATORY'

// Chat actions
export const SET_DEPARTMENT = 'SET_DEPARTMENT'
export const SET_DEPARTMENT_NAME = 'SET_DEPARTMENT_NAME'
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
export const SET_USER_ID = 'SET_USER_ID'
export const SET_CHAT_ID = 'SET_CHAT_ID'
export const SET_SOCKET= 'SET_SOCKET'
export const SET_LOADING = 'SET_LOADING'
export const ADD_EVENT = 'ADD_EVENT'
export const ADD_OPERATOR = 'ADD_OPERATOR'
export const SET_CURRENT_OPERATOR = 'SET_CURRENT_OPERATOR'
export const ADD_EVENTS = 'ADD_EVENTS'
export const SET_OPERATORS = 'SET_OPERATORS'
export const CLOSE_CHAT_REQUEST = 'CLOSE_CHAT_REQUEST'
export const CLOSE_CHAT = 'CLOSE_CHAT'
export const SET_OPERATOR_WRITING = 'SET_OPERATOR_WRITING'
export const SET_WEB_RTC_PEER = 'SET_WEB_RTC_PEER'
export const SET_JANUS_INFO = 'SET_JANUS_INFO'
export const END_MEDIA_CHAT = 'END_MEDIA_CHAT'
export const CLOSE_MEDIA_REQUEST = 'CLOSE_MEDIA_REQUEST'
export const SET_FEEDBACK = 'SET_FEEDBACK'
export const SET_PROACTIVE_CHAT_STARTED = 'SET_PROACTIVE_CHAT_STARTED'

// Page, Form, and Widget actions
export const SET_SHOW_FULL_WIDGET = 'SET_SHOW_FULL_WIDGET'
export const SET_IS_MINIMIZED = 'SET_IS_MINIMIZED'
export const SET_SHOW_INTRO_WIDGET = 'SET_SHOW_INTRO_WIDGET'
export const SET_SHOW_CIRCLE_WIDGET = 'SET_SHOW_CIRCLE_WIDGET'
export const SET_SHOW_INFORMATION_FORM = 'SET_SHOW_INFORMATION_FORM'
export const SET_SHOW_PRIVACY_FORM = 'SET_SHOW_PRIVACY_FORM'
export const SET_SHOW_SURVEY_FORM = 'SET_SHOW_SURVEY_FORM'
export const SET_DEPARTMENT_PRIVACY_MESSAGE = 'SET_DEPARTMENT_PRIVACY_MESSAGE'
export const DEPARTMENT_CLOSED = 'DEPARTMENT_CLOSED'
export const SET_SHOW_VIDEO_CHAT_REQUEST = 'SET_SHOW_VIDEO_CHAT_REQUEST'
export const SET_SHOW_AUDIO_CHAT_REQUEST = 'SET_SHOW_AUDIO_CHAT_REQUEST'
export const SET_SHOW_COBROWSE_REQUEST = 'SET_SHOW_COBROWSE_REQUEST'
export const SET_SHOW_VIDEO_CHAT = 'SET_SHOW_VIDEO_CHAT'
export const SET_SHOW_AUDIO_CHAT = 'SET_SHOW_AUDIO_CHAT'
export const SET_SHOW_COBROWSE = 'SET_SHOW_COBROWSE'
export const SET_SHOW_TRANSCRIPTION_PAGE = 'SET_SHOW_TRANSCRIPTION_PAGE'
export const TRANSCRIPTION_PAGE_REQUEST_SUCCESS = 'TRANSCRIPTION_PAGE_REQUEST_SUCCESS'
export const TRANSCRIPTION_PAGE_REQUEST_FAIL = 'TRANSCRIPTION_PAGE_REQUEST_FAIL'
export const SET_SHOW_EMAIL_FORM = 'SET_SHOW_EMAIL_FORM'
export const SET_SHOW_ATTACHMENT_BOX = 'SET_SHOW_ATTACHMENT_BOX'
export const SET_SHOW_ERROR_VIEW = 'SET_SHOW_ERROR_VIEW'
export const SET_TRANSLATION = 'SET_TRANSLATION'

// Checked
export const SET_PAGE = 'SET_PAGE'
export const SET_STATUS = 'SET_STATUS'
export const SET_CHAT_STATUS = 'SET_CHAT_STATUS'
export const SET_INITIALIZED = 'SET_INITIALIZED'
export const HANDLE_CHAT_REQUEST = 'HANDLE_CHAT_REQUEST'
export const CUSTOMER_ONLINE_RESPONSE_RECEIVED = 'CUSTOMER_ONLINE_RESPONSE_RECEIVED'
export const CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED = 'CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED'
export const OPERATOR_JOINED = 'OPERATOR_JOINED'
export const DEPARTMENT_TRANSFER = 'DEPARTMENT_TRANSFER'


