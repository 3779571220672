import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import uuid from 'uuid/v5'
import moment from 'moment'
import imgEmoji from '../../images/emoji.svg'
import imgAttachment from '../../images/attachment.svg'
import { getId, getCurrentOperator } from '../../reducers/chat'
import { getUserId } from '../../reducers/user'
import { sendSneakPeek } from '../../actions/socketActions'
import { publicUrl } from '../../configuration'
import { TranslationContext } from '../../helpers'
import EmojiBox from '../ChatPage/EmojiBox'


const mapStateToProps = state => {
  let attachmentDisabled = true
  if (getCurrentOperator(state)) {
    attachmentDisabled = false
  }
  return {
    chatID : getId(state),
    userID : getUserId(state),
    attachmentDisabled
  }
}

class WidgetEditor extends React.Component {

  state = {
    message : '',
    previousMessage : '',
    showEmojiBox : false
  }

  sneakPeekInterval = null

  componentDidMount() {
    this.startSneakPeekInterval()
  }

  componentWillUnmount() {
    this.stopSneakPeekInterval()
  }

  startSneakPeekInterval = () => {
    this.sneakPeekInterval = setInterval(this.handleSneakPeek, 1000 * 1)
  }

  stopSneakPeekInterval = () => {
    clearInterval(this.sneakPeekInterval)
  }

  handleSneakPeek = () => {
    const currentMessage = this.state.message
    if (currentMessage !== this.state.previousMessage) {
      this.props.sendSneakPeek(currentMessage)
      this.setState({previousMessage : currentMessage})
    }
  }

  handleEditorChange = (event) => {
    this.setState({message : event.target.value})
  }

  handleKeyPress = (event) => {
    if(event.key !== 'Enter' || this.state.message === '') {
      return
    }
    event.preventDefault()
    const newEvent = {
      type : 'message',
      user : { ID : this.props.userID },
      text : this.state.message,
      uuid : uuid(`${moment().valueOf()}`, uuid.URL),
      received : false
    }
    this.props.sendMessage(newEvent)
    this.setState({message : ''})
  }

  showEmojiBox = () => { this.setState({showEmojiBox : !this.state.showEmojiBox}) }

  addEmoji = (emojiCode) => {
    if (!emojiCode) {
      return
    }
    const message = this.state.message + String.fromCodePoint(emojiCode)
    this.setState({message})
  }

  render() {
    return (
      <TranslationContext.Consumer>
        {translation =>
          <WidgetEditorStyled emojiBoxOpen={this.state.showEmojiBox}>
            <EditorContainer>
              <Editor
                placeholder={translation.widget.editor}
                value={this.state.message}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleEditorChange}
              />
              <EmojiIcon src={publicUrl + imgEmoji} alt='emoji' onClick={this.showEmojiBox}/>
              <div>
                <label htmlFor="file-input" src={publicUrl + imgAttachment}>
                  <AttachmentIcon src={publicUrl + imgAttachment} alt='attachment' />
                </label>
                <FileInput disabled={this.props.attachmentDisabled} id="file-input" type="file" onChange={this.props.selectAttachment}/>
              </div>
            </EditorContainer>
            { this.state.showEmojiBox && <EmojiBox addEmoji={this.addEmoji}/> }
          </WidgetEditorStyled>
        }

      </TranslationContext.Consumer>
    )
  }
}


/********** region ~~~~~ STYLE ~~~~~ */

const WidgetEditorStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  padding: ${props => props.theme.editorContainer.padding};
  background: ${props => props.theme.editorContainer.background};
  height: ${props => props.emojiBoxOpen ? props.theme.editorContainer.heightEmoji : props.theme.editorContainer.height};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: height 0.5s cubic-bezier();
`

const EditorContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const Editor = styled.textarea`
  background: ${props => props.theme.editorContainer.editor.background};
  padding: ${props => props.theme.editorContainer.editor.padding};
  font-size: ${props => props.theme.editorContainer.editor.fontSize};
  opacity: ${props => props.theme.editorContainer.editor.opacity};
  overflow-y: auto;
  resize: none;
  width: 100%;
  height: ${props => props.theme.editorContainer.editor.height};
  font-family: ${props => props.theme.layout.fontFamily};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  outline: none;
  border: 0;
  border-top: 1px solid #BBBBBB;
  margin: 0;
`

const EmojiIcon = styled.img`
  width: ${props => props.theme.editorContainer.editor.emojiIcon.width};
  position: absolute;
  left: 10px;
  top: 10px;
  bottom: 10px;
  margin: auto;
  cursor: pointer;
`

const AttachmentIcon = styled.img`
  width: ${props => props.theme.editorContainer.editor.emojiIcon.width};
  height: ${props => props.theme.editorContainer.editor.emojiIcon.width};
  position: absolute;
  left: 45px;
  top: 10px;
  bottom: 10px;
  margin: auto;
  cursor: pointer;
  opacity: 1;
  background-image: url(${props => props.src});
`

const FileInput = styled.input`
  display: none;
`

/********** endregion */

export default connect(mapStateToProps, { sendSneakPeek })(WidgetEditor)
