import React from 'react'
import styled from 'styled-components'
import InfoBox from '../InfoBox'
import imgArrowRight from '../../images/arrow-right.svg'
import {publicUrl} from '../../configuration'
import {Input} from '../common'
import {TranslationContext} from '../../helpers'

const capitalize = str => (str[0].toUpperCase() + str.substr(1))

const InformationForm = (props) => {

  const errorFields = props.fieldError ? props.fieldError : 'Compila tutti i campi obbligatori'

  const getPlaceHolder = (translation, key, mandatory) => {
    if (translation.informations.user && translation.informations.user[key]) {
      return mandatory
        ? translation.informations.user[key] + ' *'
        : translation.informations.user[key]
    } else {
      return mandatory
        ? `${capitalize(key)} *`
        : capitalize(key)
    }
  }

  return (
    <TranslationContext.Consumer>
      {translation =>
        <InformationFromStyled>
          {Object.keys(props.user).filter(key => key !== 'showInfoBox').filter(key => !window['@hidden'] || !window['@hidden'].includes(key)).map(key =>
            <Input
              disabled={!!props.oldUser[key]}
              placeholder={getPlaceHolder(translation, key, props.isMandatory(key))}
              value={props.user[key]}
              onChange={props.onChange(key)}
              key={key}/>
          )}
          <ArrowRightContainer>
            <ArrowRightStyled type="submit" onClick={props.onSubmit}>
              <img src={publicUrl + imgArrowRight} alt="go on"/>
            </ArrowRightStyled>
          </ArrowRightContainer>
          {props.showInfoBox && <InfoBox info={errorFields}/>}
        </InformationFromStyled>
      }
    </TranslationContext.Consumer>
  )
}


/* region STYLE */

const InformationFromStyled = styled.form`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: ${props => props.theme.information.margin};
  box-sizing: border-box;
  padding: 10px 0;
  overflow-y: auto;
  max-height: 100%;
`

const ArrowRightContainer = styled.div`
    position: relative;
    margin-top: ${props => props.theme.information.arrow.marginTop};
`

const ArrowRightStyled = styled.button`
    width: 48px;
    border: none;
    background: transparent;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    & img {
      width: 48px;
      cursor: pointer;
    }
`

/********** endregion */

export default InformationForm
