import React from 'react'
import imgSpinner from '../images/Rolling-1s-200px.svg'
import { publicUrl } from '../configuration'
import styled from "styled-components";


const Loading = () => {

  return(
    <LoadingContainer>
      <Spinner src={publicUrl + imgSpinner} alt='loading' />
    </LoadingContainer>
  )
}

/********** region STYLE */

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Spinner = styled.img`
  width: 100px;
  height: 100px;
`

/********** endregion */

export default Loading