import React, {Component} from 'react'
import styled from 'styled-components'
import imgAvatar from '../../images/header/operator-avatar.svg'
import imgClose from '../../images/close.svg'
import imgVideoCall from '../../images/video-call.svg'
import imgCobrowse from '../../images/sharing.svg'
import {publicUrl} from '../../configuration'
import {TranslationContext} from '../../helpers'

class Notification extends Component {

  state = {
    showClose: false
  }

  getMediaMessage = (translation) => {
    if (this.props.type === 'message') {
      return this.props.text.length > 75
        ? this.props.text.substring(0, 75) + '...'
        : this.props.text
    } else {
      switch (this.props.subtype) {
        case 'audio request':
          return translation.notifications.audioChatRequest
        case 'video request':
          return translation.notifications.videoChatRequest
        case 'cobrowse request':
          return translation.notifications.cobrowseRequest
        default:
          return translation.notifications.errorMessage
      }
    }
  }

  getImage = () => {
    switch (this.props.subtype) {
      case 'audio request':
        return imgAvatar
      case 'video request':
        return imgVideoCall
      case 'cobrowse request':
        return imgCobrowse
      default:
        return imgAvatar
    }
  }

  render = () => (
    <TranslationContext.Consumer>
      {translation =>
        <NotificationContainer
          onMouseEnter={() => this.setState({showClose: true})}
          onMouseLeave={() => this.setState({showClose: false})}
        >
          {this.state.showClose &&
          <NotificationClose onClick={() => this.props.close(this.props.id)}>
            <img src={publicUrl + imgClose} alt="close"/>
          </NotificationClose>
          }
          <NotificationAvatar>
            <img src={publicUrl + this.getImage()} alt="avatar"/>
          </NotificationAvatar>
          <NotificationInfo>
            <NotificationOperator>{this.props.operator}</NotificationOperator>
            <NotificationText>
              { this.getMediaMessage(translation) }
            </NotificationText>
          </NotificationInfo>
        </NotificationContainer>
      }

    </TranslationContext.Consumer>
  )

}

export default Notification

//region STYLE

const NotificationContainer = styled.div`
  position: relative;
  z-index: 100000;
  background: ${props => props.theme.notifications.body.background};
  width: ${props => props.theme.notifications.body.width};
  height: ${props => props.theme.notifications.body.height};
  padding: ${props => props.theme.notifications.body.padding};
  margin: ${props => props.theme.notifications.body.margin};
  box-shadow: ${props => props.theme.notifications.body.boxShadow};
  border-radius: ${props => props.theme.notifications.body.borderRadius};
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
`

const NotificationAvatar = styled.div`
  width: ${props => props.theme.notifications.body.avatar.width};
  height: ${props => props.theme.notifications.body.avatar.height};
  margin-right: ${props => props.theme.notifications.body.avatar.marginRight};
  display: flex;
  align-items: center;
  & img {
    width: ${props => props.theme.notifications.body.avatar.width};
  }
`

const NotificationInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${props => props.theme.notifications.body.info.width};
  height: ${props => props.theme.notifications.body.info.height};
`

const NotificationOperator = styled.div`
  font-weight: ${props => props.theme.notifications.body.title.fontWeight};
  width: ${props => props.theme.notifications.body.title.width};
  height: ${props => props.theme.notifications.body.title.height};
  font-size: ${props => props.theme.notifications.body.title.fontSize};
  opacity: ${props => props.theme.notifications.body.title.opacity};
`

const NotificationText = styled.div`
  opacity: ${props => props.theme.notifications.body.text.opacity};
  overflow: hidden;
  width: ${props => props.theme.notifications.body.text.width};
  height: ${props => props.theme.notifications.body.text.height};
  font-size: ${props => props.theme.notifications.body.text.fontSize};
  margin-top: ${props => props.theme.notifications.body.text.marginTop};
`

const NotificationClose = styled.div`
  position: absolute;
  ${props => props.theme.notifications.body.close.pos.top
  ? `top: ${props.theme.notifications.body.close.pos.top};` : ''};
  ${props => props.theme.notifications.body.close.pos.right
  ? `right: ${props.theme.notifications.body.close.pos.right};` : ''};
  ${props => props.theme.notifications.body.close.pos.bottom
  ? `bottom: ${props.theme.notifications.body.close.pos.bottom};` : ''};
  ${props => props.theme.notifications.body.close.pos.left
  ? `left: ${props.theme.notifications.body.close.pos.left};` : ''};
  width: ${props => props.theme.notifications.body.close.width};
  z-index: 10000;
  cursor: pointer;
  & img {
    width: ${props => props.theme.notifications.body.close.width};
  }
`
//endregion
