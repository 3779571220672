import React, { Component } from 'react'
import styled from 'styled-components'
import uuid from 'uuid'
import Emoji from '../../emoji'
import imgEmojiSmile from '../../images/emojiSmile.svg'
import imgEmojiAnimals from '../../images/emojiAnimals.svg'
import imgEmojiFood from '../../images/emojiFood.svg'
import imgEmojiCar from '../../images/emojiCar.svg'
import imgEmojiFootball from '../../images/emojiFootball.svg'
import imgEmojiLightbulb from '../../images/emojiLightbulb.svg'
import imgEmojiHashtag from '../../images/emojiHashtag.svg'
import imgEmojiFlag from '../../images/emojiFlag.svg'
import { publicUrl } from '../../configuration'

class EmojiBox extends Component {

  state = {
    selectedCategory : 'smileyspeople'
  }

  changeSelectedCategory = (category) => {
    this.setState({selectedCategory : category})
  }

  getEmoji = (emojiCode) => {
    if (!emojiCode) {
      return
    }
    return String.fromCodePoint(emojiCode)
  }

  render() {
    return(
      <EmojiContainer>
        <EmojiCategoryContainer>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('smileyspeople')}
            selected={this.state.selectedCategory === 'smileyspeople'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiSmile} alt='emoji-smile' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('animalsnature')}
            selected={this.state.selectedCategory === 'animalsnature'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiAnimals} alt='emoji-animals' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('fooddrink')}
            selected={this.state.selectedCategory === 'fooddrink'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiFood} alt='emoji-food' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('travelplaces')}
            selected={this.state.selectedCategory === 'travelplaces'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiCar} alt='emoji-car' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('activities')}
            selected={this.state.selectedCategory === 'activities'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiFootball} alt='emoji-football' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('objects')}
            selected={this.state.selectedCategory === 'objects'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiLightbulb} alt='emoji-lightbulb' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('symbols')}
            selected={this.state.selectedCategory === 'symbols'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiHashtag} alt='emoji-hashtag' />
          </EmojiCategory>
          <EmojiCategory
            onClick={() => this.changeSelectedCategory('flags')}
            selected={this.state.selectedCategory === 'flags'}
          >
            <EmojiCategoryImage src={publicUrl + imgEmojiFlag} alt='emoji-flag' />
          </EmojiCategory>
        </EmojiCategoryContainer>
        <EmojiBody>
          {Emoji[this.state.selectedCategory].map(em =>
            <EmojiCell key={uuid()} onClick={() => this.props.addEmoji(em.codes[0])}>
              {this.getEmoji(em.codes[0])}
            </EmojiCell>
          )}
        </EmojiBody>
      </EmojiContainer>
    )
  }
}


/********** region ~~~~~ STYLE ~~~~~ */

const EmojiContainer = styled.div`
  position: relative;
  height: ${props => props.theme.emoji.container.height};
  background: ${props => props.theme.emoji.container.background};
  border-bottom-left-radius: ${props => props.theme.emoji.container.borderRadius};
  border-bottom-right-radius: ${props => props.theme.emoji.container.borderRadius};
  border-top: ${props => props.theme.emoji.container.border};
  display: flex;
  flex-direction: column;
`;

const EmojiCategoryContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: ${props => props.theme.emoji.categoryContainer.height};
`;

const EmojiCategory = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-bottom: ${props => props.selected ? props.theme.emoji.categoryContainer.borderBottomSelected : '0px'}
`;

const EmojiCategoryImage= styled.img`
 width: ${props => props.theme.emoji.image.width};
`;

const EmojiBody = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  overflow: auto;
  padding: 5px;
  justify-content: flex-start;
`;

const EmojiCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.emoji.cell.height};
  width: ${props => props.theme.emoji.cell.width};
  cursor: pointer;
`;

/********** endregion */

export default EmojiBox
