import React from 'react'
import { connect } from 'react-redux'
import { TranslationContext } from '../../helpers'
import styled from 'styled-components'
import { getOperatorWriting } from '../../reducers/chat'


const mapStateToProps = state => ({
  operatorWriting : getOperatorWriting(state)
})

const WidgetSneakpeek = (props) => {

  return(
    <TranslationContext.Consumer>
      {translation =>
        <Sneakpeek>
          {props.operatorWriting &&
          <SneakpeekText>{props.operatorWriting} {translation.widget.writing}</SneakpeekText>
          }
        </Sneakpeek>
      }
    </TranslationContext.Consumer>
  )
}


/********** region ~~~~~ STYLE ~~~~~ */

const Sneakpeek = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background: ${props => props.theme.sneakPeek.background};
  height: ${props => props.theme.sneakPeek.height};
  padding: ${props => props.theme.sneakPeek.padding};
  font-size: ${props => props.theme.sneakPeek.fontSize};
`;

const SneakpeekText = styled.span`
  display: inline-block;
  opacity: ${props => props.theme.sneakPeek.opacity};
  height: 16px;
`;

/********** endregion */

export default connect(mapStateToProps)(WidgetSneakpeek)
