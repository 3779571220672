import { combineReducers } from 'redux'
import * as at from '../actions/types'


const id = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_CHAT_ID :
      return data
    case at.HANDLE_CHAT_REQUEST:
      return data.chat
    case at.CLOSE_CHAT :
      return null
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      return data.chatID
    default :
      return state
  }
}

const department = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_DEPARTMENT :
      return data
    case at.HANDLE_CHAT_REQUEST:
      return data.department.ID
    case at.CLOSE_CHAT:
      return window['@department'] || null
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      return data.department.ID
    case at.DEPARTMENT_TRANSFER:
      return data.ID
    default :
      return state
  }
}

const departmentName = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_DEPARTMENT_NAME :
      return data
    case at.HANDLE_CHAT_REQUEST:
      return data.department.Name
    case at.CLOSE_CHAT :
      return null
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      return data.department.Name
    case at.DEPARTMENT_TRANSFER:
      return data.Name
    default :
      return state
  }
}

const departments = (state = [], {type, data}) => {
  switch (type) {
    case at.SET_DEPARTMENTS :
      return data
    case at.CLOSE_CHAT_REQUEST :
      return []
    case at.CUSTOMER_ONLINE_RESPONSE_RECEIVED :
      return data.departments
    default :
      return state
  }
}

const socket = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_SOCKET:
      return data
    case at.CLOSE_CHAT :
      return null
    default :
      return state
  }
}

const events = (state = [], {type, data}) => {
  switch (type) {
    case at.ADD_EVENT :
      return [...state, data]
    case at.ADD_EVENTS :
      return data
    case at.CLOSE_CHAT_REQUEST :
      return []
    case at.CLOSE_CHAT :
      return []
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      return data.events
    default :
      return state
  }
}

const operators = (state = [], {type, data}) => {
  switch (type) {
    case at.ADD_OPERATOR :
      return [...state, data]
    case at.SET_OPERATORS :
      return data
    case at.CLOSE_CHAT :
      return []
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      return data.operators
    case at.OPERATOR_JOINED :
      return [...state, data]
    default :
      return state
  }
}

const currentOperator = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_CURRENT_OPERATOR :
      return data
    case at.CLOSE_CHAT_REQUEST :
      return null
    case at.CLOSE_CHAT :
      return null
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      return data.currentOperator
    case at.OPERATOR_JOINED:
      return data
    case at.DEPARTMENT_TRANSFER:
      return null
    default :
      return state
  }
}

const departmentPrivacyMessage = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_DEPARTMENT_PRIVACY_MESSAGE :
      localStorage.setItem('privacy', data)
      return data
    case at.CLOSE_CHAT_REQUEST:
      return null
    case at.HANDLE_CHAT_REQUEST:
      return null
    default :
      return state
  }
}

const operatorWriting = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_OPERATOR_WRITING :
      return data
    case at.CLOSE_CHAT_REQUEST:
      return null
    default :
      return state
  }
}

const openingTimes = (state = null, {type, data}) => {
  switch (type) {
    case at.DEPARTMENT_CLOSED:
      return data.openings
    case at.CLOSE_CHAT_REQUEST:
      return null
    default:
      return state
  }
}

const isClosedDueToExtra = (state = false, {type, data}) => {
  switch (type) {
    case at.DEPARTMENT_CLOSED:
      return data.isClosedDueToExtra
    case at.CLOSE_CHAT_REQUEST:
      return false
    case at.CLOSE_CHAT:
      return false
    default:
      return state
  }
}

const webRtcPeer = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_WEB_RTC_PEER:
      return data
    case at.CLOSE_CHAT_REQUEST:
      return null
    default:
      return state
  }
}

const currentMessage = (state = [], {type, data}) => {
  switch (type) {
    case at.ADD_EVENT:
      return data
    default:
      return state
  }
}

const janusInfo = (state = {}, {type, data}) => {
  switch (type) {
    case at.SET_JANUS_INFO:
      return {...state, ...data}
    case at.END_MEDIA_CHAT:
      return {}
    default:
      return state
  }
}

const feedback = (state = null, {type, data}) => {
  switch (type) {
    case at.SET_FEEDBACK:
      return data
    case at.CLOSE_CHAT_REQUEST:
      return null
    case at.CLOSE_CHAT:
      return null
    default:
      return state
  }
}

const proactiveChatStarted = (state = false, {type, data}) => {
  switch (type) {
    case at.SET_PROACTIVE_CHAT_STARTED:
      return data
    case at.CLOSE_CHAT:
      return false
    default:
      return state
  }
}

export const getId = state => state.chat.id
export const getDepartment = state => state.chat.department
export const getDepartmentName = state => state.chat.departmentName
export const getDepartments = state => state.chat.departments
export const getSocket = state => state.chat.socket
export const getEvents = state => state.chat.events
export const getOperators = state => state.chat.operators
export const getCurrentOperator = state => state.chat.currentOperator
export const getDepartmentPrivacyMessage = state => state.chat.departmentPrivacyMessage
export const getOperatorWriting = state => state.chat.operatorWriting
export const getOpeningTimes = state => state.chat.openingTimes
export const getWebRtcPeer = state => state.chat.webRtcPeer
export const getCurrentMessage = state => state.chat.currentMessage
export const getJanusInfo = state => state.chat.janusInfo
export const getFeedback = state => state.chat.feedback
export const getProactiveChatStarted = state => state.chat.proactiveChatStarted


export default combineReducers({
  id,
  department,
  departmentName,
  departments,
  socket,
  events,
  operators,
  currentOperator,
  departmentPrivacyMessage,
  operatorWriting,
  openingTimes,
  isClosedDueToExtra,
  webRtcPeer,
  currentMessage,
  janusInfo,
  feedback,
  proactiveChatStarted
})
