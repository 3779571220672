import io from 'socket.io-client'
import configuration from '../configuration'
import * as at from './types'
import { getId, getSocket, getDepartment, getSessionUser, getPage } from '../reducers'
import {
  handleAddEvent,
  handleChatRequest,
  handleInitSocket,
  handleDepartmentInfo,
  handleSendSneakPeek,
  handleRequestMediaAccept,
  handleCloseMediaCall,
  handleTranscriptionRequest,
  handleLeaveMessage,
  handleAddFileUploadEvent,
  handleSendSurvey,
  handleCloseChatRequestCustomer
} from '../api/socketManager'


const endpoint = `${configuration.socket.address}:${configuration.socket.port}`

export const initSocket = client_info => (dispatch, getState) => {
  const socket = io(endpoint, {path: '/chat'})
  dispatch({type: at.SET_SOCKET, data: socket})
  handleInitSocket(dispatch, getState, socket, client_info)
}

export const departmentInfo = departmentID => (dispatch, getState) => {
  const socket = getSocket(getState())
  const page = getPage(getState())
  const payload = { department: departmentID }
  handleDepartmentInfo(socket, page, payload, dispatch)
}

export const chatRequest = (departmentID) => (dispatch, getState) => {
  const socket = getSocket(getState())
  const payload = {
    department: departmentID,
    user: getSessionUser(getState())
  }
  handleChatRequest(socket, payload, dispatch)
}

export const addEvent = (event) => (dispatch, getState) => {
  const socket = getSocket(getState())
  const payload = { text: event.text,  tempID: event.uuid }
  handleAddEvent(socket, payload, event, dispatch)
}

export const addFileUploadEvent = ({chat, filename, filepath}) => (dispatch, getState) => {
  const socket = getSocket(getState())
  const payload = { chat, filename, filepath }
  handleAddFileUploadEvent(socket, payload, dispatch)
}

export const closeChatRequest = () => (dispatch, getState) => {
  const socket = getSocket(getState())
  handleCloseChatRequestCustomer(dispatch, getState, socket)
}

export const sendSneakPeek = (textSneakPeek) => (dispatch, getState) => {
  const socket = getSocket(getState())
  handleSendSneakPeek(socket, textSneakPeek)
}

export const sendTranscriptionRequest = () => (dispatch, getState) => {
  const socket = getSocket(getState())
  const chatID = getId(getState())
  handleTranscriptionRequest(socket, chatID, dispatch)
}

export const mediaRequestAccepted = (accepted, type) => async (dispatch, getState) => {
  const socket = getSocket(getState())
  await handleRequestMediaAccept(socket, dispatch, getState, accepted, type)
}

export const closeMediaCall = () => async (dispatch, getState) => {
  const socket = getSocket(getState())
  await handleCloseMediaCall(socket, getState, dispatch)
}

export const leaveMessage = (subject, body) => (dispatch, getState) => {
  const socket = getSocket(getState())
  const payload = {
    department : getDepartment(getState()),
    user: getSessionUser(getState()),
    subject,
    body
  }
  handleLeaveMessage(dispatch, socket, payload)
}

export const sendSurvey = (survey) => (dispatch, getState) => {
  const socket = getSocket(getState())
  handleSendSurvey(dispatch, socket, survey)
}

//Checked

export const departmentTransfer = (department) => {
  localStorage.setItem('departmentID', department.ID)
  localStorage.setItem('departmentName', department.Name)
  return {type: at.DEPARTMENT_TRANSFER, data: department}
}
