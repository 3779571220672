import * as at from '../actions/types'
import { statuses, pages, chatStatuses } from '../constants'


const page = (state = pages.DEFAULT, {type, data}) => {
  switch (type) {
    case at.INITIALIZE:
      localStorage.setItem('page', data.page)
      return data.page
    case at.SET_PAGE:
      localStorage.setItem('page', data)
      return data
    case at.CLOSE_CHAT:
      localStorage.setItem('page', pages.DEFAULT)
      return pages.DEFAULT
    case at.SET_SHOW_ERROR_VIEW:
      localStorage.setItem('page', pages.ERROR)
      return pages.ERROR
    case at.DEPARTMENT_CLOSED:
      localStorage.setItem('page', pages.DEPARTMENT_CLOSED)
      return pages.DEPARTMENT_CLOSED
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      localStorage.setItem('page', pages.CHAT)
      return pages.CHAT
    case at.HANDLE_CHAT_REQUEST:
      localStorage.setItem('page', pages.CHAT)
      return pages.CHAT
    default:
      return state
  }
}

const chatStatus = (state = chatStatuses.DEFAULT, {type, data}) => {
  switch (type) {
    case at.INITIALIZE:
      localStorage.setItem('chatStatus', data.chatStatus)
      return data.chatStatus
    case at.SET_CHAT_STATUS :
      localStorage.setItem('chatStatus', data)
      return data
    case at.CLOSE_CHAT:
      localStorage.setItem('chatStatus', chatStatuses.CLOSED)
      return chatStatuses.CLOSED
    case at.END_MEDIA_CHAT:
      localStorage.setItem('chatStatus', chatStatuses.DEFAULT)
      return chatStatuses.DEFAULT
    case at.OPERATOR_JOINED:
      localStorage.setItem('chatStatus', chatStatuses.DEFAULT)
      return chatStatuses.DEFAULT
    case at.CLOSE_MEDIA_REQUEST:
      localStorage.setItem('chatStatus', chatStatuses.DEFAULT)
      return chatStatuses.DEFAULT
    case at.HANDLE_CHAT_REQUEST:
      localStorage.setItem('chatStatus', chatStatuses.WAITING)
      return chatStatuses.WAITING
    default :
      return state
  }
}

const status = (state = statuses.CIRCLE, {type, data}) => {
  switch (type) {
    case at.INITIALIZE:
      localStorage.setItem('status', data.status)
      return data.status
    case at.SET_STATUS :
      localStorage.setItem('status', data)
      return data
    case at.CLOSE_CHAT:
      localStorage.setItem('status', statuses.CIRCLE)
      return statuses.CIRCLE
    case at.SET_PROACTIVE_CHAT_STARTED:
      localStorage.setItem('status', statuses.OPENED)
      return statuses.OPENED
    case at.CUSTOMER_ONLINE_RESPONSE_CHAT_RESUMED:
      localStorage.setItem('status', statuses.OPENED)
      return statuses.OPENED
    default :
      return state
  }
}

const initialized = (state = false, {type, data}) => {
  switch (type) {
    case at.SET_INITIALIZED :
      localStorage.setItem('initialized', data)
      return data
    case at.CLOSE_CHAT:
      localStorage.removeItem('initialized')
      return false
    default :
      return state
  }
}

export default {
  page,
  status,
  chatStatus,
  initialized
}
