import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from "styled-components";
import ReactMic from "react-mic/es/components/ReactMic";
import { publicUrl } from "../../configuration";
import imgAudio from "../../images/header/operator-avatar.svg";
import { ButtonRefuse } from '../common'
import { closeMediaCall, mediaRequestAccepted } from '../../actions/socketActions'


const mapDispatchToProps = (dispatch) => ({
  mediaRequestAccepted : (accept, type) => dispatch(mediaRequestAccepted(accept, type)),
  closeMediaCall : () => dispatch(closeMediaCall())
})

class MediaAudioChat extends Component {

  componentDidMount() {
    this.props.mediaRequestAccepted(true, 'audio')
  }

  closeMedia = () => {
    this.props.closeMediaCall()
  }

  render() {
    return(
      <>
        <MediaAudioContainer>
          <AudioWavesContainer>
            <AudioWaves>
              <ReactMic
                record={true}
                className="sound-wave"
                strokeColor="#0e2942"
                backgroundColor="#FFFFFF" />
            </AudioWaves>
            <HeadephoneContainer>
              <Headphone src={publicUrl + imgAudio} alt='headphone' />
            </HeadephoneContainer>
            <AudioWaves>
              <ReactMic
                record={true}
                className="sound-wave"
                strokeColor="#0e2942"
                backgroundColor="#FFFFFF" />
            </AudioWaves>
          </AudioWavesContainer>
          <audio autoPlay id='audio-operator' />
          <ButtonContainer >
            <ButtonRefuse onClick={this.closeMedia} size={'medium'}>Termina Chiamata</ButtonRefuse>
          </ButtonContainer>
        </MediaAudioContainer>
      </>
    )
  }
}

/********** region STYLE */

const MediaAudioContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 20px;
`

const AudioWavesContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0px 0px 0px;
  width: 100%;
`

const HeadephoneContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border: 2px solid #0e2942;
  border-radius: 50%;
`

const AudioWaves = styled.div `
  width: 132px;
  overflow: hidden;
`

const Headphone = styled.img `
  width: 50px;
`

const ButtonContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px 0px 50px 0px;
`

/********** endregion */

export default connect(null, mapDispatchToProps)(MediaAudioChat)

