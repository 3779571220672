import React, {} from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Janus from '../../get-janus'
import { withAlert } from 'react-alert'
import WidgetMessageBox from '../Widget/WidgetMessageBox'
import WidgetSneakpeek from '../Widget/WidgetSneakpeek'
import WidgetEditor from '../Widget/WidgetEditor'
import MediaChatRequest from './MediaChatRequest'
import MediaVideoChat from './MediaVideoChat'
import MediaAudioChat from './MediaAudioChat'
import MediaCobrowse from './MediaCobrowse'
import imgVideoChat from '../../images/video-call.svg'
import imgAudioChat from '../../images/header/operator-avatar.svg'
import imgSharing from '../../images/sharing.svg'
import { addEvent, mediaRequestAccepted } from '../../actions/socketActions'
import {
  getWebRtcPeer,
  getUserId,
  getShowVideoChatRequest,
  getShowAudioChatRequest,
  getShowCobrowseRequest,
  getShowVideoChat,
  getShowAudioChat,
  getShowCobrowse,
  getChatStatus
} from '../../reducers'
import {
  setShowVideoChatRequest,
  setShowAudioChatRequest,
  setShowCobrowseRequest,
  setShowVideoChat,
  setShowAudioChat,
  setShowCobrowse,
  setWebRtcPeer,
  setShowAttachmentBox,
  setChatStatus
} from '../../actions/commonActions'
import {
  WAITING,
  DEFAULT,
  CLOSED,
  MEDIA_CALL,
  MEDIA_CALL_REQUEST,
  MEDIA_SCREEN_SHARE,
  MEDIA_SCREEN_SHARE_REQUEST,
  MEDIA_VIDEO,
  MEDIA_VIDEO_REQUEST
} from '../../constants/chatStatuses'


const mapStateToProps = state => ({
  userID : getUserId(state),
  showVideoChatRequest : getShowVideoChatRequest(state),
  showAudioChatRequest : getShowAudioChatRequest(state),
  showCobrowseRequest : getShowCobrowseRequest(state),
  showVideoChat : getShowVideoChat(state),
  showAudioChat : getShowAudioChat(state),
  showCobrowse : getShowCobrowse(state),
  webRtcPeer : getWebRtcPeer(state),
  chatStatus : getChatStatus(state)
})

const WidgetChat = ({setChatStatus, ...props}) => {

  // Start a Video Chat
  const startVideoChat = (accepted) => {
    if (accepted) {
      setChatStatus(MEDIA_VIDEO)
      // props.setShowVideoChat(true)
      // props.setShowVideoChatRequest(false)
    } else {
      setChatStatus(DEFAULT)
      props.mediaRequestAccepted(false, 'video')
      // props.setShowVideoChatRequest(false)
      // props.mediaRequestAccepted(false, 'video')
    }
  }

  // Start an Audio Chat
  const startAudioChat = (accepted) => {
    if (accepted) {
      setChatStatus(MEDIA_CALL)
      // props.setShowAudioChat(true)
      // props.setShowAudioChatRequest(false)
    } else {
      setChatStatus(DEFAULT)
      props.mediaRequestAccepted(false, 'audio')
      // props.setShowAudioChatRequest(false)
      // props.mediaRequestAccepted(false, 'audio')
    }
  }

  // Start Screen Share
  const startCobrowse = (accepted) => {
    if (accepted) {
      console.log(Janus.isExtensionEnabled())
      if (!Janus.isExtensionEnabled()) {
        props.mediaRequestAccepted(false, 'cobrowse')
        const alertStyle = {
          textTransform: 'none'
        }
        props.alert.show(
          <div style={alertStyle}>
            Estensione ScreenSharing non abilitata.
            <br />
            <br />
            Per installarla, clicca
            <a
              style={{color : '#2e9afe', marginLeft : '5px', display : 'inline-block'}}
              href="https://chrome.google.com/webstore/detail/janus-webrtc-screensharin/hapfgfdkleiggjjpfpenajgdnfckjpaj"
              target="_blank"
              rel="noopener noreferrer"
            >qui</a>
          </div>)
        setChatStatus(DEFAULT)
        //props.setShowCobrowseRequest(false)
        return
      }
      setChatStatus(MEDIA_SCREEN_SHARE)
      // props.setShowCobrowse(true)
      // props.setShowCobrowseRequest(false)
    } else {
      setChatStatus(DEFAULT)
      props.mediaRequestAccepted(false, 'cobrowse')
      // props.setShowCobrowseRequest(false)
      // props.mediaRequestAccepted(false, 'cobrowse')
    }
  }

  const selectAttachment = () => {
    props.setShowAttachmentBox(true)
  }

  const toRender = () => {
    switch (props.chatStatus) {
      case MEDIA_CALL:
        return (<MediaAudioChat />)
      case MEDIA_VIDEO:
        return (<MediaVideoChat />)
      case MEDIA_SCREEN_SHARE:
        return (<MediaCobrowse />)
      case MEDIA_CALL_REQUEST:
        return (
          <MediaChatRequest
            accept={startAudioChat}
            img={imgAudioChat}
            description={'Richiesta di Chiamata'}
            acceptButtonLabel={'Accetta'}
            refuseButtonLabel={'Rifiuta'}
            type={'audio'}
          />
        )
      case MEDIA_VIDEO_REQUEST:
        return (
          <MediaChatRequest
            accept={startVideoChat}
            img={imgVideoChat}
            description={'Richiesta di Video Chiamata'}
            acceptButtonLabel={'Accetta'}
            refuseButtonLabel={'Rifiuta'}
            type={'video'}
          />
        )
      case MEDIA_SCREEN_SHARE_REQUEST:
        return (
          <MediaChatRequest
            accept={startCobrowse}
            img={imgSharing}
            description={'Richiesta di Screen Sharing'}
            acceptButtonLabel={'Accetta'}
            refuseButtonLabel={'Rifiuta'}
            type={'cobrowse'}
          />
        )
      default:
        return (
          <WidgetContentBody>
            <WidgetMessageBox />
            <WidgetSneakpeek />
            <WidgetEditor
              sendMessage={props.addEvent}
              userID={props.userID}
              selectAttachment={selectAttachment}
            />
          </WidgetContentBody>
        )
    }
  }

  return(
    <>
      {toRender()}
    </>
  )
}

/********** region STYLE */

const WidgetContentBody = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

/********** endregion */

export default connect(mapStateToProps, {
  addEvent,
  setShowVideoChatRequest,
  setShowAudioChatRequest,
  setShowCobrowseRequest,
  setShowVideoChat,
  setShowAudioChat,
  setShowCobrowse,
  mediaRequestAccepted,
  setWebRtcPeer,
  setShowAttachmentBox,
  setChatStatus
})(withAlert(WidgetChat))
