import {
  getIsMinimized,
  getTranslation,
  getInitialized,
  getDepartment,
  getDepartmentName,
  getCurrentOperator,
  getSocket,
  getPage,
  getOperatorWriting,
  getChatStatus
} from '../reducers'
import { pages, chatStatuses } from '../constants'
import imgInformation from '../images/header/personal-information.svg'
import imgDepartmentClosed from '../images/header/department-closed.svg'
import imgDepartmentList from '../images/header/choice2.svg'
import imgPrivacy from '../images/header/privacy.svg'
import imgWaitingChat from '../images/header/hourglass.svg'
import imgChat from '../images/header/operator-avatar.svg'
import imgFeedback from '../images/header/feedback.svg'
import imgDefault from '../images/header/operator.svg'
import imgOperatorIsWriting from '../images/header/typingCircle.svg'


const mapStateToProps = state => {

  const pageToShow = getPage(state)

  let imgHeader = null
  let imgCircle = null
  let title = ''
  let subtitle = ''
  const isMinimized = getIsMinimized(state)
  const translation = getTranslation(state)
  const socket = getSocket(state)
  const department = getDepartment(state)
  const chatStatus = getChatStatus(state)
  const currentOperator = getCurrentOperator(state)
  const departmentName = getDepartmentName(state)

  const waitingForOperator = chatStatus === chatStatuses.WAITING

  switch (pageToShow) {
    case pages.INFO:
      imgHeader = imgInformation
      imgCircle = imgInformation
      title = translation.info.title
      subtitle = translation.info.subtitle
      break
    case pages.DEPARTMENT_LIST:
      imgHeader = imgDepartmentList
      imgCircle = imgDepartmentList
      title = translation.departmentList.title
      subtitle = translation.departmentList.subtitle
      break
    case pages.DEPARTMENT_CLOSED:
      imgHeader = imgDepartmentClosed
      imgCircle = imgDepartmentClosed
      title = translation.departmentClosed.title
      subtitle = translation.departmentClosed.subtitle
      break
    case pages.PRIVACY:
      imgHeader = imgPrivacy
      imgCircle = imgPrivacy
      title = translation.privacy.title
      subtitle = translation.privacy.subtitle
      break
    case pages.FEEDBACK:
      imgHeader = imgFeedback
      imgCircle = imgFeedback
      title = translation.feedback.title
      subtitle = translation.feedback.subtitle
      break
    case pages.TRANSCRIPTION:
      imgHeader = imgFeedback
      imgCircle = imgFeedback
      title = translation.transcriptionPage.title
      subtitle = translation.transcriptionPage.subtitle
      break
    case pages.CHAT:
      imgHeader = waitingForOperator ? imgWaitingChat : imgChat
      imgCircle = waitingForOperator ? imgWaitingChat : imgChat
      title = waitingForOperator
        ? translation.widget.title
        : currentOperator
          ? `${currentOperator.Name} ${currentOperator.Surname}`
          : ''
      subtitle = waitingForOperator
        ? translation.widget.subtitle
        : departmentName || ''
      break
    default:
      imgHeader = imgDefault
      imgCircle = imgChat
      title = translation.error.title
      subtitle = translation.error.subtitle
  }

  if (getOperatorWriting(state)) {
    imgCircle = imgOperatorIsWriting
  }

  if (!getInitialized(state)) {
    imgCircle = imgDefault
  }

  return {
    pageToShow,
    imgHeader,
    imgCircle,
    isMinimized,
    title,
    subtitle,
    translation,
    socket,
    department
  }
}

export default mapStateToProps
