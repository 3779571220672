import React from 'react'
import styled from 'styled-components'
import { publicUrl } from '../../configuration'

const MediaChatRequest = ({accept, img, description, acceptButtonLabel, refuseButtonLabel, type}) => {

  return (
    <VideoRequestStyled>
      <VideoRowContainer>
        <VideoRequestImage src={publicUrl + img} alt='request-image' />
      </VideoRowContainer>
      <VideoRowContainer>
        <VideoRequestTitle>{description}</VideoRequestTitle>
      </VideoRowContainer>
      <VideoButtonContainer>
        <ConfirmButton onClick={() => accept(true)}>{acceptButtonLabel}</ConfirmButton>
        <BackButton onClick={() => accept(false)}>{refuseButtonLabel}</BackButton>
      </VideoButtonContainer>
    </VideoRequestStyled>
  )
}

export default MediaChatRequest

/* region STYLE */

const VideoRequestStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 100%;
  overflow-y: auto;
  z-index: 10000;
`;

const VideoRowContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const VideoRequestTitle = styled.div `
  font-weight: bold;
  color: #000000;
  opacity: 0.7;
`

const VideoRequestImage = styled.img `
  width: 55px;
  height: 55px;
`

const VideoButtonContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 80px 5px 0px 5px;
`;

const DialogButton = styled.div `
  font-size: 0.9rem;
  color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
`

const BackButton = styled(DialogButton) `
  background: ${props => props.theme.mediaChat.buttonRefuseBackground};
  margin-bottom: ${props => props.theme.mediaChat.request.buttonRefuse.marginBottom};
  width: ${props => props.theme.mediaChat.request.buttonRefuse.width};
  color: ${props => props.theme.mediaChat.request.buttonRefuse.color};
  padding: ${props => props.theme.mediaChat.request.buttonRefuse.padding};
  font-weight: ${props => props.theme.mediaChat.request.buttonRefuse.fontWeight};
 `

const ConfirmButton = styled(DialogButton) `
  background: ${props => props.theme.mediaChat.buttonAcceptBackground};
  margin-bottom: ${props => props.theme.mediaChat.request.buttonAccept.marginBottom};
  width: ${props => props.theme.mediaChat.request.buttonAccept.width};
  color: ${props => props.theme.mediaChat.request.buttonAccept.color};
  padding: ${props => props.theme.mediaChat.request.buttonAccept.padding};
  font-weight: ${props => props.theme.mediaChat.request.buttonAccept.fontWeight};
`

/* endregion */
