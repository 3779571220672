import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { closeChatRequest } from '../actions/socketActions'
import { closeChat } from '../actions/commonActions'
import imgError from '../images/attention.svg'

const ErrorView = (props) => {

  const errorMainMessage = 'Al momento non è possibile iniziare una chat.'
  const errorSecondaryMessage = 'Riprova più tardi.'

  useEffect(() => {
    setTimeout(() => {
      props.closeChatRequest()
      props.closeChat()
    }, 5 * 1000)
  }, [])

  return(
    <ErrorMessageBox>
      <ImgError src={imgError} alt='error' />
      <ErrorMainMessage>{errorMainMessage}</ErrorMainMessage>
      <ErrorSecondaryMessage>{errorSecondaryMessage}</ErrorSecondaryMessage>
    </ErrorMessageBox>
  )
}

/********** region STYLE */

const ErrorMessageBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  margin-top: 50px;
`

const ErrorMainMessage = styled.div`
  font-weight: bold;
`

const ErrorSecondaryMessage = styled.div`
  margin-top: 10px;
`

const ImgError = styled.img`
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
`

/********** endregion */

export default connect(null, {closeChatRequest, closeChat})(ErrorView)