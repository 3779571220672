import React from 'react'
import { connect } from 'react-redux'
import publicIp from 'public-ip'
import { browserName } from 'react-device-detect'
import Widget from './Widget/Widget'
import { Provider } from 'react-alert'
import AlertTemplate from './AlertTemplate'
import * as themes from '../themes'
import ThemeContext from '../themes/context'
import { ThemeProvider } from 'styled-components'
import {
  getPage, getStatus, getInitialized, getSessionUser, getSessionDepartment, getShowIntroWidget,
  getShowCircleWidget, getIsMinimized, getSocket, getProactiveChatStarted
} from '../reducers'
import {
  setShowIntroWidget,
  setShowFullWidget,
  setShowCircleWidget,
  setIsMinimized,
  updateSession,
  setInitialized,
  setPage,
  setStatus
} from '../actions/commonActions'
import {  initSocket, departmentInfo } from '../actions/socketActions'
import NotificationsCounter from './CircleWidget/NotificationsCounter'
import CircleWidget from './CircleWidget/CircleWidget'
import IntroWidget from './IntroWidget'
import NotificationsShowerContainer from './CircleWidget/NotificationsShower'
import configuration from '../configuration'
import AudioManager from './AudioManager/AudioManager'
import { statuses, pages } from '../constants'


const mapStateToProps = state => ({
  showIntroWidget: getShowIntroWidget(state),
  showCircleWidget: getShowCircleWidget(state),
  sessionUser: getSessionUser(state),
  sessionDepartment: getSessionDepartment(state),
  isMinimized: getIsMinimized(state),
  socket: getSocket(state),
  proactiveChatStarted: getProactiveChatStarted(state),

  page: getPage(state),
  status: getStatus(state),
  initialized: getInitialized(state),
})

let timeout = null

const options = {
  timeout: 6000 * 1000,
  position: "top center"
}

class App extends React.Component {

  state = {
    theme: themes[this.props.theme]
  }

  async componentDidMount() {
    //  If the chat is minimized, set the minimized flag
    this.props.setStatus(localStorage.getItem('status') || statuses.CIRCLE)

    // If is not initialized, show the intro widget after a timeout
    !this.props.initialized && this.startTimeout()

    await this.initializeSocket({isProactive: !!(window['@proactive'] && this.props.department)})

    // Export the function to open the widget from the site that hosts the chat
    window.openOCWidgetChat = () => this.onClickCircleWidget()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.socket && !this.props.socket) {
      await this.initializeSocket({isProactive: false})
    }
  }

  startTimeout = () => {
    let highlightTime
    if (isNaN(window['@highlightTime'])) {
      highlightTime = configuration.widget.defaultTimer
    } else {
      highlightTime = window['@highlightTime']
    }
    highlightTime = parseInt(highlightTime)
    console.log('highlightTime')
    console.log(highlightTime)
    if (highlightTime < 0) return

    timeout = setTimeout(() => {
      this.props.status === statuses.CIRCLE && this.props.setStatus(statuses.INTRO)
    }, 1000 * highlightTime)
  }

  stopTimeout = () => {
    clearTimeout(timeout)
  }

  onClickIntroWidget = () => {
    this.props.setStatus(statuses.OPENED)
    !this.props.initialized && this.props.setInitialized(true)
    this.props.page === pages.DEFAULT && this.props.setPage(pages.INFO)
  }

  onClickCircleWidget = () => {
    timeout && this.stopTimeout()
    this.props.setStatus(statuses.OPENED)
    !this.props.initialized && this.props.setInitialized(true)
    this.props.page === pages.DEFAULT && this.props.setPage(pages.INFO)
  }

  // checkToSendDepartmentInfo = () => {
  //   if (!this.props.isInitialized && this.props.sessionDepartment) {
  //     console.log(this.props.sessionDepartment)
  //     departmentInfo(this.props.sessionDepartment)
  //   }
  // }

  initializeSocket = async ({isProactive}) => {
    // Init the socket with the information
    const client_info = {
      department: window['@department'] ? window['@department'] : null,
      ip: await publicIp.v4().catch(() => null),
      url: window.location.href,
      isProactive,
      browser: browserName
    }
    this.props.initSocket(client_info)
  }

  render() {
    return (
      <Provider template={AlertTemplate} {...options}>
        <ThemeContext.Provider value={this.state}>
          <ThemeContext.Consumer>
            {theme => (
              <ThemeProvider theme={theme.theme}>
                <>
                  <IntroWidget
                    isMounted={this.props.status === statuses.INTRO}
                    onClick={this.onClickIntroWidget}
                  />
                  {this.props.status === statuses.CIRCLE &&
                  <>
                    <NotificationsCounter/>
                    <NotificationsShowerContainer/>
                  </>
                  }
                  <CircleWidget
                    isMounted={this.props.status === statuses.CIRCLE || this.props.status === statuses.INTRO}
                    onClick={this.onClickCircleWidget}
                  />
                  <Widget
                    isMounted={this.props.status === statuses.OPENED}
                    user={this.props.user}
                    department={this.props.department}
                    pageToShow={this.props.page}
                    status={this.props.status}
                  />
                  <AudioManager />
                </>
              </ThemeProvider>
            )}
          </ThemeContext.Consumer>
        </ThemeContext.Provider>
      </Provider>
    )
  }
}

export default connect(mapStateToProps, {
  updateSession,
  setShowIntroWidget,
  setShowFullWidget,
  setShowCircleWidget,
  setIsMinimized,
  setInitialized,
  initSocket,
  departmentInfo,
  setPage,
  setStatus
})(App)
