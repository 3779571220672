import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import rootReducer from './reducers'
import App from './components/App'
import { getTranslation } from './api/get-translation'
import { TranslationContext } from './helpers'
import { INITIALIZE } from './actions/types'
import { pages, statuses, chatStatuses } from './constants'
import { clearlocalStorage } from './api/helpers'
import { closeChat } from './actions/commonActions'
import { closeChatRequest } from './actions/socketActions'


const LANGUAGE = window['@language']
const USER = JSON.parse(localStorage.getItem('sessionUser')) || window['@user']
const DEPARTMENT = parseInt(window['@department']) || null
const MANDATORY = window['@mandatory']
const THEME = window['@theme'] || 'theme1'
const PAGE = localStorage.getItem('page') || pages.DEFAULT
const STATUS = localStorage.getItem('status') || statuses.CIRCLE
const CHAT_STATUS = localStorage.getItem('chatStatus') || chatStatuses.DEFAULT
const INITIALIZED = localStorage.getItem('initialized') || false
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewares = process.env.NODE_ENV === 'development'
  ? [thunk, logger]
  : [thunk]

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(...middlewares)
  )
)

getTranslation(LANGUAGE).then(translation => {

  // Funzione da far richiamare ai clienti al logout o alla chiusura della finestra
  window.closeOCWidgetChat = () => {
    store.dispatch(closeChatRequest())
    store.dispatch(closeChat())
    clearlocalStorage()
  }

  store.dispatch({ type: INITIALIZE, data: {
      page: PAGE,
      status: STATUS,
      chatStatus: CHAT_STATUS,
      initialized: INITIALIZED,
      translation,
      session: {user: USER, department: DEPARTMENT, mandatory: MANDATORY}
    }
  })

  ReactDOM.render(
    <Provider store={store}>
      <TranslationContext.Provider value={translation}>
        <App
          theme={THEME}
          translation={translation}
          user={USER}
          mandatory={MANDATORY}
          department={DEPARTMENT}
        />
      </TranslationContext.Provider>
    </Provider>,
    document.querySelector('#bytewise-oc-chat')
  )
})
