import React from 'react'
import moment from 'moment'
import { TranslationContext } from '../../../helpers'
import styled from 'styled-components'

const mapNumberToDay = new Map([
  [0, 'monday'],
  [1, 'tuesday'],
  [2, 'wednesday'],
  [3, 'thursday'],
  [4, 'friday'],
  [5, 'saturday'],
  [6, 'sunday'],
])

const OpeningTime = ({ time }) => {
  const [interval] = time
  if (!interval) return (<DepartmentClosedListElementTime>Chiuso</DepartmentClosedListElementTime>)
  const [from, to] = interval
  const fromFormatted = moment.utc().startOf('day').add(from, 'minutes').format('HH:mm')
  const toFormatted = moment.utc().startOf('day').add(to, 'minutes').format('HH:mm')
  return <DepartmentClosedListElementTime>{fromFormatted} - {toFormatted}</DepartmentClosedListElementTime>
}

const OpeningTimes = ({ openingTimes, showEmailForm, showEmailFormButton, isClosedDueToExtra }) => {

  return(
    <TranslationContext.Consumer>
      {translation =>
        <DepartmentContainer>
          <DepartmentClosedContent>

            { isClosedDueToExtra &&
                <CloseExtraDisclaimer>Il dipartimento è chiuso in via eccezionale rispetto ai normali orari di apertura.</CloseExtraDisclaimer>
            }

            {openingTimes
              ? (<>
                <DepartmentClosedH2>{translation.departmentClosed.description}</DepartmentClosedH2>
                <DepartmentClosedList>
                  {openingTimes && Object.values(openingTimes).map((time, i) =>
                    <DepartmentClosedListElement key={i}>
                      <OpeningTime time={time}/>
                      <DepartmentClosedListElementDay>{translation.days[mapNumberToDay.get(i)]} </DepartmentClosedListElementDay>
                    </DepartmentClosedListElement>
                  )}
                </DepartmentClosedList>
              </>)
              : (<DepartmentClosedH2>Dipartimento attualmente chiuso.</DepartmentClosedH2>)
            }
          </DepartmentClosedContent>
          { showEmailFormButton && <ButtonEmail onClick={showEmailForm}>Lascia un messaggio</ButtonEmail> }
        </DepartmentContainer>
      }
    </TranslationContext.Consumer>
  )
}

/********** region STYLE */

const DepartmentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const CloseExtraDisclaimer = styled.div`
  color: #eae4e4;
  font-size: 0.85rem;
  margin-bottom: 25px;
  border-radius: 4px;
  padding: 10px;
  background: #133553;
`

const DepartmentClosedContent = styled.div`
  font-family: 'Open Sans',sans-serif !important;
  font-size: 10px;
  width: 90%;
  margin-top: 18px;

  & img {
    width: 90px;
    margin: auto;
  }
`

const DepartmentClosedH2 = styled.div`
  margin-bottom: 20px;
  font-size: 1.05rem;
`

const DepartmentClosedList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const DepartmentClosedListElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.95rem;
  background: #ECECEC;
  margin-bottom: 10px;
  width: 100%;
  padding: 2px 5px;
  height: 28px;
  border-radius: 28px;
`

const DepartmentClosedListElementTime = styled.div`
  margin-left: 10px;
  font-size: 14px;
`

const DepartmentClosedListElementDay = styled.div`
  margin-right: 10px;
  opacity: 0.7;
  font-size: 14px;
`

const ButtonEmail = styled.div`
  width: 150px;
  height: 25px;
  background: #133553;
  color: #FFFFFF;
  font-size: 10px;
  padding: 6px;
  text-align: center;
  line-height: 25px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
`

/********** endregion */

export default OpeningTimes
