import React from 'react'
import styled from 'styled-components'

const WidgetContent = ({children}) => {

  return(
    <WidgetContentStyled>
      {children}
    </WidgetContentStyled>
  )
}


/********** region ~~~~~ STYLE ~~~~~ */

const WidgetContentStyled = styled.div `
  position: relative;
  overflow-y: auto;
  height: ${props => props.theme.layout.content.height};
  background: ${props => props.theme.layout.content.background};
  border-bottom-left-radius: ${props => props.theme.layout.content.borderRadius};
  border-bottom-right-radius: ${props => props.theme.layout.content.borderRadius};
`

/********** endregion */

export default WidgetContent
