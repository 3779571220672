import React from 'react'
import styled from 'styled-components'

const ButtonAccept = (props) => {

  const { children, size, onClick } = props

  return(
    <ButtonAcceptStyled
      onClick={onClick}
      size={size}
    >
      {children}
    </ButtonAcceptStyled>
  )
}

/********** region ~~~~~ STYLE ~~~~~ */

const ButtonAcceptStyled = styled.div `
  padding: 10px;
  color: ${props => props.theme.mediaChat.buttonAcceptColor};
  border-radius: 4px;
  font-size: ${props => props.theme.mediaChat.buttonAcceptFontSize};
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
  width: ${props => props.theme.mediaChat.buttonAcceptWidth};
  background: ${props => props.theme.mediaChat.buttonAcceptBackground};
  font-weight: ${props => props.theme.mediaChat.buttonAcceptFontWeight};
`

/********** endregion */

export { ButtonAccept }
