import { getCurrentMessage } from '../reducers/chat'
import { getChatStatus } from '../reducers'
import { MEDIA_SCREEN_SHARE_REQUEST, MEDIA_VIDEO_REQUEST, MEDIA_CALL_REQUEST } from '../constants/chatStatuses'


const _shouldPlayRingtone = status =>
  status === MEDIA_CALL_REQUEST ||
  status === MEDIA_VIDEO_REQUEST ||
  status === MEDIA_SCREEN_SHARE_REQUEST

const mapStateToProps = state => {
  const message = getCurrentMessage(state)
  const chatStatus = getChatStatus(state)

  const playNotification = message.type === 'message'
  const playRingtone = _shouldPlayRingtone(chatStatus)

  return { message, playRingtone, playNotification }
}

export default mapStateToProps
