import React from 'react'
import { connect } from 'react-redux'
import ChatLayout from '../ChatPage/ChatLayout'
import { departmentInfo } from '../../actions/socketActions'
import ComponentsFactory from '../ComponentsFactory/ComponentsFactory'
import { statuses } from '../../constants'
import mapStateToProps from '../../states/pages'


const Widget = (props) => {

  return(
    <ChatLayout
      isMinimized={props.status === statuses.CIRCLE}
      isMounted={props.isMounted}
      delay={400}
    >
      <ComponentsFactory component={props.pageToShow} />
    </ChatLayout>
  )
}

export default connect(mapStateToProps, { departmentInfo })(Widget)
