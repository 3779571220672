import React from 'react'
import { connect } from 'react-redux'
import styled, {css} from 'styled-components'
import imgMinimize from '../../images/minimize.svg'
import imgMaximize from '../../images/maximize.svg'
import { setStatus } from '../../actions/commonActions'
import { getStatus, getDepartmentName } from '../../reducers'
import { publicUrl } from '../../configuration'
import imgClose from "../../images/close-chat.svg"
import {closeChatRequest} from "../../actions/socketActions"
import { CIRCLE, OPENED} from '../../constants/statuses'


const mapStateToProps = state => ({
  isMinimized : getStatus(state) !== OPENED,
  departmentName : getDepartmentName(state)
})

const mapDispatchToProps = dispatch => ({
  setStatus : status => dispatch(setStatus(status)),
  closeChat : () => dispatch(closeChatRequest())
})

const WidgetTitleBar = (props) => {

  const handleCloseButtonPress = () => {
    props.closeChat()
  }

  return (
    <WidgetTitleBarStyled id="bw-drag-here" isDraggable={props.isDraggable}>
      {props.isMinimized
        ? <ResizeIcon
            src={publicUrl + imgMaximize}
            onClick={() => props.setStatus(OPENED)}
          />
        : <ResizeIcon
            src={publicUrl + imgMinimize}
            onClick={() => props.setStatus(CIRCLE)}
          />
      }
      <CloseCircle onClick={() => handleCloseButtonPress()}>
        <img src={publicUrl + imgClose} alt="close"/>
      </CloseCircle>
    </WidgetTitleBarStyled>
  )
}


/********** region STYLE */

const WidgetTitleBarStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.layout.titleBar.background};
  height: ${props => props.theme.layout.titleBar.height};
  border-top-left-radius: ${props => props.theme.layout.titleBar.borderRadius};
  border-top-right-radius: ${props => props.theme.layout.titleBar.borderRadius};
  cursor: ${props => props.isDraggable ? 'move' : 'auto'};
`;

const ResizeIcon = styled.img `
  width: 16px;
  cursor: pointer;
`

const CloseCircle = styled.div`
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  font-size: 0.8rem;
  background: ${props => props.theme.layout.header.closeCircle.background};
  padding: 3px;
  cursor: pointer;
  color: ${props => props.theme.layout.header.closeCircle.color};
  transition: ${props => props.theme.layout.header.closeCircle.transition};
  
  & img {
    width: 16px;
    max-width: none;
  }
`

/********** endregion */

export default connect(mapStateToProps, mapDispatchToProps)(WidgetTitleBar)
