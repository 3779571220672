export default {

    layout: {
        fontFamily: '\'Open Sans\', sans-serif !important',
        width: '380px',
        height: '520px',
        border: '',
        borderRadius: '8px', //or '0px'
        boxShadow: '0px 0px 47px -6px rgba(0,0,0,0.75)', // or 'none'
        pos: {
            bottom: '35px',
            right: '25px'
        },
        titleBar: {
            height: '25px',
            background: '#0e2942',
            color: '#FFFFFF',
            departmentDisplay: 'block', // or 'none'
            borderRadius: '8px'
        },
        header: {
            height: '80px',
            background: '#133553',
            color: '#FFFFFF',
            avatar: {
                width: '55px',
                margin: '0px 15px'
            },
            information: {
                color: '#FFFFFF',
                fontWeight: 'bold',
                opacity: '0.8',
                lineHeight: '20px',
                title: {
                    fontSize: '0.85rem',
                    fontSizeOperator: '0.95rem'
                },
                subtitle: {
                    fontSize: '0.8rem',
                    opacity: '0.65'
                }
            },
            closeCircle: {
                pos: {
                    top: '0',
                    right: '10px',
                    bottom: '0'
                },
                width: {
                    default: '15px',
                    extended: '55px'
                },
                height: '15px',
                borderRadius: {
                    default: '50%',
                    extended: '30px'
                },
                fontSize: {
                    default: '0.1rem',
                    extended: '0.8rem'
                },
                img: {width: '25px'},
                transition: '0.2s',
                color: '#FFFFFF',
                padding: '10px',
                background: '#D75A4A'
            }
        },
        content: {
            background: '#FFFFFF',
            height: '415px',
            borderRadius: '8px'
        },
        footer: {
            height: '25px',
            background: '#0e2942',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0px 20px',
            borderRadius: '8px'
        }
    },
    information: {
        margin: '15px',
        input: {
            height: '45px',
            padding: '5px 15px',
            borderRadius: '5px',
            border: '1px solid #BBBBBB',
            boxShadow: 'none',
            opacity: '0.9',
            margin: '10px 0px 0px 0px',
            lineHeight: '20px'
        },
        arrow: {
            marginTop: '45px'
        }
    },
    chooseDepartment: {
        department: {
            background: '#0173c7',
            color: '#FFFFFF',
            width: '90%',
            marginTop: '12px',
            fontSize: '0.8rem',
            padding: '8px 10px',
            borderRadius: '4px',
            fontWeight: 'normal'
        }
    },
    privacy: {
        privacyBox: {
            height: '330px',
            fontSize: '0.85rem',
            opacity: '0.9',
            padding: '20px'
        },
        buttonAcceptContainer: {
            height: '70px',
            buttonAccept: {
                height: '33px',
                width: '100px',
                background: '#0173c7',
                color: '#FFFFFF',
                fontSize: '0.95rem',
                padding: '0 0 2px 0',
                borderRadius: '4px', // or 0px
                justifyContent: 'center'
            }
        }
    },
    editorContainer: {
        height: '60px',
        heightEmoji: '200px', // EditorContainer.height + Emoji.height + 10
        padding: '0',
        background: '#FFFFFF',
        editor: {
            height: '42px',
            background: '#FFFFFF',
            padding: '8px 8px 8px 90px',
            fontSize: '0.95rem',
            opacity: '0.85',
            emojiIcon: {
                width: '25px'
            }
        }
    },
    sneakPeek: {
        height: '25px',
        padding: '0px 12px',
        background: 'linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0))',
        borderTop: '1px solid #BBBBBB',
        fontSize: '0.8rem',
        opacity: '0.6'
    },
    messageBox: {
        padding: '10px 20px',
        message: {
            maxWidth: '80%',
            minWidth: '100px',
            borderRadius: '4px',
            lineHeight: '19px',
            operatorBackground: '#329166',
            customerBackground: '#0173C7',
            supervisorBackground: '#593365',
            color: '#FFFFFF',
            opacity: '0.8',
            fontSize: '0.8rem',
            padding: '12px 20px 20px 12px',
            marginTop: '12px'
        }
    },
    emoji: {
        container: {
            height: '140px',
            background: '#FFFFFF',
            borderRadius: '5px',
            border: '1px solid #BBBBBB'
        },
        categoryContainer: {
            height: '40px',
            borderBottomSelected: '2px solid #5ba785'
        },
        cell: {
            width: '29px',
            height: '30px'
        },
        image: {
            width: '20px'
        }
    },
    previewWidget: {
        background: 'linear-gradient(to right, #0e2942, #133553)',
        pos: {
            bottom: '25px',
            right: '25px'
        },
        width: '65px',
        height: '65px',
        border: '1px solid #BBBBBB',
        borderRadius: '50%',
        img: {
            small: {
                size: '20px'
            },
            medium: {
                size: '60px'
            },
            large: {
                size: '45px'
            },
            margin: '7px 0px 0px 0px'
        }
    },
    notifications: {
        list: {
            pos: {
                right: '25px',
                bottom: '130px',
            },
            flex: {
                direction: 'column',
                alignItems: 'flex-end'
            }
        },
        counter: {
            pos: {
                right: '20px',
                bottom: '80px',
            },
            fontSize: '0.9rem',
            background: '#D75A4A',
            width: '25px',
            height: '25px',
            color: '#FFFFFF',
            borderRadius: '50%'
        },
        body: {
            width: '300px',
            height: '80px',
            padding: '10px',
            margin: '10px',
            boxShadow: '0 0 40px 0 rgba(0,0,0,0.4)',
            borderRadius: '10px',
            background: '#FFFFFF',
            avatar: {
                width: '50px',
                height: '100%',
                marginRight: '20px'
            },
            info: {
                width: '220px',
                height: '100%'
            },
            title: {
                width: '220px',
                height: '20px',
                fontSize: '0.95rem',
                opacity: '0.9',
                fontWeight: 'bold'
            },
            text: {
                width: '220px',
                height: '60px',
                opacity: '0.8',
                fontSize: '0.9rem',
                marginTop: '3px'
            },
            close: {
                pos: {
                    top: '5px',
                    right: '5px',
                },
                width: '20px'
            }
        }

    },
    mediaChat: {
        buttonAcceptBackground: '#329166',
        buttonRefuseBackground: '#D75A4A',
        buttonAcceptWidth: '100px',
        buttonRefuseWidth: '100px',
        buttonAcceptFontSize: '0.75rem',
        buttonRefuseFontSize: '0.75rem',
        buttonAcceptColor: '#FFFFFF',
        buttonRefuseColor: '#FFFFFF',
        buttonAcceptFontWeight: 'normal',
        buttonRefuseFontWeight: 'normal',
        request: {
            buttonAccept: {
                width: '90%',
                color: '#FFFFFF',
                marginBottom: '10px',
                padding: '10px',
                fontWeight: 'normal'
            },
            buttonRefuse: {
                width: '90%',
                color: '#FFFFFF',
                marginBottom: '0',
                padding: '10px',
                fontWeight: 'normal'
            }
        }
    },
    general: {
      infoMessage: {
        background: '#133553'
      },
    },
    transcription: {
      buttonAcceptBackground: '#329166',
      buttonRefuseBackground: '#D75A4A',
      buttonAcceptWidth: '100px',
      buttonRefuseWidth: '100px',
      buttonAcceptFontSize: '1rem',
      buttonRefuseFontSize: '1rem',
      buttonAcceptColor: '#FFFFFF',
      buttonRefuseColor: '#FFFFFF',
      buttonAcceptFontWeight: 'normal',
      buttonRefuseFontWeight: 'normal'
    },

    // THEME
    barWidget: {
        background: '#FFFFFF',
        height: '65px',
        width: '280px',
        padding: '10px',
        borderRadius: '40px',
        boxShadow: '-1px 0px 6px 0px rgba(0,0,0,0.75)',
        pos: {
            bottom: '25px',
            right: '26px'
        },
        textContainer: {
            marginLeft: '15px',
            textUpper: {
                fontSize: '0.9rem',
                color: '#000000'
            },
            textBottom: {
                fontWeight: 'bold',
                fontSize: '1.05rem',
                color: '#000000'
            }
        },
        clickHereButton: {
            color: '#FFFFFF',
            background: '#133553',
            padding: '6px',
            borderRadius: '6px',
            fontSize: '0.75rem',
            marginLeft: '15px',
            fontWeight: 'normal'
        }
    }


}
