import React from 'react'
import { connect } from 'react-redux'
import PrivacyMessageForm from './PrivacyMessageForm'
import { getDepartment, getDepartmentPrivacyMessage } from '../../reducers/chat'
import { chatRequest } from '../../actions/socketActions'
import { setPage, setChatStatus } from '../../actions/commonActions'


const mapStateToProps = state => ({
  department : getDepartment(state),
  departmentPrivacyMessage: getDepartmentPrivacyMessage(state)
})

const PrivacyForm = ({ department, departmentPrivacyMessage, chatRequest }) => {

  const acceptPrivacyForm = () => {
    localStorage.removeItem('privacy')
    chatRequest(department || parseInt(localStorage.getItem('departmentID')))
  }

  return(
    <PrivacyMessageForm
      acceptPrivacyForm={acceptPrivacyForm}
      departmentPrivacyMessage={departmentPrivacyMessage || localStorage.getItem('privacy')}
    />
  )
}

export default connect(mapStateToProps, {
  chatRequest, setPage, setChatStatus
})(PrivacyForm)
