import React from 'react'
import styled from 'styled-components'
import Ratings from 'react-ratings-declarative'


const QuestionRating5 = ({ question, nextQuestion }) => {

  const changeRating = (rating) => {
    nextQuestion(rating)}

  return(
    <QuestionContainer>
      <Question>{question}</Question>
      <Ratings
        widgetRatedColors="blue"
        changeRating={changeRating}
      >
        <Ratings.Widget widgetDimension="45px" widgetHoverColor="#e7ad27" />
        <Ratings.Widget widgetDimension="45px" widgetHoverColor="#e7ad27" />
        <Ratings.Widget widgetDimension="45px" widgetHoverColor="#e7ad27" />
        <Ratings.Widget widgetDimension="45px" widgetHoverColor="#e7ad27" />
        <Ratings.Widget widgetDimension="45px" widgetHoverColor="#e7ad27" />
      </Ratings>
    </QuestionContainer>
  )
}

// region STYLE

const QuestionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  margin-top: 30px;
`

const Question = styled.div`
  position: relative;
  padding: 15px;
  border-bottom: 1px solid #BBBBBB;
  opacity: 0.9;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
`

//endregion

export default QuestionRating5