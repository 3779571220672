import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getDepartments, getSessionDepartment, getProactiveChatStarted } from '../../reducers'
import { departmentInfo } from '../../actions/socketActions'
import { setPage } from '../../actions/commonActions'
import { DEPARTMENT_CLOSED } from '../../constants/pages'

const mapStateToProps = state => ({
  departments: getDepartments(state),
  sessionDepartment: getSessionDepartment(state),
  proactiveChatStarted: getProactiveChatStarted(state)
})

const DepartmentList = ({ departments, departmentInfo, sessionDepartment, proactiveChatStarted }) => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(proactiveChatStarted || sessionDepartment) {
      departmentInfo(sessionDepartment)
    } else if (departments.length === 0) {
      setPage(DEPARTMENT_CLOSED)
    } else {
      setLoading(false)
    }
  }, [])

  return(
    <>
      { loading && null }
      { !loading && <DepartmentsContainer>
        {departments && departments.filter(department => department.isOpen).map(department =>
        <Department
          key={department.ID}
          onClick={() => departmentInfo(department.ID)}
        >
          {department.Name}
        </Department>
        )}
      </DepartmentsContainer>}
    </>
  )
}

/********** region STYLE */

const DepartmentsContainer = styled.div `
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px 0px;
    box-sizing: border-box;
`

const Department = styled.div `
    cursor: pointer;
    background: ${props => props.theme.chooseDepartment.department.background};
    margin-top: ${props => props.theme.chooseDepartment.department.marginTop};
    width: ${props => props.theme.chooseDepartment.department.width};
    padding: ${props => props.theme.chooseDepartment.department.padding};
    font-size: ${props => props.theme.chooseDepartment.department.fontSize};
    color: ${props => props.theme.chooseDepartment.department.color};
    border-radius: ${props => props.theme.chooseDepartment.department.borderRadius};
    font-weight: ${props => props.theme.chooseDepartment.department.fontWeight};
`

/********** endregion */

export default connect(mapStateToProps, { departmentInfo, setPage })(DepartmentList)
