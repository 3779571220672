import React from 'react'
import { connect } from 'react-redux'
import { publicUrl } from '../../configuration'
import { closeChatRequest } from '../../actions/socketActions'
import imgSuccess from '../../images/transcriptionSent.svg'
import styled from 'styled-components'

const TranscriptionPageSuccess = ({ closeChatRequest }) => (
  <>
    <ImgTranscription src={publicUrl + imgSuccess} alt="transcription success"/>
    <Text>Riceverà presto una email con la trascrizione completa della chat, come da lei richiesto, all'indirizzo indicato.</Text>
    <Button onClick={closeChatRequest}>Chiudi</Button>
  </>
)

export default connect(null, { closeChatRequest })(TranscriptionPageSuccess)

// region STYLE

const ImgTranscription = styled.img`
  margin-top: 40px;
`

const Text = styled.div`
  margin-top: 50px;
  font-size: 1.0rem;
  opacity: 0.8;
`

const Button = styled.div`
  margin: 40px 10px 10px 10px;
  cursor: pointer;
  padding: 10px 15px;
  background: #58b269;
  color: #FFFFFF;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  font-size: 1rem;
`

//endregion
