import { combineReducers } from 'redux'
import {CLOSE_CHAT, UPDATE_SESSION, UPDATE_SESSION_USER, INITIALIZE} from '../actions/types'

const user = (state = {}, {type, data}) => {
  switch (type) {
    case INITIALIZE:
      return data.session.user
    case UPDATE_SESSION :
      return data.user
    case UPDATE_SESSION_USER :
      return data
    case CLOSE_CHAT:
      return data
    default :
      return state
  }
}

const department = (state = null, {type, data}) => {
  switch (type) {
    case INITIALIZE:
      return data.session.department
    case UPDATE_SESSION :
      return data.department
    default :
      return state
  }
}

const mandatory = (state = {}, {type, data}) => {
  switch (type) {
    case INITIALIZE:
      return data.session.mandatory
    case UPDATE_SESSION :
      return data.mandatory
    default :
      return state
  }
}


export const getSessionUser = state => state.session.user
export const getSessionDepartment = state => state.session.department
export const getSessionMandatory = state => state.session.mandatory


export default combineReducers({
  user,
  department,
  mandatory
})
