import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getCurrentMessage} from '../../reducers/chat'
import styled from 'styled-components'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import Notification from './Notification'
import uuid from 'uuid/v4'
import '../../css/transitions.css'

const mapStateToProps = state => ({
  message: getCurrentMessage(state)
})

class NotificationsShower extends Component {

  state = {
    unreads: []
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //If a new message come from an operator
    if (prevProps.message !== this.props.message) {
      //Check if we need to do nothing
      if (!this.isGoodEvent(this.props.message)) return
      //Add a unique id to the message to delete it after
      let message = { ...this.props.message, id: uuid() }
      //Add the reference to the timeout that will delete it
      message.timeout = setTimeout(() => this.closeNotification(message.id), 1000 * 8)
      //Keep the list to the size of 4
      if (this.state.unreads.length === 4) {
        const unreads = this.state.unreads.splice(0, 3).concat([message])
        this.setState({unreads})
      } else {
        const unreads = [...this.state.unreads, message]
        this.setState({unreads})
      }
    }
  }

  // Clears all timeouts, or it could call setState on an unmounted component
  componentWillUnmount() {
    this.state.unreads.forEach(message => clearTimeout(message.timeout))
  }

  //Tells if a system event is an event for media requests, like videochat, audiochat and cobrowse
  isGoodEvent = ({type, subtype}) =>
    (type === 'message' || (type === 'system' &&
      (subtype === 'audio request' || subtype === 'video request' ||
        subtype === 'cobrowse request')))

  // Close the notification pressing the button or with the timeout
  closeNotification = (id) => {
    const unreads = this.state.unreads.filter(message => message.id !== id)
    this.setState({unreads})
  }

  render = () => (
    <NotificationsList>
      <TransitionGroup>
        {this.state.unreads.map(message =>
          <CSSTransition
            key={message.id}
            mountOnEnter
            unmountOnExit
            in={this.state.unreads.filter(mex => mex.id === message.id).length > 0}
            timeout={300}
            classNames={'notification'}
          >
            <Notification
              id={message.id}
              text={message.text}
              operator={`${message.user.Name} ${message.user.Surname}`}
              close={this.closeNotification}
              type={message.type}
              subtype={message.subtype}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </NotificationsList>
  )

}

export default connect(mapStateToProps)(NotificationsShower)

//region STYLE

const NotificationsList = styled.div`
  font-family: ${props => props.theme.layout.fontFamily};
  color: #000000;
  & > div { 
    position: fixed;
    color: #000000;
    z-index: 100000;
    right: 25px;
    bottom: 120px;
    display: flex;
    flex-direction: ${props => props.theme.notifications.list.flex.direction};
    align-items: ${props => props.theme.notifications.list.flex.alignItems};
  }
`

//endregion

