import { combineReducers } from 'redux'
import {
  SET_SHOW_INTRO_WIDGET,
  SET_SHOW_FULL_WIDGET,
  SET_SHOW_CIRCLE_WIDGET,
  SET_IS_MINIMIZED,
  SET_SHOW_SURVEY_FORM,
  DEPARTMENT_CLOSED,
  SET_SHOW_VIDEO_CHAT_REQUEST,
  SET_SHOW_AUDIO_CHAT_REQUEST,
  SET_SHOW_COBROWSE_REQUEST,
  SET_SHOW_VIDEO_CHAT,
  SET_SHOW_AUDIO_CHAT,
  SET_SHOW_COBROWSE,
  CLOSE_CHAT,
  CLOSE_CHAT_REQUEST,
  END_MEDIA_CHAT,
  CLOSE_MEDIA_REQUEST,
  SET_SHOW_TRANSCRIPTION_PAGE,
  TRANSCRIPTION_PAGE_REQUEST_SUCCESS,
  TRANSCRIPTION_PAGE_REQUEST_FAIL,
  SET_SHOW_EMAIL_FORM,
  SET_SHOW_ATTACHMENT_BOX,
  SET_SHOW_ERROR_VIEW,
  SET_TRANSLATION,
  SET_INITIALIZED,
  INITIALIZE
} from '../actions/types'


const isMinimized = (state = true, {type, data}) => {
  switch (type) {
    case SET_IS_MINIMIZED :
      return data
    case CLOSE_CHAT:
      return true
    default :
      return state
  }
}

const showFullWidget = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_FULL_WIDGET :
      return data
    case CLOSE_CHAT:
      return false
    default :
      return state
  }
}

const showIntroWidget = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_INTRO_WIDGET :
      return data
    default :
      return state
  }
}

const showCircleWidget = (state = true, {type, data}) => {
  switch (type) {
    case SET_SHOW_CIRCLE_WIDGET :
      return data
    case CLOSE_CHAT :
      return true
    default :
      return state
  }
}

const showVideoChatRequest = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_VIDEO_CHAT_REQUEST:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    case CLOSE_MEDIA_REQUEST:
      return false
    default:
      return state
  }
}

const showAudioChatRequest = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_AUDIO_CHAT_REQUEST:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    case CLOSE_MEDIA_REQUEST:
      return false
    default:
      return state
  }
}

const showCobrowseRequest = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_COBROWSE_REQUEST:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    case CLOSE_MEDIA_REQUEST:
      return false
    default:
      return state
  }
}

const showVideoChat = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_VIDEO_CHAT:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    case CLOSE_MEDIA_REQUEST :
      return false
    case END_MEDIA_CHAT:
      return false
    default:
      return state
  }
}

const showAudioChat = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_AUDIO_CHAT:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    case CLOSE_MEDIA_REQUEST :
      return false
    case END_MEDIA_CHAT:
      return false
    default:
      return state
  }
}

const showCobrowse = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_COBROWSE:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    case CLOSE_MEDIA_REQUEST :
      return false
    case END_MEDIA_CHAT:
      return false
    default:
      return state
  }
}

const departmentClosed = (state = false, {type}) => {
  switch (type) {
    case DEPARTMENT_CLOSED:
      return true
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    default:
      return state
  }
}

const showErrorView = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_ERROR_VIEW :
      return data
    case CLOSE_CHAT_REQUEST :
      return null
    case CLOSE_CHAT :
      return null
    default:
      return state
  }
}

const translation = (state = {}, {type, data}) => {
  switch (type) {
    case INITIALIZE:
      return data.translation
    case SET_TRANSLATION :
      return data
    default :
      return state
  }
}

const showEmailForm = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_EMAIL_FORM:
      return data
    case CLOSE_CHAT_REQUEST:
      return false
    case CLOSE_CHAT :
      return false
    default:
      return state
  }
}

const showTranscriptionPage = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_TRANSCRIPTION_PAGE:
      return data
    case CLOSE_CHAT :
      return false
    default:
      return state
  }
}

const showSurveyForm = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_SURVEY_FORM :
      return data
    case CLOSE_CHAT :
      return false
    default :
      return state
  }
}

const transcriptionPageAckResult = (state = null, {type}) => {
  switch (type) {
    case TRANSCRIPTION_PAGE_REQUEST_SUCCESS:
      return 'success'
    case TRANSCRIPTION_PAGE_REQUEST_FAIL:
      return 'error'
    case CLOSE_CHAT :
      return null
    default:
      return state
  }
}

const showAttachmentBox = (state = false, {type, data}) => {
  switch (type) {
    case SET_SHOW_ATTACHMENT_BOX:
      return data
    case CLOSE_CHAT :
      return false
    default:
      return state
  }
}

const initialized = (state = false, {type, data}) => {
  switch (type) {
    case SET_INITIALIZED:
      return data
    case CLOSE_CHAT :
      return false
    default:
      return state
  }
}


export const getLoading = state => state.pages.loading
export const getShowFullWidget = state => state.pages.showFullWidget
export const getIsMinimized = state => state.pages.isMinimized
export const getShowIntroWidget = state => state.pages.showIntroWidget
export const getShowCircleWidget = state => state.pages.showCircleWidget
export const getDepartmentClosed = state => state.pages.departmentClosed
export const getShowVideoChatRequest = state => state.pages.showVideoChatRequest
export const getShowAudioChatRequest = state => state.pages.showAudioChatRequest
export const getShowCobrowseRequest = state => state.pages.showCobrowseRequest
export const getShowVideoChat = state => state.pages.showVideoChat
export const getShowAudioChat = state => state.pages.showAudioChat
export const getShowCobrowse = state => state.pages.showCobrowse
export const getShowTranscriptionPage = state => state.pages.showTranscriptionPage
export const getTranscriptionPageAckResult = state => state.pages.transcriptionPageAckResult
export const getShowEmailForm = state => state.pages.showEmailForm
export const getShowAttachmentBox = state => state.pages.showAttachmentBox
export const getShowErrorView = state => state.pages.showErrorView
export const getTranslation = state => state.pages.translation
export const getInitialized = state => state.pages.initialized

export default combineReducers({
  showSurveyForm,
  showFullWidget,
  isMinimized,
  showIntroWidget,
  showCircleWidget,
  departmentClosed,
  showVideoChatRequest,
  showAudioChatRequest,
  showCobrowseRequest,
  showVideoChat,
  showAudioChat,
  showCobrowse,
  showEmailForm,
  showTranscriptionPage,
  transcriptionPageAckResult,
  showAttachmentBox,
  showErrorView,
  translation,
  initialized
})
