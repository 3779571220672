import { combineReducers } from 'redux'
import root from './root'
import session from './session'
import chat from './chat'
import pages from './pages'
import user from './user'


export default combineReducers({
  ...root,
  session,
  chat,
  pages,
  user
})


// ROOT
export const getPage = state => state.page
export const getStatus = state => state.status
export const getChatStatus = state => state.chatStatus
export const getInitialized = state => state.initialized

// CHAT
export const getId = state => state.chat.id
export const getDepartment = state => state.chat.department
export const getDepartmentName = state => state.chat.departmentName
export const getDepartments = state => state.chat.departments
export const getSocket = state => state.chat.socket
export const getEvents = state => state.chat.events
export const getOperators = state => state.chat.operators
export const getCurrentOperator = state => state.chat.currentOperator
export const getDepartmentPrivacyMessage = state => state.chat.departmentPrivacyMessage
export const getOperatorWriting = state => state.chat.operatorWriting
export const getOpeningTimes = state => state.chat.openingTimes
export const getIsClosedDueToExtra = state => state.chat.isClosedDueToExtra
export const getWebRtcPeer = state => state.chat.webRtcPeer
export const getCurrentMessage = state => state.chat.currentMessage
export const getJanusInfo = state => state.chat.janusInfo
export const getFeedback = state => state.chat.feedback
export const getProactiveChatStarted = state => state.chat.proactiveChatStarted

//PAGES
export const getLoading = state => state.pages.loading
export const getShowFullWidget = state => state.pages.showFullWidget
export const getIsMinimized = state => state.pages.isMinimized
export const getShowIntroWidget = state => state.pages.showIntroWidget
export const getShowCircleWidget = state => state.pages.showCircleWidget
export const getDepartmentClosed = state => state.pages.departmentClosed
export const getShowVideoChatRequest = state => state.pages.showVideoChatRequest
export const getShowAudioChatRequest = state => state.pages.showAudioChatRequest
export const getShowCobrowseRequest = state => state.pages.showCobrowseRequest
export const getShowVideoChat = state => state.pages.showVideoChat
export const getShowAudioChat = state => state.pages.showAudioChat
export const getShowCobrowse = state => state.pages.showCobrowse
export const getShowTranscriptionPage = state => state.pages.showTranscriptionPage
export const getTranscriptionPageAckResult = state => state.pages.transcriptionPageAckResult
export const getShowEmailForm = state => state.pages.showEmailForm
export const getShowAttachmentBox = state => state.pages.showAttachmentBox
export const getShowErrorView = state => state.pages.showErrorView
export const getTranslation = state => state.pages.translation

// SESSION
export const getSessionUser = state => state.session.user
export const getSessionDepartment = state => state.session.department
export const getSessionMandatory = state => state.session.mandatory

// USER
export const getUserId = state => state.user.userId
