import React, {Component} from 'react'
import styled from 'styled-components'
import { sendTranscriptionRequest, closeChatRequest } from '../../actions/socketActions'
import { getTranscriptionPageAckResult, getSessionUser, getOperators } from '../../reducers'
import { connect } from 'react-redux'
import TranscriptionPageDefault from './TranscriptionPageDefault'
import TranscriptionPageSuccess from './TranscriptionPageSuccess'
import TranscriptionPageError from './TranscriptionPageError'


const mapStateToProps = state => ({
  result : getTranscriptionPageAckResult(state),
  user : getSessionUser(state),
  operators: getOperators(state)
})

class TranscriptionPage extends Component {

  state = {
    showConfirm: false,
    showError: false,
    loading: true
  }

  componentDidMount() {
    if (this.props.operators && this.props.operators.length === 0) {
      this.props.closeChatRequest()
    } else {
      this.setState({ loading: false })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const result = this.props.result
    if (prevProps.result !== result) {
      this.setState({ showConfirm: result  === 'success', showError: result === 'error'})
    }
  }

  toRender = (user, closeChatRequest, sendTranscriptionRequest) => {
    if (this.state.showConfirm) {
      return (<TranscriptionPageSuccess />)
    }
    if (this.state.showError) {
      return (<TranscriptionPageError />)
    }
    return <TranscriptionPageDefault user={user} closeChat={closeChatRequest} sendTranscriptionRequest={sendTranscriptionRequest}/>
  }

  render() {
    const {user, closeChatRequest, sendTranscriptionRequest} = this.props

    return (
      <>
        { this.state.loading && null }
        { !this.state.loading &&
          <TranscriptionPageContainer>
            {this.toRender(user, closeChatRequest, sendTranscriptionRequest)}
          </TranscriptionPageContainer>
        }
      </>
    )
  }
}

/********** region STYLE */

const TranscriptionPageContainer = styled.div`
  font-family: 'Open Sans',sans-serif !important;
  color: #000000;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  & img {
    width: 120px;
    height: auto;
  }`

/********** endregion */

export default connect(mapStateToProps, {sendTranscriptionRequest, closeChatRequest})(TranscriptionPage)
