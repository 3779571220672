import React from 'react'
import ErrorView from '../ErrorView'
import InformationFormContainer from '../InformationPage/InformationFormContainer'
import DepartmentList from '../DepartmentsPage/DepartmentList'
import DepartmentClosed from '../DepartmentsPage/DepartmentClosed/DepartmentClosed'
import PrivacyForm from '../PrivacyPage/PrivacyForm'
import TranscriptionPage from '../TranscriptionPage/TranscriptionPage'
import WidgetChat from '../ChatPage/WidgetChat'
import Feedback from '../FeedbackPage/Feedback'
import Loading from '../Loading'


const ComponentsFactory = ({ component }) => {

  const components = {
    error : ErrorView,
    information : InformationFormContainer,
    deplist : DepartmentList,
    depclosed : DepartmentClosed,
    privacy : PrivacyForm,
    feedback : Feedback,
    transcription : TranscriptionPage,
    chat : WidgetChat,
    loading : Loading,
    default: InformationFormContainer
  }

  const TagName = components[component || 'loading']

  return(
    <TagName />
  )
}

export default ComponentsFactory
