const emoji = {
  "smileyspeople": [
    {
      "codes": [
        "0x1F600"
      ],
      "description": "grinning face"
    },
    {
      "codes": [
        "0x1F601"
      ],
      "description": "beaming face with smiling eyes"
    },
    {
      "codes": [
        "0x1F602"
      ],
      "description": "face with tears of joy"
    },
    {
      "codes": [
        "0x1F923"
      ],
      "description": "rolling on the floor laughing"
    },
    {
      "codes": [
        "0x1F603"
      ],
      "description": "grinning face with big eyes"
    },
    {
      "codes": [
        "0x1F604"
      ],
      "description": "grinning face with smiling eyes"
    },
    {
      "codes": [
        "0x1F605"
      ],
      "description": "grinning face with sweat"
    },
    {
      "codes": [
        "0x1F606"
      ],
      "description": "grinning squinting face"
    },
    {
      "codes": [
        "0x1F609"
      ],
      "description": "winking face"
    },
    {
      "codes": [
        "0x1F60A"
      ],
      "description": "smiling face with smiling eyes"
    },
    {
      "codes": [
        "0x1F60B"
      ],
      "description": "face savoring food"
    },
    {
      "codes": [
        "0x1F60E"
      ],
      "description": "smiling face with sunglasses"
    },
    {
      "codes": [
        "0x1F60D"
      ],
      "description": "smiling face with hearteyes"
    },
    {
      "codes": [
        "0x1F618"
      ],
      "description": "face blowing a kiss"
    },
    {
      "codes": [
        "0x1F617"
      ],
      "description": "kissing face"
    },
    {
      "codes": [
        "0x1F619"
      ],
      "description": "kissing face with smiling eyes"
    },
    {
      "codes": [
        "0x1F61A"
      ],
      "description": "kissing face with closed eyes"
    },
    {
      "codes": [
        "0x1F642"
      ],
      "description": "slightly smiling face"
    },
    {
      "codes": [
        "0x1F917"
      ],
      "description": "hugging face"
    },
    {
      "codes": [
        "0x1F929"
      ],
      "description": "starstruck"
    },
    {
      "codes": [
        "0x1F914"
      ],
      "description": "thinking face"
    },
    {
      "codes": [
        "0x1F928"
      ],
      "description": "face with raised eyebrow"
    },
    {
      "codes": [
        "0x1F610"
      ],
      "description": "neutral face"
    },
    {
      "codes": [
        "0x1F611"
      ],
      "description": "expressionless face"
    },
    {
      "codes": [
        "0x1F636"
      ],
      "description": "face without mouth"
    },
    {
      "codes": [
        "0x1F644"
      ],
      "description": "face with rolling eyes"
    },
    {
      "codes": [
        "0x1F60F"
      ],
      "description": "smirking face"
    },
    {
      "codes": [
        "0x1F623"
      ],
      "description": "persevering face"
    },
    {
      "codes": [
        "0x1F625"
      ],
      "description": "sad but relieved face"
    },
    {
      "codes": [
        "0x1F62E"
      ],
      "description": "face with open mouth"
    },
    {
      "codes": [
        "0x1F910"
      ],
      "description": "zippermouth face"
    },
    {
      "codes": [
        "0x1F62F"
      ],
      "description": "hushed face"
    },
    {
      "codes": [
        "0x1F62A"
      ],
      "description": "sleepy face"
    },
    {
      "codes": [
        "0x1F62B"
      ],
      "description": "tired face"
    },
    {
      "codes": [
        "0x1F634"
      ],
      "description": "sleeping face"
    },
    {
      "codes": [
        "0x1F60C"
      ],
      "description": "relieved face"
    },
    {
      "codes": [
        "0x1F61B"
      ],
      "description": "face with tongue"
    },
    {
      "codes": [
        "0x1F61C"
      ],
      "description": "winking face with tongue"
    },
    {
      "codes": [
        "0x1F61D"
      ],
      "description": "squinting face with tongue"
    },
    {
      "codes": [
        "0x1F924"
      ],
      "description": "drooling face"
    },
    {
      "codes": [
        "0x1F612"
      ],
      "description": "unamused face"
    },
    {
      "codes": [
        "0x1F613"
      ],
      "description": "downcast face with sweat"
    },
    {
      "codes": [
        "0x1F614"
      ],
      "description": "pensive face"
    },
    {
      "codes": [
        "0x1F615"
      ],
      "description": "confused face"
    },
    {
      "codes": [
        "0x1F643"
      ],
      "description": "upsidedown face"
    },
    {
      "codes": [
        "0x1F911"
      ],
      "description": "moneymouth face"
    },
    {
      "codes": [
        "0x1F632"
      ],
      "description": "astonished face"
    },
    {
      "codes": [
        "0x1F641"
      ],
      "description": "slightly frowning face"
    },
    {
      "codes": [
        "0x1F616"
      ],
      "description": "confounded face"
    },
    {
      "codes": [
        "0x1F61E"
      ],
      "description": "disappointed face"
    },
    {
      "codes": [
        "0x1F61F"
      ],
      "description": "worried face"
    },
    {
      "codes": [
        "0x1F624"
      ],
      "description": "face with steam from nose"
    },
    {
      "codes": [
        "0x1F622"
      ],
      "description": "crying face"
    },
    {
      "codes": [
        "0x1F62D"
      ],
      "description": "loudly crying face"
    },
    {
      "codes": [
        "0x1F626"
      ],
      "description": "frowning face with open mouth"
    },
    {
      "codes": [
        "0x1F627"
      ],
      "description": "anguished face"
    },
    {
      "codes": [
        "0x1F628"
      ],
      "description": "fearful face"
    },
    {
      "codes": [
        "0x1F629"
      ],
      "description": "weary face"
    },
    {
      "codes": [
        "0x1F92F"
      ],
      "description": "exploding head"
    },
    {
      "codes": [
        "0x1F62C"
      ],
      "description": "grimacing face"
    },
    {
      "codes": [
        "0x1F630"
      ],
      "description": "anxious face with sweat"
    },
    {
      "codes": [
        "0x1F631"
      ],
      "description": "face screaming in fear"
    },
    {
      "codes": [
        "0x1F633"
      ],
      "description": "flushed face"
    },
    {
      "codes": [
        "0x1F92A"
      ],
      "description": "zany face"
    },
    {
      "codes": [
        "0x1F635"
      ],
      "description": "dizzy face"
    },
    {
      "codes": [
        "0x1F621"
      ],
      "description": "pouting face"
    },
    {
      "codes": [
        "0x1F620"
      ],
      "description": "angry face"
    },
    {
      "codes": [
        "0x1F92C"
      ],
      "description": "face with symbols on mouth"
    },
    {
      "codes": [
        "0x1F637"
      ],
      "description": "face with medical mask"
    },
    {
      "codes": [
        "0x1F912"
      ],
      "description": "face with thermometer"
    },
    {
      "codes": [
        "0x1F915"
      ],
      "description": "face with headbandage"
    },
    {
      "codes": [
        "0x1F922"
      ],
      "description": "nauseated face"
    },
    {
      "codes": [
        "0x1F92E"
      ],
      "description": "face vomiting"
    },
    {
      "codes": [
        "0x1F927"
      ],
      "description": "sneezing face"
    },
    {
      "codes": [
        "0x1F607"
      ],
      "description": "smiling face with halo"
    },
    {
      "codes": [
        "0x1F920"
      ],
      "description": "cowboy hat face"
    },
    {
      "codes": [
        "0x1F925"
      ],
      "description": "lying face"
    },
    {
      "codes": [
        "0x1F92B"
      ],
      "description": "shushing face"
    },
    {
      "codes": [
        "0x1F92D"
      ],
      "description": "face with hand over mouth"
    },
    {
      "codes": [
        "0x1F9D0"
      ],
      "description": "face with monocle"
    },
    {
      "codes": [
        "0x1F913"
      ],
      "description": "nerd face"
    },
    {
      "codes": [
        "0x1F608"
      ],
      "description": "smiling face with horns"
    },
    {
      "codes": [
        "0x1F47F"
      ],
      "description": "angry face with horns"
    },
    {
      "codes": [
        "0x1F921"
      ],
      "description": "clown face"
    },
    {
      "codes": [
        "0x1F479"
      ],
      "description": "ogre"
    },
    {
      "codes": [
        "0x1F47A"
      ],
      "description": "goblin"
    },
    {
      "codes": [
        "0x1F480"
      ],
      "description": "skull"
    },
    {
      "codes": [
        "0x1F47B"
      ],
      "description": "ghost"
    },
    {
      "codes": [
        "0x1F47D"
      ],
      "description": "alien"
    },
    {
      "codes": [
        "0x1F47E"
      ],
      "description": "alien monster"
    },
    {
      "codes": [
        "0x1F916"
      ],
      "description": "robot face"
    },
    {
      "codes": [
        "0x1F4A9"
      ],
      "description": "pile of poo"
    },
    {
      "codes": [
        "0x1F63A"
      ],
      "description": "grinning cat face"
    },
    {
      "codes": [
        "0x1F638"
      ],
      "description": "grinning cat face with smiling eyes"
    },
    {
      "codes": [
        "0x1F639"
      ],
      "description": "cat face with tears of joy"
    },
    {
      "codes": [
        "0x1F63B"
      ],
      "description": "smiling cat face with hearteyes"
    },
    {
      "codes": [
        "0x1F63C"
      ],
      "description": "cat face with wry smile"
    },
    {
      "codes": [
        "0x1F63D"
      ],
      "description": "kissing cat face"
    },
    {
      "codes": [
        "0x1F640"
      ],
      "description": "weary cat face"
    },
    {
      "codes": [
        "0x1F63F"
      ],
      "description": "crying cat face"
    },
    {
      "codes": [
        "0x1F63E"
      ],
      "description": "pouting cat face"
    },
    {
      "codes": [
        "0x1F648"
      ],
      "description": "seenoevil monkey"
    },
    {
      "codes": [
        "0x1F649"
      ],
      "description": "hearnoevil monkey"
    },
    {
      "codes": [
        "0x1F64A"
      ],
      "description": "speaknoevil monkey"
    },
    {
      "codes": [
        "0x1F3FB"
      ],
      "description": "light skin tone"
    },
    {
      "codes": [
        "0x1F3FC"
      ],
      "description": "mediumlight skin tone"
    },
    {
      "codes": [
        "0x1F3FD"
      ],
      "description": "medium skin tone"
    },
    {
      "codes": [
        "0x1F3FE"
      ],
      "description": "mediumdark skin tone"
    },
    {
      "codes": [
        "0x1F3FF"
      ],
      "description": "dark skin tone"
    },
    {
      "codes": [
        "0x1F476"
      ],
      "description": "baby"
    },
    {
      "codes": [
        "0x1F9D2"
      ],
      "description": "child"
    },
    {
      "codes": [
        "0x1F466"
      ],
      "description": "boy"
    },
    {
      "codes": [
        "0x1F467"
      ],
      "description": "girl"
    },
    {
      "codes": [
        "0x1F9D1"
      ],
      "description": "adult"
    },
    {
      "codes": [
        "0x1F468"
      ],
      "description": "man"
    },
    {
      "codes": [
        "0x1F469"
      ],
      "description": "woman"
    },
    {
      "codes": [
        "0x1F9D3"
      ],
      "description": "older adult"
    },
    {
      "codes": [
        "0x1F474"
      ],
      "description": "old man"
    },
    {
      "codes": [
        "0x1F475"
      ],
      "description": "old woman"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x2695",
        "0xFE0F"
      ],
      "description": "man health worker"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2695",
        "0xFE0F"
      ],
      "description": "woman health worker"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F393"
      ],
      "description": "man student"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F393"
      ],
      "description": "woman student"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F3EB"
      ],
      "description": "man teacher"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F3EB"
      ],
      "description": "woman teacher"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x2696",
        "0xFE0F"
      ],
      "description": "man judge"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2696",
        "0xFE0F"
      ],
      "description": "woman judge"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F33E"
      ],
      "description": "man farmer"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F33E"
      ],
      "description": "woman farmer"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F373"
      ],
      "description": "man cook"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F373"
      ],
      "description": "woman cook"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F527"
      ],
      "description": "man mechanic"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F527"
      ],
      "description": "woman mechanic"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F3ED"
      ],
      "description": "man factory worker"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F3ED"
      ],
      "description": "woman factory worker"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F4BC"
      ],
      "description": "man office worker"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F4BC"
      ],
      "description": "woman office worker"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F52C"
      ],
      "description": "man scientist"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F52C"
      ],
      "description": "woman scientist"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F4BB"
      ],
      "description": "man technologist"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F4BB"
      ],
      "description": "woman technologist"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F3A4"
      ],
      "description": "man singer"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F3A4"
      ],
      "description": "woman singer"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F3A8"
      ],
      "description": "man artist"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F3A8"
      ],
      "description": "woman artist"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x2708",
        "0xFE0F"
      ],
      "description": "man pilot"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2708",
        "0xFE0F"
      ],
      "description": "woman pilot"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F680"
      ],
      "description": "man astronaut"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F680"
      ],
      "description": "woman astronaut"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F692"
      ],
      "description": "man firefighter"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F692"
      ],
      "description": "woman firefighter"
    },
    {
      "codes": [
        "0x1F46E"
      ],
      "description": "police officer"
    },
    {
      "codes": [
        "0x1F575",
        "0xFE0F"
      ],
      "description": "detective"
    },
    {
      "codes": [
        "0x1F482"
      ],
      "description": "guard"
    },
    {
      "codes": [
        "0x1F477"
      ],
      "description": "construction worker"
    },
    {
      "codes": [
        "0x1F934"
      ],
      "description": "prince"
    },
    {
      "codes": [
        "0x1F478"
      ],
      "description": "princess"
    },
    {
      "codes": [
        "0x1F473"
      ],
      "description": "person wearing turban"
    },
    {
      "codes": [
        "0x1F472"
      ],
      "description": "man with Chinese cap"
    },
    {
      "codes": [
        "0x1F9D5"
      ],
      "description": "woman with headscarf"
    },
    {
      "codes": [
        "0x1F9D4"
      ],
      "description": "bearded person"
    },
    {
      "codes": [
        "0x1F471"
      ],
      "description": "blondhaired person"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F9B0"
      ],
      "description": "man red haired"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F9B0"
      ],
      "description": "woman red haired"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F9B1"
      ],
      "description": "man curly haired"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F9B1"
      ],
      "description": "woman curly haired"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F9B2"
      ],
      "description": "man bald"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F9B2"
      ],
      "description": "woman bald"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F9B3"
      ],
      "description": "man white haired"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F9B3"
      ],
      "description": "woman white haired"
    },
    {
      "codes": [
        "0x1F935"
      ],
      "description": "man in tuxedo"
    },
    {
      "codes": [
        "0x1F470"
      ],
      "description": "bride with veil"
    },
    {
      "codes": [
        "0x1F930"
      ],
      "description": "pregnant woman"
    },
    {
      "codes": [
        "0x1F931"
      ],
      "description": "breastfeeding"
    },
    {
      "codes": [
        "0x1F47C"
      ],
      "description": "baby angel"
    },
    {
      "codes": [
        "0x1F385"
      ],
      "description": "Santa Claus"
    },
    {
      "codes": [
        "0x1F936"
      ],
      "description": "Mrs Claus"
    },
    {
      "codes": [
        "0x1F9D9"
      ],
      "description": "mage"
    },
    {
      "codes": [
        "0x1F9DA"
      ],
      "description": "fairy"
    },
    {
      "codes": [
        "0x1F9DB"
      ],
      "description": "vampire"
    },
    {
      "codes": [
        "0x1F9DC"
      ],
      "description": "merperson"
    },
    {
      "codes": [
        "0x1F9DD"
      ],
      "description": "elf"
    },
    {
      "codes": [
        "0x1F9DE"
      ],
      "description": "genie"
    },
    {
      "codes": [
        "0x1F9DF"
      ],
      "description": "zombie"
    },
    {
      "codes": [
        "0x1F64D"
      ],
      "description": "person frowning"
    },
    {
      "codes": [
        "0x1F64E"
      ],
      "description": "person pouting"
    },
    {
      "codes": [
        "0x1F645"
      ],
      "description": "person gesturing NO"
    },
    {
      "codes": [
        "0x1F646"
      ],
      "description": "person gesturing OK"
    },
    {
      "codes": [
        "0x1F481"
      ],
      "description": "person tipping hand"
    },
    {
      "codes": [
        "0x1F64B"
      ],
      "description": "person raising hand"
    },
    {
      "codes": [
        "0x1F647"
      ],
      "description": "person bowing"
    },
    {
      "codes": [
        "0x1F926"
      ],
      "description": "person facepalming"
    },
    {
      "codes": [
        "0x1F937"
      ],
      "description": "person shrugging"
    },
    {
      "codes": [
        "0x1F486"
      ],
      "description": "person getting massage"
    },
    {
      "codes": [
        "0x1F487"
      ],
      "description": "person getting haircut"
    },
    {
      "codes": [
        "0x1F6B6"
      ],
      "description": "person walking"
    },
    {
      "codes": [
        "0x1F3C3"
      ],
      "description": "person running"
    },
    {
      "codes": [
        "0x1F483"
      ],
      "description": "woman dancing"
    },
    {
      "codes": [
        "0x1F57A"
      ],
      "description": "man dancing"
    },
    {
      "codes": [
        "0x1F46F"
      ],
      "description": "people with bunny ears"
    },
    {
      "codes": [
        "0x1F9D6"
      ],
      "description": "person in steamy room"
    },
    {
      "codes": [
        "0x1F9D7"
      ],
      "description": "person climbing"
    },
    {
      "codes": [
        "0x1F9D8"
      ],
      "description": "person in lotus position"
    },
    {
      "codes": [
        "0x1F6C0"
      ],
      "description": "person taking bath"
    },
    {
      "codes": [
        "0x1F6CC"
      ],
      "description": "person in bed"
    },
    {
      "codes": [
        "0x1F574",
        "0xFE0F"
      ],
      "description": "man in suit levitating"
    },
    {
      "codes": [
        "0x1F5E3",
        "0xFE0F"
      ],
      "description": "speaking head"
    },
    {
      "codes": [
        "0x1F464"
      ],
      "description": "bust in silhouette"
    },
    {
      "codes": [
        "0x1F465"
      ],
      "description": "busts in silhouette"
    },
    {
      "codes": [
        "0x1F93A"
      ],
      "description": "person fencing"
    },
    {
      "codes": [
        "0x1F3C7"
      ],
      "description": "horse racing"
    },
    {
      "codes": [
        "0x26F7",
        "0xFE0F"
      ],
      "description": "skier"
    },
    {
      "codes": [
        "0x1F3C2"
      ],
      "description": "snowboarder"
    },
    {
      "codes": [
        "0x1F3CC",
        "0xFE0F"
      ],
      "description": "person golfing"
    },
    {
      "codes": [
        "0x1F3C4"
      ],
      "description": "person surfing"
    },
    {
      "codes": [
        "0x1F6A3"
      ],
      "description": "person rowing boat"
    },
    {
      "codes": [
        "0x1F3CA"
      ],
      "description": "person swimming"
    },
    {
      "codes": [
        "0x26F9",
        "0xFE0F"
      ],
      "description": "person bouncing ball"
    },
    {
      "codes": [
        "0x1F3CB",
        "0xFE0F"
      ],
      "description": "person lifting weights"
    },
    {
      "codes": [
        "0x1F6B4"
      ],
      "description": "person biking"
    },
    {
      "codes": [
        "0x1F6B5"
      ],
      "description": "person mountain biking"
    },
    {
      "codes": [
        "0x1F3CE",
        "0xFE0F"
      ],
      "description": "racing car"
    },
    {
      "codes": [
        "0x1F3CD",
        "0xFE0F"
      ],
      "description": "motorcycle"
    },
    {
      "codes": [
        "0x1F938"
      ],
      "description": "person cartwheeling"
    },
    {
      "codes": [
        "0x1F93C"
      ],
      "description": "people wrestling"
    },
    {
      "codes": [
        "0x1F93D"
      ],
      "description": "person playing water polo"
    },
    {
      "codes": [
        "0x1F93E"
      ],
      "description": "person playing handball"
    },
    {
      "codes": [
        "0x1F939"
      ],
      "description": "person juggling"
    },
    {
      "codes": [
        "0x1F46B"
      ],
      "description": "man and woman holding hands"
    },
    {
      "codes": [
        "0x1F46C"
      ],
      "description": "two men holding hands"
    },
    {
      "codes": [
        "0x1F46D"
      ],
      "description": "two women holding hands"
    },
    {
      "codes": [
        "0x1F48F"
      ],
      "description": "kiss"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2764",
        "0xFE0F",
        "0x200D",
        "0x1F48B",
        "0x200D",
        "0x1F468"
      ],
      "description": "kiss woman man"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x2764",
        "0xFE0F",
        "0x200D",
        "0x1F48B",
        "0x200D",
        "0x1F468"
      ],
      "description": "kiss man man"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2764",
        "0xFE0F",
        "0x200D",
        "0x1F48B",
        "0x200D",
        "0x1F469"
      ],
      "description": "kiss woman woman"
    },
    {
      "codes": [
        "0x1F491"
      ],
      "description": "couple with heart"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2764",
        "0xFE0F",
        "0x200D",
        "0x1F468"
      ],
      "description": "couple with heart woman man"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x2764",
        "0xFE0F",
        "0x200D",
        "0x1F468"
      ],
      "description": "couple with heart man man"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x2764",
        "0xFE0F",
        "0x200D",
        "0x1F469"
      ],
      "description": "couple with heart woman woman"
    },
    {
      "codes": [
        "0x1F46A"
      ],
      "description": "family"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F469",
        "0x200D",
        "0x1F466"
      ],
      "description": "family man woman boy"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F469",
        "0x200D",
        "0x1F466"
      ],
      "description": "family woman woman boy"
    },
    {
      "codes": [
        "0x1F468",
        "0x200D",
        "0x1F466"
      ],
      "description": "family man boy"
    },
    {
      "codes": [
        "0x1F469",
        "0x200D",
        "0x1F466"
      ],
      "description": "family woman boy"
    },
    {
      "codes": [
        "0x1F933"
      ],
      "description": "selfie"
    },
    {
      "codes": [
        "0x1F4AA"
      ],
      "description": "flexed biceps"
    },
    {
      "codes": [
        "0x1F448"
      ],
      "description": "backhand index pointing left"
    },
    {
      "codes": [
        "0x1F449"
      ],
      "description": "backhand index pointing right"
    },
    {
      "codes": [
        "0x261D",
        "0xFE0F"
      ],
      "description": "index pointing up"
    },
    {
      "codes": [
        "0x1F446"
      ],
      "description": "backhand index pointing up"
    },
    {
      "codes": [
        "0x1F595"
      ],
      "description": "middle finger"
    },
    {
      "codes": [
        "0x1F447"
      ],
      "description": "backhand index pointing down"
    },
    {
      "codes": [
        "0x270C",
        "0xFE0F"
      ],
      "description": "victory hand"
    },
    {
      "codes": [
        "0x1F91E"
      ],
      "description": "crossed fingers"
    },
    {
      "codes": [
        "0x1F596"
      ],
      "description": "vulcan salute"
    },
    {
      "codes": [
        "0x1F918"
      ],
      "description": "sign of the horns"
    },
    {
      "codes": [
        "0x1F919"
      ],
      "description": "call me hand"
    },
    {
      "codes": [
        "0x1F590",
        "0xFE0F"
      ],
      "description": "hand with fingers splayed"
    },
    {
      "codes": [
        "0x270B"
      ],
      "description": "raised hand"
    },
    {
      "codes": [
        "0x1F44C"
      ],
      "description": "OK hand"
    },
    {
      "codes": [
        "0x1F44D"
      ],
      "description": "thumbs up"
    },
    {
      "codes": [
        "0x1F44E"
      ],
      "description": "thumbs down"
    },
    {
      "codes": [
        "0x270A"
      ],
      "description": "raised fist"
    },
    {
      "codes": [
        "0x1F44A"
      ],
      "description": "oncoming fist"
    },
    {
      "codes": [
        "0x1F91B"
      ],
      "description": "leftfacing fist"
    },
    {
      "codes": [
        "0x1F91C"
      ],
      "description": "rightfacing fist"
    },
    {
      "codes": [
        "0x1F91A"
      ],
      "description": "raised back of hand"
    },
    {
      "codes": [
        "0x1F44B"
      ],
      "description": "waving hand"
    },
    {
      "codes": [
        "0x1F91F"
      ],
      "description": "loveyou gesture"
    },
    {
      "codes": [
        "0x270D",
        "0xFE0F"
      ],
      "description": "writing hand"
    },
    {
      "codes": [
        "0x1F44F"
      ],
      "description": "clapping hands"
    },
    {
      "codes": [
        "0x1F450"
      ],
      "description": "open hands"
    },
    {
      "codes": [
        "0x1F64C"
      ],
      "description": "raising hands"
    },
    {
      "codes": [
        "0x1F932"
      ],
      "description": "palms up together"
    },
    {
      "codes": [
        "0x1F64F"
      ],
      "description": "folded hands"
    },
    {
      "codes": [
        "0x1F91D"
      ],
      "description": "handshake"
    },
    {
      "codes": [
        "0x1F485"
      ],
      "description": "nail polish"
    },
    {
      "codes": [
        "0x1F442"
      ],
      "description": "ear"
    },
    {
      "codes": [
        "0x1F443"
      ],
      "description": "nose"
    },
    {
      "codes": [
        "0x1F463"
      ],
      "description": "footprints"
    },
    {
      "codes": [
        "0x1F440"
      ],
      "description": "eyes"
    },
    {
      "codes": [
        "0x1F441",
        "0xFE0F"
      ],
      "description": "eye"
    },
    {
      "codes": [
        "0x1F9E0"
      ],
      "description": "brain"
    },
    {
      "codes": [
        "0x1F445"
      ],
      "description": "tongue"
    },
    {
      "codes": [
        "0x1F444"
      ],
      "description": "mouth"
    },
    {
      "codes": [
        "0x1F48B"
      ],
      "description": "kiss mark"
    },
    {
      "codes": [
        "0x1F498"
      ],
      "description": "heart with arrow"
    },
    {
      "codes": [
        "0x2764",
        "0xFE0F"
      ],
      "description": "red heart"
    },
    {
      "codes": [
        "0x1F493"
      ],
      "description": "beating heart"
    },
    {
      "codes": [
        "0x1F494"
      ],
      "description": "broken heart"
    },
    {
      "codes": [
        "0x1F495"
      ],
      "description": "two hearts"
    },
    {
      "codes": [
        "0x1F496"
      ],
      "description": "sparkling heart"
    },
    {
      "codes": [
        "0x1F497"
      ],
      "description": "growing heart"
    },
    {
      "codes": [
        "0x1F499"
      ],
      "description": "blue heart"
    },
    {
      "codes": [
        "0x1F49A"
      ],
      "description": "green heart"
    },
    {
      "codes": [
        "0x1F49B"
      ],
      "description": "yellow heart"
    },
    {
      "codes": [
        "0x1F9E1"
      ],
      "description": "orange heart"
    },
    {
      "codes": [
        "0x1F49C"
      ],
      "description": "purple heart"
    },
    {
      "codes": [
        "0x1F5A4"
      ],
      "description": "black heart"
    },
    {
      "codes": [
        "0x1F49D"
      ],
      "description": "heart with ribbon"
    },
    {
      "codes": [
        "0x1F49E"
      ],
      "description": "revolving hearts"
    },
    {
      "codes": [
        "0x1F49F"
      ],
      "description": "heart decoration"
    },
    {
      "codes": [
        "0x2763",
        "0xFE0F"
      ],
      "description": "heavy heart exclamation"
    },
    {
      "codes": [
        "0x1F48C"
      ],
      "description": "love letter"
    },
    {
      "codes": [
        "0x1F4A4"
      ],
      "description": "zzz"
    },
    {
      "codes": [
        "0x1F4A2"
      ],
      "description": "anger symbol"
    },
    {
      "codes": [
        "0x1F4A3"
      ],
      "description": "bomb"
    },
    {
      "codes": [
        "0x1F4A5"
      ],
      "description": "collision"
    },
    {
      "codes": [
        "0x1F4A6"
      ],
      "description": "sweat droplets"
    },
    {
      "codes": [
        "0x1F4A8"
      ],
      "description": "dashing away"
    },
    {
      "codes": [
        "0x1F4AB"
      ],
      "description": "dizzy"
    },
    {
      "codes": [
        "0x1F4AC"
      ],
      "description": "speech balloon"
    },
    {
      "codes": [
        "0x1F5E8",
        "0xFE0F"
      ],
      "description": "left speech bubble"
    },
    {
      "codes": [
        "0x1F5EF",
        "0xFE0F"
      ],
      "description": "right anger bubble"
    },
    {
      "codes": [
        "0x1F4AD"
      ],
      "description": "thought balloon"
    },
    {
      "codes": [
        "0x1F573",
        "0xFE0F"
      ],
      "description": "hole"
    },
    {
      "codes": [
        "0x1F453"
      ],
      "description": "glasses"
    },
    {
      "codes": [
        "0x1F576",
        "0xFE0F"
      ],
      "description": "sunglasses"
    },
    {
      "codes": [
        "0x1F454"
      ],
      "description": "necktie"
    },
    {
      "codes": [
        "0x1F455"
      ],
      "description": "tshirt"
    },
    {
      "codes": [
        "0x1F456"
      ],
      "description": "jeans"
    },
    {
      "codes": [
        "0x1F9E3"
      ],
      "description": "scarf"
    },
    {
      "codes": [
        "0x1F9E4"
      ],
      "description": "gloves"
    },
    {
      "codes": [
        "0x1F9E5"
      ],
      "description": "coat"
    },
    {
      "codes": [
        "0x1F9E6"
      ],
      "description": "socks"
    },
    {
      "codes": [
        "0x1F457"
      ],
      "description": "dress"
    },
    {
      "codes": [
        "0x1F458"
      ],
      "description": "kimono"
    },
    {
      "codes": [
        "0x1F459"
      ],
      "description": "bikini"
    },
    {
      "codes": [
        "0x1F45A"
      ],
      "description": "womans clothes"
    },
    {
      "codes": [
        "0x1F45B"
      ],
      "description": "purse"
    },
    {
      "codes": [
        "0x1F45C"
      ],
      "description": "handbag"
    },
    {
      "codes": [
        "0x1F45D"
      ],
      "description": "clutch bag"
    },
    {
      "codes": [
        "0x1F6CD",
        "0xFE0F"
      ],
      "description": "shopping bags"
    },
    {
      "codes": [
        "0x1F392"
      ],
      "description": "school backpack"
    },
    {
      "codes": [
        "0x1F45E"
      ],
      "description": "mans shoe"
    },
    {
      "codes": [
        "0x1F45F"
      ],
      "description": "running shoe"
    },
    {
      "codes": [
        "0x1F460"
      ],
      "description": "highheeled shoe"
    },
    {
      "codes": [
        "0x1F461"
      ],
      "description": "womans sandal"
    },
    {
      "codes": [
        "0x1F462"
      ],
      "description": "womans boot"
    },
    {
      "codes": [
        "0x1F451"
      ],
      "description": "crown"
    },
    {
      "codes": [
        "0x1F452"
      ],
      "description": "womans hat"
    },
    {
      "codes": [
        "0x1F3A9"
      ],
      "description": "top hat"
    },
    {
      "codes": [
        "0x1F393"
      ],
      "description": "graduation cap"
    },
    {
      "codes": [
        "0x1F9E2"
      ],
      "description": "billed cap"
    },
    {
      "codes": [
        "0x26D1",
        "0xFE0F"
      ],
      "description": "rescue workers helmet"
    },
    {
      "codes": [
        "0x1F4FF"
      ],
      "description": "prayer beads"
    },
    {
      "codes": [
        "0x1F484"
      ],
      "description": "lipstick"
    },
    {
      "codes": [
        "0x1F48D"
      ],
      "description": "ring"
    },
    {
      "codes": [
        "0x1F48E"
      ],
      "description": "gem stone"
    }
  ],
  "animalsnature": [
    {
      "codes": [
        "0x1F435"
      ],
      "description": "monkey face"
    },
    {
      "codes": [
        "0x1F412"
      ],
      "description": "monkey"
    },
    {
      "codes": [
        "0x1F98D"
      ],
      "description": "gorilla"
    },
    {
      "codes": [
        "0x1F436"
      ],
      "description": "dog face"
    },
    {
      "codes": [
        "0x1F415"
      ],
      "description": "dog"
    },
    {
      "codes": [
        "0x1F429"
      ],
      "description": "poodle"
    },
    {
      "codes": [
        "0x1F43A"
      ],
      "description": "wolf face"
    },
    {
      "codes": [
        "0x1F98A"
      ],
      "description": "fox face"
    },
    {
      "codes": [
        "0x1F431"
      ],
      "description": "cat face"
    },
    {
      "codes": [
        "0x1F408"
      ],
      "description": "cat"
    },
    {
      "codes": [
        "0x1F981"
      ],
      "description": "lion face"
    },
    {
      "codes": [
        "0x1F42F"
      ],
      "description": "tiger face"
    },
    {
      "codes": [
        "0x1F405"
      ],
      "description": "tiger"
    },
    {
      "codes": [
        "0x1F406"
      ],
      "description": "leopard"
    },
    {
      "codes": [
        "0x1F434"
      ],
      "description": "horse face"
    },
    {
      "codes": [
        "0x1F40E"
      ],
      "description": "horse"
    },
    {
      "codes": [
        "0x1F984"
      ],
      "description": "unicorn face"
    },
    {
      "codes": [
        "0x1F993"
      ],
      "description": "zebra"
    },
    {
      "codes": [
        "0x1F98C"
      ],
      "description": "deer"
    },
    {
      "codes": [
        "0x1F42E"
      ],
      "description": "cow face"
    },
    {
      "codes": [
        "0x1F402"
      ],
      "description": "ox"
    },
    {
      "codes": [
        "0x1F403"
      ],
      "description": "water buffalo"
    },
    {
      "codes": [
        "0x1F404"
      ],
      "description": "cow"
    },
    {
      "codes": [
        "0x1F437"
      ],
      "description": "pig face"
    },
    {
      "codes": [
        "0x1F416"
      ],
      "description": "pig"
    },
    {
      "codes": [
        "0x1F417"
      ],
      "description": "boar"
    },
    {
      "codes": [
        "0x1F43D"
      ],
      "description": "pig nose"
    },
    {
      "codes": [
        "0x1F40F"
      ],
      "description": "ram"
    },
    {
      "codes": [
        "0x1F411"
      ],
      "description": "ewe"
    },
    {
      "codes": [
        "0x1F410"
      ],
      "description": "goat"
    },
    {
      "codes": [
        "0x1F42A"
      ],
      "description": "camel"
    },
    {
      "codes": [
        "0x1F42B"
      ],
      "description": "twohump camel"
    },
    {
      "codes": [
        "0x1F992"
      ],
      "description": "giraffe"
    },
    {
      "codes": [
        "0x1F418"
      ],
      "description": "elephant"
    },
    {
      "codes": [
        "0x1F98F"
      ],
      "description": "rhinoceros"
    },
    {
      "codes": [
        "0x1F42D"
      ],
      "description": "mouse face"
    },
    {
      "codes": [
        "0x1F401"
      ],
      "description": "mouse"
    },
    {
      "codes": [
        "0x1F400"
      ],
      "description": "rat"
    },
    {
      "codes": [
        "0x1F439"
      ],
      "description": "hamster face"
    },
    {
      "codes": [
        "0x1F430"
      ],
      "description": "rabbit face"
    },
    {
      "codes": [
        "0x1F407"
      ],
      "description": "rabbit"
    },
    {
      "codes": [
        "0x1F43F",
        "0xFE0F"
      ],
      "description": "chipmunk"
    },
    {
      "codes": [
        "0x1F994"
      ],
      "description": "hedgehog"
    },
    {
      "codes": [
        "0x1F987"
      ],
      "description": "bat"
    },
    {
      "codes": [
        "0x1F43B"
      ],
      "description": "bear face"
    },
    {
      "codes": [
        "0x1F428"
      ],
      "description": "koala"
    },
    {
      "codes": [
        "0x1F43C"
      ],
      "description": "panda face"
    },
    {
      "codes": [
        "0x1F43E"
      ],
      "description": "paw prints"
    },
    {
      "codes": [
        "0x1F983"
      ],
      "description": "turkey"
    },
    {
      "codes": [
        "0x1F414"
      ],
      "description": "chicken"
    },
    {
      "codes": [
        "0x1F413"
      ],
      "description": "rooster"
    },
    {
      "codes": [
        "0x1F423"
      ],
      "description": "hatching chick"
    },
    {
      "codes": [
        "0x1F424"
      ],
      "description": "baby chick"
    },
    {
      "codes": [
        "0x1F425"
      ],
      "description": "frontfacing baby chick"
    },
    {
      "codes": [
        "0x1F426"
      ],
      "description": "bird"
    },
    {
      "codes": [
        "0x1F427"
      ],
      "description": "penguin"
    },
    {
      "codes": [
        "0x1F985"
      ],
      "description": "eagle"
    },
    {
      "codes": [
        "0x1F986"
      ],
      "description": "duck"
    },
    {
      "codes": [
        "0x1F989"
      ],
      "description": "owl"
    },
    {
      "codes": [
        "0x1F438"
      ],
      "description": "frog face"
    },
    {
      "codes": [
        "0x1F40A"
      ],
      "description": "crocodile"
    },
    {
      "codes": [
        "0x1F422"
      ],
      "description": "turtle"
    },
    {
      "codes": [
        "0x1F98E"
      ],
      "description": "lizard"
    },
    {
      "codes": [
        "0x1F40D"
      ],
      "description": "snake"
    },
    {
      "codes": [
        "0x1F432"
      ],
      "description": "dragon face"
    },
    {
      "codes": [
        "0x1F409"
      ],
      "description": "dragon"
    },
    {
      "codes": [
        "0x1F995"
      ],
      "description": "sauropod"
    },
    {
      "codes": [
        "0x1F996"
      ],
      "description": "TRex"
    },
    {
      "codes": [
        "0x1F433"
      ],
      "description": "spouting whale"
    },
    {
      "codes": [
        "0x1F40B"
      ],
      "description": "whale"
    },
    {
      "codes": [
        "0x1F42C"
      ],
      "description": "dolphin"
    },
    {
      "codes": [
        "0x1F41F"
      ],
      "description": "fish"
    },
    {
      "codes": [
        "0x1F420"
      ],
      "description": "tropical fish"
    },
    {
      "codes": [
        "0x1F421"
      ],
      "description": "blowfish"
    },
    {
      "codes": [
        "0x1F988"
      ],
      "description": "shark"
    },
    {
      "codes": [
        "0x1F419"
      ],
      "description": "octopus"
    },
    {
      "codes": [
        "0x1F41A"
      ],
      "description": "spiral shell"
    },
    {
      "codes": [
        "0x1F980"
      ],
      "description": "crab"
    },
    {
      "codes": [
        "0x1F990"
      ],
      "description": "shrimp"
    },
    {
      "codes": [
        "0x1F991"
      ],
      "description": "squid"
    },
    {
      "codes": [
        "0x1F40C"
      ],
      "description": "snail"
    },
    {
      "codes": [
        "0x1F98B"
      ],
      "description": "butterfly"
    },
    {
      "codes": [
        "0x1F41B"
      ],
      "description": "bug"
    },
    {
      "codes": [
        "0x1F41C"
      ],
      "description": "ant"
    },
    {
      "codes": [
        "0x1F41D"
      ],
      "description": "honeybee"
    },
    {
      "codes": [
        "0x1F41E"
      ],
      "description": "lady beetle"
    },
    {
      "codes": [
        "0x1F997"
      ],
      "description": "cricket"
    },
    {
      "codes": [
        "0x1F577",
        "0xFE0F"
      ],
      "description": "spider"
    },
    {
      "codes": [
        "0x1F578",
        "0xFE0F"
      ],
      "description": "spider web"
    },
    {
      "codes": [
        "0x1F982"
      ],
      "description": "scorpion"
    },
    {
      "codes": [
        "0x1F490"
      ],
      "description": "bouquet"
    },
    {
      "codes": [
        "0x1F338"
      ],
      "description": "cherry blossom"
    },
    {
      "codes": [
        "0x1F4AE"
      ],
      "description": "white flower"
    },
    {
      "codes": [
        "0x1F339"
      ],
      "description": "rose"
    },
    {
      "codes": [
        "0x1F940"
      ],
      "description": "wilted flower"
    },
    {
      "codes": [
        "0x1F33A"
      ],
      "description": "hibiscus"
    },
    {
      "codes": [
        "0x1F33B"
      ],
      "description": "sunflower"
    },
    {
      "codes": [
        "0x1F33C"
      ],
      "description": "blossom"
    },
    {
      "codes": [
        "0x1F337"
      ],
      "description": "tulip"
    },
    {
      "codes": [
        "0x1F331"
      ],
      "description": "seedling"
    },
    {
      "codes": [
        "0x1F332"
      ],
      "description": "evergreen tree"
    },
    {
      "codes": [
        "0x1F333"
      ],
      "description": "deciduous tree"
    },
    {
      "codes": [
        "0x1F334"
      ],
      "description": "palm tree"
    },
    {
      "codes": [
        "0x1F335"
      ],
      "description": "cactus"
    },
    {
      "codes": [
        "0x1F33E"
      ],
      "description": "sheaf of rice"
    },
    {
      "codes": [
        "0x1F33F"
      ],
      "description": "herb"
    },
    {
      "codes": [
        "0x2618",
        "0xFE0F"
      ],
      "description": "shamrock"
    },
    {
      "codes": [
        "0x1F340"
      ],
      "description": "four leaf clover"
    },
    {
      "codes": [
        "0x1F341"
      ],
      "description": "maple leaf"
    },
    {
      "codes": [
        "0x1F342"
      ],
      "description": "fallen leaf"
    },
    {
      "codes": [
        "0x1F343"
      ],
      "description": "leaf fluttering in wind"
    }
  ],
  "fooddrink": [
    {
      "codes": [
        "0x1F347"
      ],
      "description": "grapes"
    },
    {
      "codes": [
        "0x1F348"
      ],
      "description": "melon"
    },
    {
      "codes": [
        "0x1F349"
      ],
      "description": "watermelon"
    },
    {
      "codes": [
        "0x1F34A"
      ],
      "description": "tangerine"
    },
    {
      "codes": [
        "0x1F34B"
      ],
      "description": "lemon"
    },
    {
      "codes": [
        "0x1F34C"
      ],
      "description": "banana"
    },
    {
      "codes": [
        "0x1F34D"
      ],
      "description": "pineapple"
    },
    {
      "codes": [
        "0x1F34E"
      ],
      "description": "red apple"
    },
    {
      "codes": [
        "0x1F34F"
      ],
      "description": "green apple"
    },
    {
      "codes": [
        "0x1F350"
      ],
      "description": "pear"
    },
    {
      "codes": [
        "0x1F351"
      ],
      "description": "peach"
    },
    {
      "codes": [
        "0x1F352"
      ],
      "description": "cherries"
    },
    {
      "codes": [
        "0x1F353"
      ],
      "description": "strawberry"
    },
    {
      "codes": [
        "0x1F95D"
      ],
      "description": "kiwi fruit"
    },
    {
      "codes": [
        "0x1F345"
      ],
      "description": "tomato"
    },
    {
      "codes": [
        "0x1F965"
      ],
      "description": "coconut"
    },
    {
      "codes": [
        "0x1F951"
      ],
      "description": "avocado"
    },
    {
      "codes": [
        "0x1F346"
      ],
      "description": "eggplant"
    },
    {
      "codes": [
        "0x1F954"
      ],
      "description": "potato"
    },
    {
      "codes": [
        "0x1F955"
      ],
      "description": "carrot"
    },
    {
      "codes": [
        "0x1F33D"
      ],
      "description": "ear of corn"
    },
    {
      "codes": [
        "0x1F336",
        "0xFE0F"
      ],
      "description": "hot pepper"
    },
    {
      "codes": [
        "0x1F952"
      ],
      "description": "cucumber"
    },
    {
      "codes": [
        "0x1F966"
      ],
      "description": "broccoli"
    },
    {
      "codes": [
        "0x1F344"
      ],
      "description": "mushroom"
    },
    {
      "codes": [
        "0x1F95C"
      ],
      "description": "peanuts"
    },
    {
      "codes": [
        "0x1F330"
      ],
      "description": "chestnut"
    },
    {
      "codes": [
        "0x1F35E"
      ],
      "description": "bread"
    },
    {
      "codes": [
        "0x1F950"
      ],
      "description": "croissant"
    },
    {
      "codes": [
        "0x1F956"
      ],
      "description": "baguette bread"
    },
    {
      "codes": [
        "0x1F968"
      ],
      "description": "pretzel"
    },
    {
      "codes": [
        "0x1F95E"
      ],
      "description": "pancakes"
    },
    {
      "codes": [
        "0x1F9C0"
      ],
      "description": "cheese wedge"
    },
    {
      "codes": [
        "0x1F356"
      ],
      "description": "meat on bone"
    },
    {
      "codes": [
        "0x1F357"
      ],
      "description": "poultry leg"
    },
    {
      "codes": [
        "0x1F969"
      ],
      "description": "cut of meat"
    },
    {
      "codes": [
        "0x1F953"
      ],
      "description": "bacon"
    },
    {
      "codes": [
        "0x1F354"
      ],
      "description": "hamburger"
    },
    {
      "codes": [
        "0x1F35F"
      ],
      "description": "french fries"
    },
    {
      "codes": [
        "0x1F355"
      ],
      "description": "pizza"
    },
    {
      "codes": [
        "0x1F32D"
      ],
      "description": "hot dog"
    },
    {
      "codes": [
        "0x1F96A"
      ],
      "description": "sandwich"
    },
    {
      "codes": [
        "0x1F32E"
      ],
      "description": "taco"
    },
    {
      "codes": [
        "0x1F32F"
      ],
      "description": "burrito"
    },
    {
      "codes": [
        "0x1F959"
      ],
      "description": "stuffed flatbread"
    },
    {
      "codes": [
        "0x1F95A"
      ],
      "description": "egg"
    },
    {
      "codes": [
        "0x1F373"
      ],
      "description": "cooking"
    },
    {
      "codes": [
        "0x1F958"
      ],
      "description": "shallow pan of food"
    },
    {
      "codes": [
        "0x1F372"
      ],
      "description": "pot of food"
    },
    {
      "codes": [
        "0x1F963"
      ],
      "description": "bowl with spoon"
    },
    {
      "codes": [
        "0x1F957"
      ],
      "description": "green salad"
    },
    {
      "codes": [
        "0x1F37F"
      ],
      "description": "popcorn"
    },
    {
      "codes": [
        "0x1F96B"
      ],
      "description": "canned food"
    },
    {
      "codes": [
        "0x1F371"
      ],
      "description": "bento box"
    },
    {
      "codes": [
        "0x1F358"
      ],
      "description": "rice cracker"
    },
    {
      "codes": [
        "0x1F359"
      ],
      "description": "rice ball"
    },
    {
      "codes": [
        "0x1F35A"
      ],
      "description": "cooked rice"
    },
    {
      "codes": [
        "0x1F35B"
      ],
      "description": "curry rice"
    },
    {
      "codes": [
        "0x1F35C"
      ],
      "description": "steaming bowl"
    },
    {
      "codes": [
        "0x1F35D"
      ],
      "description": "spaghetti"
    },
    {
      "codes": [
        "0x1F360"
      ],
      "description": "roasted sweet potato"
    },
    {
      "codes": [
        "0x1F362"
      ],
      "description": "oden"
    },
    {
      "codes": [
        "0x1F363"
      ],
      "description": "sushi"
    },
    {
      "codes": [
        "0x1F364"
      ],
      "description": "fried shrimp"
    },
    {
      "codes": [
        "0x1F365"
      ],
      "description": "fish cake with swirl"
    },
    {
      "codes": [
        "0x1F361"
      ],
      "description": "dango"
    },
    {
      "codes": [
        "0x1F95F"
      ],
      "description": "dumpling"
    },
    {
      "codes": [
        "0x1F960"
      ],
      "description": "fortune cookie"
    },
    {
      "codes": [
        "0x1F961"
      ],
      "description": "takeout box"
    },
    {
      "codes": [
        "0x1F366"
      ],
      "description": "soft ice cream"
    },
    {
      "codes": [
        "0x1F367"
      ],
      "description": "shaved ice"
    },
    {
      "codes": [
        "0x1F368"
      ],
      "description": "ice cream"
    },
    {
      "codes": [
        "0x1F369"
      ],
      "description": "doughnut"
    },
    {
      "codes": [
        "0x1F36A"
      ],
      "description": "cookie"
    },
    {
      "codes": [
        "0x1F382"
      ],
      "description": "birthday cake"
    },
    {
      "codes": [
        "0x1F370"
      ],
      "description": "shortcake"
    },
    {
      "codes": [
        "0x1F967"
      ],
      "description": "pie"
    },
    {
      "codes": [
        "0x1F36B"
      ],
      "description": "chocolate bar"
    },
    {
      "codes": [
        "0x1F36C"
      ],
      "description": "candy"
    },
    {
      "codes": [
        "0x1F36D"
      ],
      "description": "lollipop"
    },
    {
      "codes": [
        "0x1F36E"
      ],
      "description": "custard"
    },
    {
      "codes": [
        "0x1F36F"
      ],
      "description": "honey pot"
    },
    {
      "codes": [
        "0x1F37C"
      ],
      "description": "baby bottle"
    },
    {
      "codes": [
        "0x1F95B"
      ],
      "description": "glass of milk"
    },
    {
      "codes": [
        "0x2615"
      ],
      "description": "hot beverage"
    },
    {
      "codes": [
        "0x1F375"
      ],
      "description": "teacup without handle"
    },
    {
      "codes": [
        "0x1F376"
      ],
      "description": "sake"
    },
    {
      "codes": [
        "0x1F37E"
      ],
      "description": "bottle with popping cork"
    },
    {
      "codes": [
        "0x1F377"
      ],
      "description": "wine glass"
    },
    {
      "codes": [
        "0x1F378"
      ],
      "description": "cocktail glass"
    },
    {
      "codes": [
        "0x1F379"
      ],
      "description": "tropical drink"
    },
    {
      "codes": [
        "0x1F37A"
      ],
      "description": "beer mug"
    },
    {
      "codes": [
        "0x1F37B"
      ],
      "description": "clinking beer mugs"
    },
    {
      "codes": [
        "0x1F942"
      ],
      "description": "clinking glasses"
    },
    {
      "codes": [
        "0x1F943"
      ],
      "description": "tumbler glass"
    },
    {
      "codes": [
        "0x1F964"
      ],
      "description": "cup with straw"
    },
    {
      "codes": [
        "0x1F962"
      ],
      "description": "chopsticks"
    },
    {
      "codes": [
        "0x1F37D",
        "0xFE0F"
      ],
      "description": "fork and knife with plate"
    },
    {
      "codes": [
        "0x1F374"
      ],
      "description": "fork and knife"
    },
    {
      "codes": [
        "0x1F944"
      ],
      "description": "spoon"
    },
    {
      "codes": [
        "0x1F52A"
      ],
      "description": "kitchen knife"
    },
    {
      "codes": [
        "0x1F3FA"
      ],
      "description": "amphora"
    }
  ],
  "travelplaces": [
    {
      "codes": [
        "0x1F30D"
      ],
      "description": "globe showing EuropeAfrica"
    },
    {
      "codes": [
        "0x1F30E"
      ],
      "description": "globe showing Americas"
    },
    {
      "codes": [
        "0x1F30F"
      ],
      "description": "globe showing AsiaAustralia"
    },
    {
      "codes": [
        "0x1F310"
      ],
      "description": "globe with meridians"
    },
    {
      "codes": [
        "0x1F5FA",
        "0xFE0F"
      ],
      "description": "world map"
    },
    {
      "codes": [
        "0x1F5FE"
      ],
      "description": "map of Japan"
    },
    {
      "codes": [
        "0x1F3D4",
        "0xFE0F"
      ],
      "description": "snowcapped mountain"
    },
    {
      "codes": [
        "0x26F0",
        "0xFE0F"
      ],
      "description": "mountain"
    },
    {
      "codes": [
        "0x1F30B"
      ],
      "description": "volcano"
    },
    {
      "codes": [
        "0x1F5FB"
      ],
      "description": "mount fuji"
    },
    {
      "codes": [
        "0x1F3D5",
        "0xFE0F"
      ],
      "description": "camping"
    },
    {
      "codes": [
        "0x1F3D6",
        "0xFE0F"
      ],
      "description": "beach with umbrella"
    },
    {
      "codes": [
        "0x1F3DC",
        "0xFE0F"
      ],
      "description": "desert"
    },
    {
      "codes": [
        "0x1F3DD",
        "0xFE0F"
      ],
      "description": "desert island"
    },
    {
      "codes": [
        "0x1F3DE",
        "0xFE0F"
      ],
      "description": "national park"
    },
    {
      "codes": [
        "0x1F3DF",
        "0xFE0F"
      ],
      "description": "stadium"
    },
    {
      "codes": [
        "0x1F3DB",
        "0xFE0F"
      ],
      "description": "classical building"
    },
    {
      "codes": [
        "0x1F3D7",
        "0xFE0F"
      ],
      "description": "building construction"
    },
    {
      "codes": [
        "0x1F3D8",
        "0xFE0F"
      ],
      "description": "houses"
    },
    {
      "codes": [
        "0x1F3DA",
        "0xFE0F"
      ],
      "description": "derelict house"
    },
    {
      "codes": [
        "0x1F3E0"
      ],
      "description": "house"
    },
    {
      "codes": [
        "0x1F3E1"
      ],
      "description": "house with garden"
    },
    {
      "codes": [
        "0x1F3E2"
      ],
      "description": "office building"
    },
    {
      "codes": [
        "0x1F3E3"
      ],
      "description": "Japanese post office"
    },
    {
      "codes": [
        "0x1F3E4"
      ],
      "description": "post office"
    },
    {
      "codes": [
        "0x1F3E5"
      ],
      "description": "hospital"
    },
    {
      "codes": [
        "0x1F3E6"
      ],
      "description": "bank"
    },
    {
      "codes": [
        "0x1F3E8"
      ],
      "description": "hotel"
    },
    {
      "codes": [
        "0x1F3E9"
      ],
      "description": "love hotel"
    },
    {
      "codes": [
        "0x1F3EA"
      ],
      "description": "convenience store"
    },
    {
      "codes": [
        "0x1F3EB"
      ],
      "description": "school"
    },
    {
      "codes": [
        "0x1F3EC"
      ],
      "description": "department store"
    },
    {
      "codes": [
        "0x1F3ED"
      ],
      "description": "factory"
    },
    {
      "codes": [
        "0x1F3EF"
      ],
      "description": "Japanese castle"
    },
    {
      "codes": [
        "0x1F3F0"
      ],
      "description": "castle"
    },
    {
      "codes": [
        "0x1F492"
      ],
      "description": "wedding"
    },
    {
      "codes": [
        "0x1F5FC"
      ],
      "description": "Tokyo tower"
    },
    {
      "codes": [
        "0x1F5FD"
      ],
      "description": "Statue of Liberty"
    },
    {
      "codes": [
        "0x26EA"
      ],
      "description": "church"
    },
    {
      "codes": [
        "0x1F54C"
      ],
      "description": "mosque"
    },
    {
      "codes": [
        "0x1F54D"
      ],
      "description": "synagogue"
    },
    {
      "codes": [
        "0x26E9",
        "0xFE0F"
      ],
      "description": "shinto shrine"
    },
    {
      "codes": [
        "0x1F54B"
      ],
      "description": "kaaba"
    },
    {
      "codes": [
        "0x26F2"
      ],
      "description": "fountain"
    },
    {
      "codes": [
        "0x26FA"
      ],
      "description": "tent"
    },
    {
      "codes": [
        "0x1F301"
      ],
      "description": "foggy"
    },
    {
      "codes": [
        "0x1F303"
      ],
      "description": "night with stars"
    },
    {
      "codes": [
        "0x1F3D9",
        "0xFE0F"
      ],
      "description": "cityscape"
    },
    {
      "codes": [
        "0x1F304"
      ],
      "description": "sunrise over mountains"
    },
    {
      "codes": [
        "0x1F305"
      ],
      "description": "sunrise"
    },
    {
      "codes": [
        "0x1F306"
      ],
      "description": "cityscape at dusk"
    },
    {
      "codes": [
        "0x1F307"
      ],
      "description": "sunset"
    },
    {
      "codes": [
        "0x1F309"
      ],
      "description": "bridge at night"
    },
    {
      "codes": [
        "0x2668",
        "0xFE0F"
      ],
      "description": "hot springs"
    },
    {
      "codes": [
        "0x1F30C"
      ],
      "description": "milky way"
    },
    {
      "codes": [
        "0x1F3A0"
      ],
      "description": "carousel horse"
    },
    {
      "codes": [
        "0x1F3A1"
      ],
      "description": "ferris wheel"
    },
    {
      "codes": [
        "0x1F3A2"
      ],
      "description": "roller coaster"
    },
    {
      "codes": [
        "0x1F488"
      ],
      "description": "barber pole"
    },
    {
      "codes": [
        "0x1F3AA"
      ],
      "description": "circus tent"
    },
    {
      "codes": [
        "0x1F682"
      ],
      "description": "locomotive"
    },
    {
      "codes": [
        "0x1F683"
      ],
      "description": "railway car"
    },
    {
      "codes": [
        "0x1F684"
      ],
      "description": "highspeed train"
    },
    {
      "codes": [
        "0x1F685"
      ],
      "description": "bullet train"
    },
    {
      "codes": [
        "0x1F686"
      ],
      "description": "train"
    },
    {
      "codes": [
        "0x1F687"
      ],
      "description": "metro"
    },
    {
      "codes": [
        "0x1F688"
      ],
      "description": "light rail"
    },
    {
      "codes": [
        "0x1F689"
      ],
      "description": "station"
    },
    {
      "codes": [
        "0x1F68A"
      ],
      "description": "tram"
    },
    {
      "codes": [
        "0x1F69D"
      ],
      "description": "monorail"
    },
    {
      "codes": [
        "0x1F69E"
      ],
      "description": "mountain railway"
    },
    {
      "codes": [
        "0x1F68B"
      ],
      "description": "tram car"
    },
    {
      "codes": [
        "0x1F68C"
      ],
      "description": "bus"
    },
    {
      "codes": [
        "0x1F68D"
      ],
      "description": "oncoming bus"
    },
    {
      "codes": [
        "0x1F68E"
      ],
      "description": "trolleybus"
    },
    {
      "codes": [
        "0x1F690"
      ],
      "description": "minibus"
    },
    {
      "codes": [
        "0x1F691"
      ],
      "description": "ambulance"
    },
    {
      "codes": [
        "0x1F692"
      ],
      "description": "fire engine"
    },
    {
      "codes": [
        "0x1F693"
      ],
      "description": "police car"
    },
    {
      "codes": [
        "0x1F694"
      ],
      "description": "oncoming police car"
    },
    {
      "codes": [
        "0x1F695"
      ],
      "description": "taxi"
    },
    {
      "codes": [
        "0x1F696"
      ],
      "description": "oncoming taxi"
    },
    {
      "codes": [
        "0x1F697"
      ],
      "description": "automobile"
    },
    {
      "codes": [
        "0x1F698"
      ],
      "description": "oncoming automobile"
    },
    {
      "codes": [
        "0x1F699"
      ],
      "description": "sport utility vehicle"
    },
    {
      "codes": [
        "0x1F69A"
      ],
      "description": "delivery truck"
    },
    {
      "codes": [
        "0x1F69B"
      ],
      "description": "articulated lorry"
    },
    {
      "codes": [
        "0x1F69C"
      ],
      "description": "tractor"
    },
    {
      "codes": [
        "0x1F6B2"
      ],
      "description": "bicycle"
    },
    {
      "codes": [
        "0x1F6F4"
      ],
      "description": "kick scooter"
    },
    {
      "codes": [
        "0x1F6F5"
      ],
      "description": "motor scooter"
    },
    {
      "codes": [
        "0x1F68F"
      ],
      "description": "bus stop"
    },
    {
      "codes": [
        "0x1F6E3",
        "0xFE0F"
      ],
      "description": "motorway"
    },
    {
      "codes": [
        "0x1F6E4",
        "0xFE0F"
      ],
      "description": "railway track"
    },
    {
      "codes": [
        "0x1F6E2",
        "0xFE0F"
      ],
      "description": "oil drum"
    },
    {
      "codes": [
        "0x26FD"
      ],
      "description": "fuel pump"
    },
    {
      "codes": [
        "0x1F6A8"
      ],
      "description": "police car light"
    },
    {
      "codes": [
        "0x1F6A5"
      ],
      "description": "horizontal traffic light"
    },
    {
      "codes": [
        "0x1F6A6"
      ],
      "description": "vertical traffic light"
    },
    {
      "codes": [
        "0x1F6D1"
      ],
      "description": "stop sign"
    },
    {
      "codes": [
        "0x1F6A7"
      ],
      "description": "construction"
    },
    {
      "codes": [
        "0x2693"
      ],
      "description": "anchor"
    },
    {
      "codes": [
        "0x26F5"
      ],
      "description": "sailboat"
    },
    {
      "codes": [
        "0x1F6F6"
      ],
      "description": "canoe"
    },
    {
      "codes": [
        "0x1F6A4"
      ],
      "description": "speedboat"
    },
    {
      "codes": [
        "0x1F6F3",
        "0xFE0F"
      ],
      "description": "passenger ship"
    },
    {
      "codes": [
        "0x26F4",
        "0xFE0F"
      ],
      "description": "ferry"
    },
    {
      "codes": [
        "0x1F6E5",
        "0xFE0F"
      ],
      "description": "motor boat"
    },
    {
      "codes": [
        "0x1F6A2"
      ],
      "description": "ship"
    },
    {
      "codes": [
        "0x2708",
        "0xFE0F"
      ],
      "description": "airplane"
    },
    {
      "codes": [
        "0x1F6E9",
        "0xFE0F"
      ],
      "description": "small airplane"
    },
    {
      "codes": [
        "0x1F6EB"
      ],
      "description": "airplane departure"
    },
    {
      "codes": [
        "0x1F6EC"
      ],
      "description": "airplane arrival"
    },
    {
      "codes": [
        "0x1F4BA"
      ],
      "description": "seat"
    },
    {
      "codes": [
        "0x1F681"
      ],
      "description": "helicopter"
    },
    {
      "codes": [
        "0x1F69F"
      ],
      "description": "suspension railway"
    },
    {
      "codes": [
        "0x1F6A0"
      ],
      "description": "mountain cableway"
    },
    {
      "codes": [
        "0x1F6A1"
      ],
      "description": "aerial tramway"
    },
    {
      "codes": [
        "0x1F6F0",
        "0xFE0F"
      ],
      "description": "satellite"
    },
    {
      "codes": [
        "0x1F680"
      ],
      "description": "rocket"
    },
    {
      "codes": [
        "0x1F6F8"
      ],
      "description": "flying saucer"
    },
    {
      "codes": [
        "0x1F6CE",
        "0xFE0F"
      ],
      "description": "bellhop bell"
    },
    {
      "codes": [
        "0x231B"
      ],
      "description": "hourglass done"
    },
    {
      "codes": [
        "0x23F3"
      ],
      "description": "hourglass not done"
    },
    {
      "codes": [
        "0x231A"
      ],
      "description": "watch"
    },
    {
      "codes": [
        "0x23F0"
      ],
      "description": "alarm clock"
    },
    {
      "codes": [
        "0x23F1",
        "0xFE0F"
      ],
      "description": "stopwatch"
    },
    {
      "codes": [
        "0x23F2",
        "0xFE0F"
      ],
      "description": "timer clock"
    },
    {
      "codes": [
        "0x1F570",
        "0xFE0F"
      ],
      "description": "mantelpiece clock"
    },
    {
      "codes": [
        "0x1F55B"
      ],
      "description": "twelve oclock"
    },
    {
      "codes": [
        "0x1F567"
      ],
      "description": "twelvethirty"
    },
    {
      "codes": [
        "0x1F550"
      ],
      "description": "one oclock"
    },
    {
      "codes": [
        "0x1F55C"
      ],
      "description": "onethirty"
    },
    {
      "codes": [
        "0x1F551"
      ],
      "description": "two oclock"
    },
    {
      "codes": [
        "0x1F55D"
      ],
      "description": "twothirty"
    },
    {
      "codes": [
        "0x1F552"
      ],
      "description": "three oclock"
    },
    {
      "codes": [
        "0x1F55E"
      ],
      "description": "threethirty"
    },
    {
      "codes": [
        "0x1F553"
      ],
      "description": "four oclock"
    },
    {
      "codes": [
        "0x1F55F"
      ],
      "description": "fourthirty"
    },
    {
      "codes": [
        "0x1F554"
      ],
      "description": "five oclock"
    },
    {
      "codes": [
        "0x1F560"
      ],
      "description": "fivethirty"
    },
    {
      "codes": [
        "0x1F555"
      ],
      "description": "six oclock"
    },
    {
      "codes": [
        "0x1F561"
      ],
      "description": "sixthirty"
    },
    {
      "codes": [
        "0x1F556"
      ],
      "description": "seven oclock"
    },
    {
      "codes": [
        "0x1F562"
      ],
      "description": "seventhirty"
    },
    {
      "codes": [
        "0x1F557"
      ],
      "description": "eight oclock"
    },
    {
      "codes": [
        "0x1F563"
      ],
      "description": "eightthirty"
    },
    {
      "codes": [
        "0x1F558"
      ],
      "description": "nine oclock"
    },
    {
      "codes": [
        "0x1F564"
      ],
      "description": "ninethirty"
    },
    {
      "codes": [
        "0x1F559"
      ],
      "description": "ten oclock"
    },
    {
      "codes": [
        "0x1F565"
      ],
      "description": "tenthirty"
    },
    {
      "codes": [
        "0x1F55A"
      ],
      "description": "eleven oclock"
    },
    {
      "codes": [
        "0x1F566"
      ],
      "description": "eleventhirty"
    },
    {
      "codes": [
        "0x1F311"
      ],
      "description": "new moon"
    },
    {
      "codes": [
        "0x1F312"
      ],
      "description": "waxing crescent moon"
    },
    {
      "codes": [
        "0x1F313"
      ],
      "description": "first quarter moon"
    },
    {
      "codes": [
        "0x1F314"
      ],
      "description": "waxing gibbous moon"
    },
    {
      "codes": [
        "0x1F315"
      ],
      "description": "full moon"
    },
    {
      "codes": [
        "0x1F316"
      ],
      "description": "waning gibbous moon"
    },
    {
      "codes": [
        "0x1F317"
      ],
      "description": "last quarter moon"
    },
    {
      "codes": [
        "0x1F318"
      ],
      "description": "waning crescent moon"
    },
    {
      "codes": [
        "0x1F319"
      ],
      "description": "crescent moon"
    },
    {
      "codes": [
        "0x1F31A"
      ],
      "description": "new moon face"
    },
    {
      "codes": [
        "0x1F31B"
      ],
      "description": "first quarter moon face"
    },
    {
      "codes": [
        "0x1F31C"
      ],
      "description": "last quarter moon face"
    },
    {
      "codes": [
        "0x1F321",
        "0xFE0F"
      ],
      "description": "thermometer"
    },
    {
      "codes": [
        "0x2600",
        "0xFE0F"
      ],
      "description": "sun"
    },
    {
      "codes": [
        "0x1F31D"
      ],
      "description": "full moon face"
    },
    {
      "codes": [
        "0x1F31E"
      ],
      "description": "sun with face"
    },
    {
      "codes": [
        "0x2B50"
      ],
      "description": "star"
    },
    {
      "codes": [
        "0x1F31F"
      ],
      "description": "glowing star"
    },
    {
      "codes": [
        "0x1F320"
      ],
      "description": "shooting star"
    },
    {
      "codes": [
        "0x2601",
        "0xFE0F"
      ],
      "description": "cloud"
    },
    {
      "codes": [
        "0x26C5"
      ],
      "description": "sun behind cloud"
    },
    {
      "codes": [
        "0x26C8",
        "0xFE0F"
      ],
      "description": "cloud with lightning and rain"
    },
    {
      "codes": [
        "0x1F324",
        "0xFE0F"
      ],
      "description": "sun behind small cloud"
    },
    {
      "codes": [
        "0x1F325",
        "0xFE0F"
      ],
      "description": "sun behind large cloud"
    },
    {
      "codes": [
        "0x1F326",
        "0xFE0F"
      ],
      "description": "sun behind rain cloud"
    },
    {
      "codes": [
        "0x1F327",
        "0xFE0F"
      ],
      "description": "cloud with rain"
    },
    {
      "codes": [
        "0x1F328",
        "0xFE0F"
      ],
      "description": "cloud with snow"
    },
    {
      "codes": [
        "0x1F329",
        "0xFE0F"
      ],
      "description": "cloud with lightning"
    },
    {
      "codes": [
        "0x1F32A",
        "0xFE0F"
      ],
      "description": "tornado"
    },
    {
      "codes": [
        "0x1F300"
      ],
      "description": "cyclone"
    },
    {
      "codes": [
        "0x1F308"
      ],
      "description": "rainbow"
    },
    {
      "codes": [
        "0x1F302"
      ],
      "description": "closed umbrella"
    },
    {
      "codes": [
        "0x2602",
        "0xFE0F"
      ],
      "description": "umbrella"
    },
    {
      "codes": [
        "0x2614"
      ],
      "description": "umbrella with rain drops"
    },
    {
      "codes": [
        "0x26F1",
        "0xFE0F"
      ],
      "description": "umbrella on ground"
    },
    {
      "codes": [
        "0x26A1"
      ],
      "description": "high voltage"
    },
    {
      "codes": [
        "0x2744",
        "0xFE0F"
      ],
      "description": "snowflake"
    },
    {
      "codes": [
        "0x2603",
        "0xFE0F"
      ],
      "description": "snowman"
    },
    {
      "codes": [
        "0x26C4"
      ],
      "description": "snowman without snow"
    },
    {
      "codes": [
        "0x2604",
        "0xFE0F"
      ],
      "description": "comet"
    },
    {
      "codes": [
        "0x1F525"
      ],
      "description": "fire"
    },
    {
      "codes": [
        "0x1F4A7"
      ],
      "description": "droplet"
    },
    {
      "codes": [
        "0x1F30A"
      ],
      "description": "water wave"
    }
  ],
  "activities": [
    {
      "codes": [
        "0x1F383"
      ],
      "description": "jackolantern"
    },
    {
      "codes": [
        "0x1F384"
      ],
      "description": "Christmas tree"
    },
    {
      "codes": [
        "0x1F386"
      ],
      "description": "fireworks"
    },
    {
      "codes": [
        "0x1F387"
      ],
      "description": "sparkler"
    },
    {
      "codes": [
        "0x2728"
      ],
      "description": "sparkles"
    },
    {
      "codes": [
        "0x1F388"
      ],
      "description": "balloon"
    },
    {
      "codes": [
        "0x1F389"
      ],
      "description": "party popper"
    },
    {
      "codes": [
        "0x1F38A"
      ],
      "description": "confetti ball"
    },
    {
      "codes": [
        "0x1F38B"
      ],
      "description": "tanabata tree"
    },
    {
      "codes": [
        "0x1F38D"
      ],
      "description": "pine decoration"
    },
    {
      "codes": [
        "0x1F38E"
      ],
      "description": "Japanese dolls"
    },
    {
      "codes": [
        "0x1F38F"
      ],
      "description": "carp streamer"
    },
    {
      "codes": [
        "0x1F390"
      ],
      "description": "wind chime"
    },
    {
      "codes": [
        "0x1F391"
      ],
      "description": "moon viewing ceremony"
    },
    {
      "codes": [
        "0x1F380"
      ],
      "description": "ribbon"
    },
    {
      "codes": [
        "0x1F381"
      ],
      "description": "wrapped gift"
    },
    {
      "codes": [
        "0x1F397",
        "0xFE0F"
      ],
      "description": "reminder ribbon"
    },
    {
      "codes": [
        "0x1F39F",
        "0xFE0F"
      ],
      "description": "admission tickets"
    },
    {
      "codes": [
        "0x1F3AB"
      ],
      "description": "ticket"
    },
    {
      "codes": [
        "0x1F396",
        "0xFE0F"
      ],
      "description": "military medal"
    },
    {
      "codes": [
        "0x1F3C6"
      ],
      "description": "trophy"
    },
    {
      "codes": [
        "0x1F3C5"
      ],
      "description": "sports medal"
    },
    {
      "codes": [
        "0x1F947"
      ],
      "description": "1st place medal"
    },
    {
      "codes": [
        "0x1F948"
      ],
      "description": "2nd place medal"
    },
    {
      "codes": [
        "0x1F949"
      ],
      "description": "3rd place medal"
    },
    {
      "codes": [
        "0x26BD"
      ],
      "description": "soccer ball"
    },
    {
      "codes": [
        "0x26BE"
      ],
      "description": "baseball"
    },
    {
      "codes": [
        "0x1F3C0"
      ],
      "description": "basketball"
    },
    {
      "codes": [
        "0x1F3D0"
      ],
      "description": "volleyball"
    },
    {
      "codes": [
        "0x1F3C8"
      ],
      "description": "american football"
    },
    {
      "codes": [
        "0x1F3C9"
      ],
      "description": "rugby football"
    },
    {
      "codes": [
        "0x1F3BE"
      ],
      "description": "tennis"
    },
    {
      "codes": [
        "0x1F3B3"
      ],
      "description": "bowling"
    },
    {
      "codes": [
        "0x1F3CF"
      ],
      "description": "cricket game"
    },
    {
      "codes": [
        "0x1F3D1"
      ],
      "description": "field hockey"
    },
    {
      "codes": [
        "0x1F3D2"
      ],
      "description": "ice hockey"
    },
    {
      "codes": [
        "0x1F3D3"
      ],
      "description": "ping pong"
    },
    {
      "codes": [
        "0x1F3F8"
      ],
      "description": "badminton"
    },
    {
      "codes": [
        "0x1F94A"
      ],
      "description": "boxing glove"
    },
    {
      "codes": [
        "0x1F94B"
      ],
      "description": "martial arts uniform"
    },
    {
      "codes": [
        "0x1F945"
      ],
      "description": "goal net"
    },
    {
      "codes": [
        "0x26F3"
      ],
      "description": "flag in hole"
    },
    {
      "codes": [
        "0x26F8",
        "0xFE0F"
      ],
      "description": "ice skate"
    },
    {
      "codes": [
        "0x1F3A3"
      ],
      "description": "fishing pole"
    },
    {
      "codes": [
        "0x1F3BD"
      ],
      "description": "running shirt"
    },
    {
      "codes": [
        "0x1F3BF"
      ],
      "description": "skis"
    },
    {
      "codes": [
        "0x1F6F7"
      ],
      "description": "sled"
    },
    {
      "codes": [
        "0x1F94C"
      ],
      "description": "curling stone"
    },
    {
      "codes": [
        "0x1F3AF"
      ],
      "description": "direct hit"
    },
    {
      "codes": [
        "0x1F3B1"
      ],
      "description": "pool 8 ball"
    },
    {
      "codes": [
        "0x1F52E"
      ],
      "description": "crystal ball"
    },
    {
      "codes": [
        "0x1F3AE"
      ],
      "description": "video game"
    },
    {
      "codes": [
        "0x1F579",
        "0xFE0F"
      ],
      "description": "joystick"
    },
    {
      "codes": [
        "0x1F3B0"
      ],
      "description": "slot machine"
    },
    {
      "codes": [
        "0x1F3B2"
      ],
      "description": "game die"
    },
    {
      "codes": [
        "0x2660",
        "0xFE0F"
      ],
      "description": "spade suit"
    },
    {
      "codes": [
        "0x2665",
        "0xFE0F"
      ],
      "description": "heart suit"
    },
    {
      "codes": [
        "0x2666",
        "0xFE0F"
      ],
      "description": "diamond suit"
    },
    {
      "codes": [
        "0x2663",
        "0xFE0F"
      ],
      "description": "club suit"
    },
    {
      "codes": [
        "0x265F",
        "0xFE0F"
      ],
      "description": "chess pawn"
    },
    {
      "codes": [
        "0x1F0CF"
      ],
      "description": "joker"
    },
    {
      "codes": [
        "0x1F004"
      ],
      "description": "mahjong red dragon"
    },
    {
      "codes": [
        "0x1F3B4"
      ],
      "description": "flower playing cards"
    },
    {
      "codes": [
        "0x1F3AD"
      ],
      "description": "performing arts"
    },
    {
      "codes": [
        "0x1F5BC",
        "0xFE0F"
      ],
      "description": "framed picture"
    },
    {
      "codes": [
        "0x1F3A8"
      ],
      "description": "artist palette"
    }
  ],
  "objects": [
    {
      "codes": [
        "0x1F507"
      ],
      "description": "muted speaker"
    },
    {
      "codes": [
        "0x1F508"
      ],
      "description": "speaker low volume"
    },
    {
      "codes": [
        "0x1F509"
      ],
      "description": "speaker medium volume"
    },
    {
      "codes": [
        "0x1F50A"
      ],
      "description": "speaker high volume"
    },
    {
      "codes": [
        "0x1F4E2"
      ],
      "description": "loudspeaker"
    },
    {
      "codes": [
        "0x1F4E3"
      ],
      "description": "megaphone"
    },
    {
      "codes": [
        "0x1F4EF"
      ],
      "description": "postal horn"
    },
    {
      "codes": [
        "0x1F514"
      ],
      "description": "bell"
    },
    {
      "codes": [
        "0x1F515"
      ],
      "description": "bell with slash"
    },
    {
      "codes": [
        "0x1F3BC"
      ],
      "description": "musical score"
    },
    {
      "codes": [
        "0x1F3B5"
      ],
      "description": "musical note"
    },
    {
      "codes": [
        "0x1F3B6"
      ],
      "description": "musical notes"
    },
    {
      "codes": [
        "0x1F399",
        "0xFE0F"
      ],
      "description": "studio microphone"
    },
    {
      "codes": [
        "0x1F39A",
        "0xFE0F"
      ],
      "description": "level slider"
    },
    {
      "codes": [
        "0x1F39B",
        "0xFE0F"
      ],
      "description": "control knobs"
    },
    {
      "codes": [
        "0x1F3A4"
      ],
      "description": "microphone"
    },
    {
      "codes": [
        "0x1F3A7"
      ],
      "description": "headphone"
    },
    {
      "codes": [
        "0x1F4FB"
      ],
      "description": "radio"
    },
    {
      "codes": [
        "0x1F3B7"
      ],
      "description": "saxophone"
    },
    {
      "codes": [
        "0x1F3B8"
      ],
      "description": "guitar"
    },
    {
      "codes": [
        "0x1F3B9"
      ],
      "description": "musical keyboard"
    },
    {
      "codes": [
        "0x1F3BA"
      ],
      "description": "trumpet"
    },
    {
      "codes": [
        "0x1F3BB"
      ],
      "description": "violin"
    },
    {
      "codes": [
        "0x1F941"
      ],
      "description": "drum"
    },
    {
      "codes": [
        "0x1F4F1"
      ],
      "description": "mobile phone"
    },
    {
      "codes": [
        "0x1F4F2"
      ],
      "description": "mobile phone with arrow"
    },
    {
      "codes": [
        "0x260E",
        "0xFE0F"
      ],
      "description": "telephone"
    },
    {
      "codes": [
        "0x1F4DE"
      ],
      "description": "telephone receiver"
    },
    {
      "codes": [
        "0x1F4DF"
      ],
      "description": "pager"
    },
    {
      "codes": [
        "0x1F4E0"
      ],
      "description": "fax machine"
    },
    {
      "codes": [
        "0x1F50B"
      ],
      "description": "battery"
    },
    {
      "codes": [
        "0x1F50C"
      ],
      "description": "electric plug"
    },
    {
      "codes": [
        "0x1F4BB"
      ],
      "description": "laptop computer"
    },
    {
      "codes": [
        "0x1F5A5",
        "0xFE0F"
      ],
      "description": "desktop computer"
    },
    {
      "codes": [
        "0x1F5A8",
        "0xFE0F"
      ],
      "description": "printer"
    },
    {
      "codes": [
        "0x2328",
        "0xFE0F"
      ],
      "description": "keyboard"
    },
    {
      "codes": [
        "0x1F5B1",
        "0xFE0F"
      ],
      "description": "computer mouse"
    },
    {
      "codes": [
        "0x1F5B2",
        "0xFE0F"
      ],
      "description": "trackball"
    },
    {
      "codes": [
        "0x1F4BD"
      ],
      "description": "computer disk"
    },
    {
      "codes": [
        "0x1F4BE"
      ],
      "description": "floppy disk"
    },
    {
      "codes": [
        "0x1F4BF"
      ],
      "description": "optical disk"
    },
    {
      "codes": [
        "0x1F4C0"
      ],
      "description": "dvd"
    },
    {
      "codes": [
        "0x1F3A5"
      ],
      "description": "movie camera"
    },
    {
      "codes": [
        "0x1F39E",
        "0xFE0F"
      ],
      "description": "film frames"
    },
    {
      "codes": [
        "0x1F4FD",
        "0xFE0F"
      ],
      "description": "film projector"
    },
    {
      "codes": [
        "0x1F3AC"
      ],
      "description": "clapper board"
    },
    {
      "codes": [
        "0x1F4FA"
      ],
      "description": "television"
    },
    {
      "codes": [
        "0x1F4F7"
      ],
      "description": "camera"
    },
    {
      "codes": [
        "0x1F4F8"
      ],
      "description": "camera with flash"
    },
    {
      "codes": [
        "0x1F4F9"
      ],
      "description": "video camera"
    },
    {
      "codes": [
        "0x1F4FC"
      ],
      "description": "videocassette"
    },
    {
      "codes": [
        "0x1F50D"
      ],
      "description": "magnifying glass tilted left"
    },
    {
      "codes": [
        "0x1F50E"
      ],
      "description": "magnifying glass tilted right"
    },
    {
      "codes": [
        "0x1F56F",
        "0xFE0F"
      ],
      "description": "candle"
    },
    {
      "codes": [
        "0x1F4A1"
      ],
      "description": "light bulb"
    },
    {
      "codes": [
        "0x1F526"
      ],
      "description": "flashlight"
    },
    {
      "codes": [
        "0x1F3EE"
      ],
      "description": "red paper lantern"
    },
    {
      "codes": [
        "0x1F4D4"
      ],
      "description": "notebook with decorative cover"
    },
    {
      "codes": [
        "0x1F4D5"
      ],
      "description": "closed book"
    },
    {
      "codes": [
        "0x1F4D6"
      ],
      "description": "open book"
    },
    {
      "codes": [
        "0x1F4D7"
      ],
      "description": "green book"
    },
    {
      "codes": [
        "0x1F4D8"
      ],
      "description": "blue book"
    },
    {
      "codes": [
        "0x1F4D9"
      ],
      "description": "orange book"
    },
    {
      "codes": [
        "0x1F4DA"
      ],
      "description": "books"
    },
    {
      "codes": [
        "0x1F4D3"
      ],
      "description": "notebook"
    },
    {
      "codes": [
        "0x1F4D2"
      ],
      "description": "ledger"
    },
    {
      "codes": [
        "0x1F4C3"
      ],
      "description": "page with curl"
    },
    {
      "codes": [
        "0x1F4DC"
      ],
      "description": "scroll"
    },
    {
      "codes": [
        "0x1F4C4"
      ],
      "description": "page facing up"
    },
    {
      "codes": [
        "0x1F4F0"
      ],
      "description": "newspaper"
    },
    {
      "codes": [
        "0x1F5DE",
        "0xFE0F"
      ],
      "description": "rolledup newspaper"
    },
    {
      "codes": [
        "0x1F4D1"
      ],
      "description": "bookmark tabs"
    },
    {
      "codes": [
        "0x1F516"
      ],
      "description": "bookmark"
    },
    {
      "codes": [
        "0x1F3F7",
        "0xFE0F"
      ],
      "description": "label"
    },
    {
      "codes": [
        "0x1F4B0"
      ],
      "description": "money bag"
    },
    {
      "codes": [
        "0x1F4B4"
      ],
      "description": "yen banknote"
    },
    {
      "codes": [
        "0x1F4B5"
      ],
      "description": "dollar banknote"
    },
    {
      "codes": [
        "0x1F4B6"
      ],
      "description": "euro banknote"
    },
    {
      "codes": [
        "0x1F4B7"
      ],
      "description": "pound banknote"
    },
    {
      "codes": [
        "0x1F4B8"
      ],
      "description": "money with wings"
    },
    {
      "codes": [
        "0x1F4B3"
      ],
      "description": "credit card"
    },
    {
      "codes": [
        "0x1F4B9"
      ],
      "description": "chart increasing with yen"
    },
    {
      "codes": [
        "0x1F4B1"
      ],
      "description": "currency exchange"
    },
    {
      "codes": [
        "0x1F4B2"
      ],
      "description": "heavy dollar sign"
    },
    {
      "codes": [
        "0x2709",
        "0xFE0F"
      ],
      "description": "envelope"
    },
    {
      "codes": [
        "0x1F4E7"
      ],
      "description": "email"
    },
    {
      "codes": [
        "0x1F4E8"
      ],
      "description": "incoming envelope"
    },
    {
      "codes": [
        "0x1F4E9"
      ],
      "description": "envelope with arrow"
    },
    {
      "codes": [
        "0x1F4E4"
      ],
      "description": "outbox tray"
    },
    {
      "codes": [
        "0x1F4E5"
      ],
      "description": "inbox tray"
    },
    {
      "codes": [
        "0x1F4E6"
      ],
      "description": "package"
    },
    {
      "codes": [
        "0x1F4EB"
      ],
      "description": "closed mailbox with raised flag"
    },
    {
      "codes": [
        "0x1F4EA"
      ],
      "description": "closed mailbox with lowered flag"
    },
    {
      "codes": [
        "0x1F4EC"
      ],
      "description": "open mailbox with raised flag"
    },
    {
      "codes": [
        "0x1F4ED"
      ],
      "description": "open mailbox with lowered flag"
    },
    {
      "codes": [
        "0x1F4EE"
      ],
      "description": "postbox"
    },
    {
      "codes": [
        "0x1F5F3",
        "0xFE0F"
      ],
      "description": "ballot box with ballot"
    },
    {
      "codes": [
        "0x270F",
        "0xFE0F"
      ],
      "description": "pencil"
    },
    {
      "codes": [
        "0x2712",
        "0xFE0F"
      ],
      "description": "black nib"
    },
    {
      "codes": [
        "0x1F58B",
        "0xFE0F"
      ],
      "description": "fountain pen"
    },
    {
      "codes": [
        "0x1F58A",
        "0xFE0F"
      ],
      "description": "pen"
    },
    {
      "codes": [
        "0x1F58C",
        "0xFE0F"
      ],
      "description": "paintbrush"
    },
    {
      "codes": [
        "0x1F58D",
        "0xFE0F"
      ],
      "description": "crayon"
    },
    {
      "codes": [
        "0x1F4DD"
      ],
      "description": "memo"
    },
    {
      "codes": [
        "0x1F4BC"
      ],
      "description": "briefcase"
    },
    {
      "codes": [
        "0x1F4C1"
      ],
      "description": "file folder"
    },
    {
      "codes": [
        "0x1F4C2"
      ],
      "description": "open file folder"
    },
    {
      "codes": [
        "0x1F5C2",
        "0xFE0F"
      ],
      "description": "card index dividers"
    },
    {
      "codes": [
        "0x1F4C5"
      ],
      "description": "calendar"
    },
    {
      "codes": [
        "0x1F4C6"
      ],
      "description": "tearoff calendar"
    },
    {
      "codes": [
        "0x1F5D3",
        "0xFE0F"
      ],
      "description": "spiral calendar"
    },
    {
      "codes": [
        "0x1F4C7"
      ],
      "description": "card index"
    },
    {
      "codes": [
        "0x1F4C8"
      ],
      "description": "chart increasing"
    },
    {
      "codes": [
        "0x1F4C9"
      ],
      "description": "chart decreasing"
    },
    {
      "codes": [
        "0x1F4CA"
      ],
      "description": "bar chart"
    },
    {
      "codes": [
        "0x1F4CB"
      ],
      "description": "clipboard"
    },
    {
      "codes": [
        "0x1F4CC"
      ],
      "description": "pushpin"
    },
    {
      "codes": [
        "0x1F4CD"
      ],
      "description": "round pushpin"
    },
    {
      "codes": [
        "0x1F4CE"
      ],
      "description": "paperclip"
    },
    {
      "codes": [
        "0x1F587",
        "0xFE0F"
      ],
      "description": "linked paperclips"
    },
    {
      "codes": [
        "0x1F4CF"
      ],
      "description": "straight ruler"
    },
    {
      "codes": [
        "0x1F4D0"
      ],
      "description": "triangular ruler"
    },
    {
      "codes": [
        "0x2702",
        "0xFE0F"
      ],
      "description": "scissors"
    },
    {
      "codes": [
        "0x1F5C3",
        "0xFE0F"
      ],
      "description": "card file box"
    },
    {
      "codes": [
        "0x1F5C4",
        "0xFE0F"
      ],
      "description": "file cabinet"
    },
    {
      "codes": [
        "0x1F5D1",
        "0xFE0F"
      ],
      "description": "wastebasket"
    },
    {
      "codes": [
        "0x1F512"
      ],
      "description": "locked"
    },
    {
      "codes": [
        "0x1F513"
      ],
      "description": "unlocked"
    },
    {
      "codes": [
        "0x1F50F"
      ],
      "description": "locked with pen"
    },
    {
      "codes": [
        "0x1F510"
      ],
      "description": "locked with key"
    },
    {
      "codes": [
        "0x1F511"
      ],
      "description": "key"
    },
    {
      "codes": [
        "0x1F5DD",
        "0xFE0F"
      ],
      "description": "old key"
    },
    {
      "codes": [
        "0x1F528"
      ],
      "description": "hammer"
    },
    {
      "codes": [
        "0x26CF",
        "0xFE0F"
      ],
      "description": "pick"
    },
    {
      "codes": [
        "0x2692",
        "0xFE0F"
      ],
      "description": "hammer and pick"
    },
    {
      "codes": [
        "0x1F6E0",
        "0xFE0F"
      ],
      "description": "hammer and wrench"
    },
    {
      "codes": [
        "0x1F5E1",
        "0xFE0F"
      ],
      "description": "dagger"
    },
    {
      "codes": [
        "0x2694",
        "0xFE0F"
      ],
      "description": "crossed swords"
    },
    {
      "codes": [
        "0x1F52B"
      ],
      "description": "pistol"
    },
    {
      "codes": [
        "0x1F3F9"
      ],
      "description": "bow and arrow"
    },
    {
      "codes": [
        "0x1F6E1",
        "0xFE0F"
      ],
      "description": "shield"
    },
    {
      "codes": [
        "0x1F527"
      ],
      "description": "wrench"
    },
    {
      "codes": [
        "0x1F529"
      ],
      "description": "nut and bolt"
    },
    {
      "codes": [
        "0x2699",
        "0xFE0F"
      ],
      "description": "gear"
    },
    {
      "codes": [
        "0x1F5DC",
        "0xFE0F"
      ],
      "description": "clamp"
    },
    {
      "codes": [
        "0x2696",
        "0xFE0F"
      ],
      "description": "balance scale"
    },
    {
      "codes": [
        "0x1F517"
      ],
      "description": "link"
    },
    {
      "codes": [
        "0x26D3",
        "0xFE0F"
      ],
      "description": "chains"
    },
    {
      "codes": [
        "0x2697",
        "0xFE0F"
      ],
      "description": "alembic"
    },
    {
      "codes": [
        "0x1F52C"
      ],
      "description": "microscope"
    },
    {
      "codes": [
        "0x1F52D"
      ],
      "description": "telescope"
    },
    {
      "codes": [
        "0x1F4E1"
      ],
      "description": "satellite antenna"
    },
    {
      "codes": [
        "0x1F489"
      ],
      "description": "syringe"
    },
    {
      "codes": [
        "0x1F48A"
      ],
      "description": "pill"
    },
    {
      "codes": [
        "0x1F6AA"
      ],
      "description": "door"
    },
    {
      "codes": [
        "0x1F6CF",
        "0xFE0F"
      ],
      "description": "bed"
    },
    {
      "codes": [
        "0x1F6CB",
        "0xFE0F"
      ],
      "description": "couch and lamp"
    },
    {
      "codes": [
        "0x1F6BD"
      ],
      "description": "toilet"
    },
    {
      "codes": [
        "0x1F6BF"
      ],
      "description": "shower"
    },
    {
      "codes": [
        "0x1F6C1"
      ],
      "description": "bathtub"
    },
    {
      "codes": [
        "0x1F6D2"
      ],
      "description": "shopping cart"
    },
    {
      "codes": [
        "0x1F6AC"
      ],
      "description": "cigarette"
    },
    {
      "codes": [
        "0x26B0",
        "0xFE0F"
      ],
      "description": "coffin"
    },
    {
      "codes": [
        "0x26B1",
        "0xFE0F"
      ],
      "description": "funeral urn"
    },
    {
      "codes": [
        "0x1F5FF"
      ],
      "description": "moai"
    }
  ],
  "symbols": [
    {
      "codes": [
        "0x1F3E7"
      ],
      "description": "ATM sign"
    },
    {
      "codes": [
        "0x1F6AE"
      ],
      "description": "litter in bin sign"
    },
    {
      "codes": [
        "0x1F6B0"
      ],
      "description": "potable water"
    },
    {
      "codes": [
        "0x267F"
      ],
      "description": "wheelchair symbol"
    },
    {
      "codes": [
        "0x1F6B9"
      ],
      "description": "mens room"
    },
    {
      "codes": [
        "0x1F6BA"
      ],
      "description": "womens room"
    },
    {
      "codes": [
        "0x1F6BB"
      ],
      "description": "restroom"
    },
    {
      "codes": [
        "0x1F6BC"
      ],
      "description": "baby symbol"
    },
    {
      "codes": [
        "0x1F6BE"
      ],
      "description": "water closet"
    },
    {
      "codes": [
        "0x1F6C2"
      ],
      "description": "passport control"
    },
    {
      "codes": [
        "0x1F6C3"
      ],
      "description": "customs"
    },
    {
      "codes": [
        "0x1F6C4"
      ],
      "description": "baggage claim"
    },
    {
      "codes": [
        "0x1F6C5"
      ],
      "description": "left luggage"
    },
    {
      "codes": [
        "0x26A0",
        "0xFE0F"
      ],
      "description": "warning"
    },
    {
      "codes": [
        "0x1F6B8"
      ],
      "description": "children crossing"
    },
    {
      "codes": [
        "0x26D4"
      ],
      "description": "no entry"
    },
    {
      "codes": [
        "0x1F6AB"
      ],
      "description": "prohibited"
    },
    {
      "codes": [
        "0x1F6B3"
      ],
      "description": "no bicycles"
    },
    {
      "codes": [
        "0x1F6AD"
      ],
      "description": "no smoking"
    },
    {
      "codes": [
        "0x1F6AF"
      ],
      "description": "no littering"
    },
    {
      "codes": [
        "0x1F6B1"
      ],
      "description": "nonpotable water"
    },
    {
      "codes": [
        "0x1F6B7"
      ],
      "description": "no pedestrians"
    },
    {
      "codes": [
        "0x1F4F5"
      ],
      "description": "no mobile phones"
    },
    {
      "codes": [
        "0x1F51E"
      ],
      "description": "no one under eighteen"
    },
    {
      "codes": [
        "0x2622",
        "0xFE0F"
      ],
      "description": "radioactive"
    },
    {
      "codes": [
        "0x2623",
        "0xFE0F"
      ],
      "description": "biohazard"
    },
    {
      "codes": [
        "0x2B06",
        "0xFE0F"
      ],
      "description": "up arrow"
    },
    {
      "codes": [
        "0x2197",
        "0xFE0F"
      ],
      "description": "upright arrow"
    },
    {
      "codes": [
        "0x27A1",
        "0xFE0F"
      ],
      "description": "right arrow"
    },
    {
      "codes": [
        "0x2198",
        "0xFE0F"
      ],
      "description": "downright arrow"
    },
    {
      "codes": [
        "0x2B07",
        "0xFE0F"
      ],
      "description": "down arrow"
    },
    {
      "codes": [
        "0x2199",
        "0xFE0F"
      ],
      "description": "downleft arrow"
    },
    {
      "codes": [
        "0x2B05",
        "0xFE0F"
      ],
      "description": "left arrow"
    },
    {
      "codes": [
        "0x2196",
        "0xFE0F"
      ],
      "description": "upleft arrow"
    },
    {
      "codes": [
        "0x2195",
        "0xFE0F"
      ],
      "description": "updown arrow"
    },
    {
      "codes": [
        "0x2194",
        "0xFE0F"
      ],
      "description": "leftright arrow"
    },
    {
      "codes": [
        "0x21A9",
        "0xFE0F"
      ],
      "description": "right arrow curving left"
    },
    {
      "codes": [
        "0x21AA",
        "0xFE0F"
      ],
      "description": "left arrow curving right"
    },
    {
      "codes": [
        "0x2934",
        "0xFE0F"
      ],
      "description": "right arrow curving up"
    },
    {
      "codes": [
        "0x2935",
        "0xFE0F"
      ],
      "description": "right arrow curving down"
    },
    {
      "codes": [
        "0x1F503"
      ],
      "description": "clockwise vertical arrows"
    },
    {
      "codes": [
        "0x1F504"
      ],
      "description": "counterclockwise arrows button"
    },
    {
      "codes": [
        "0x1F519"
      ],
      "description": "BACK arrow"
    },
    {
      "codes": [
        "0x1F51A"
      ],
      "description": "END arrow"
    },
    {
      "codes": [
        "0x1F51B"
      ],
      "description": "ON arrow"
    },
    {
      "codes": [
        "0x1F51C"
      ],
      "description": "SOON arrow"
    },
    {
      "codes": [
        "0x1F51D"
      ],
      "description": "TOP arrow"
    },
    {
      "codes": [
        "0x1F6D0"
      ],
      "description": "place of worship"
    },
    {
      "codes": [
        "0x269B",
        "0xFE0F"
      ],
      "description": "atom symbol"
    },
    {
      "codes": [
        "0x1F549",
        "0xFE0F"
      ],
      "description": "om"
    },
    {
      "codes": [
        "0x2721",
        "0xFE0F"
      ],
      "description": "star of David"
    },
    {
      "codes": [
        "0x2638",
        "0xFE0F"
      ],
      "description": "wheel of dharma"
    },
    {
      "codes": [
        "0x262F",
        "0xFE0F"
      ],
      "description": "yin yang"
    },
    {
      "codes": [
        "0x271D",
        "0xFE0F"
      ],
      "description": "latin cross"
    },
    {
      "codes": [
        "0x2626",
        "0xFE0F"
      ],
      "description": "orthodox cross"
    },
    {
      "codes": [
        "0x262A",
        "0xFE0F"
      ],
      "description": "star and crescent"
    },
    {
      "codes": [
        "0x262E",
        "0xFE0F"
      ],
      "description": "peace symbol"
    },
    {
      "codes": [
        "0x1F54E"
      ],
      "description": "menorah"
    },
    {
      "codes": [
        "0x1F52F"
      ],
      "description": "dotted sixpointed star"
    },
    {
      "codes": [
        "0x2648"
      ],
      "description": "Aries"
    },
    {
      "codes": [
        "0x2649"
      ],
      "description": "Taurus"
    },
    {
      "codes": [
        "0x264A"
      ],
      "description": "Gemini"
    },
    {
      "codes": [
        "0x264B"
      ],
      "description": "Cancer"
    },
    {
      "codes": [
        "0x264C"
      ],
      "description": "Leo"
    },
    {
      "codes": [
        "0x264D"
      ],
      "description": "Virgo"
    },
    {
      "codes": [
        "0x264E"
      ],
      "description": "Libra"
    },
    {
      "codes": [
        "0x264F"
      ],
      "description": "Scorpio"
    },
    {
      "codes": [
        "0x2650"
      ],
      "description": "Sagittarius"
    },
    {
      "codes": [
        "0x2651"
      ],
      "description": "Capricorn"
    },
    {
      "codes": [
        "0x2652"
      ],
      "description": "Aquarius"
    },
    {
      "codes": [
        "0x2653"
      ],
      "description": "Pisces"
    },
    {
      "codes": [
        "0x26CE"
      ],
      "description": "Ophiuchus"
    },
    {
      "codes": [
        "0x1F500"
      ],
      "description": "shuffle tracks button"
    },
    {
      "codes": [
        "0x1F501"
      ],
      "description": "repeat button"
    },
    {
      "codes": [
        "0x1F502"
      ],
      "description": "repeat single button"
    },
    {
      "codes": [
        "0x25B6",
        "0xFE0F"
      ],
      "description": "play button"
    },
    {
      "codes": [
        "0x23E9"
      ],
      "description": "fastforward button"
    },
    {
      "codes": [
        "0x23ED",
        "0xFE0F"
      ],
      "description": "next track button"
    },
    {
      "codes": [
        "0x23EF",
        "0xFE0F"
      ],
      "description": "play or pause button"
    },
    {
      "codes": [
        "0x25C0",
        "0xFE0F"
      ],
      "description": "reverse button"
    },
    {
      "codes": [
        "0x23EA"
      ],
      "description": "fast reverse button"
    },
    {
      "codes": [
        "0x23EE",
        "0xFE0F"
      ],
      "description": "last track button"
    },
    {
      "codes": [
        "0x1F53C"
      ],
      "description": "upwards button"
    },
    {
      "codes": [
        "0x23EB"
      ],
      "description": "fast up button"
    },
    {
      "codes": [
        "0x1F53D"
      ],
      "description": "downwards button"
    },
    {
      "codes": [
        "0x23EC"
      ],
      "description": "fast down button"
    },
    {
      "codes": [
        "0x23F8",
        "0xFE0F"
      ],
      "description": "pause button"
    },
    {
      "codes": [
        "0x23F9",
        "0xFE0F"
      ],
      "description": "stop button"
    },
    {
      "codes": [
        "0x23FA",
        "0xFE0F"
      ],
      "description": "record button"
    },
    {
      "codes": [
        "0x23CF",
        "0xFE0F"
      ],
      "description": "eject button"
    },
    {
      "codes": [
        "0x1F3A6"
      ],
      "description": "cinema"
    },
    {
      "codes": [
        "0x1F505"
      ],
      "description": "dim button"
    },
    {
      "codes": [
        "0x1F506"
      ],
      "description": "bright button"
    },
    {
      "codes": [
        "0x1F4F6"
      ],
      "description": "antenna bars"
    },
    {
      "codes": [
        "0x1F4F3"
      ],
      "description": "vibration mode"
    },
    {
      "codes": [
        "0x1F4F4"
      ],
      "description": "mobile phone off"
    },
    {
      "codes": [
        "0x2640",
        "0xFE0F"
      ],
      "description": "female sign"
    },
    {
      "codes": [
        "0x2642",
        "0xFE0F"
      ],
      "description": "male sign"
    },
    {
      "codes": [
        "0x2695",
        "0xFE0F"
      ],
      "description": "medical symbol"
    },
    {
      "codes": [
        "0x267E",
        "0xFE0F"
      ],
      "description": "infinity"
    },
    {
      "codes": [
        "0x267B",
        "0xFE0F"
      ],
      "description": "recycling symbol"
    },
    {
      "codes": [
        "0x269C",
        "0xFE0F"
      ],
      "description": "fleurdelis"
    },
    {
      "codes": [
        "0x1F531"
      ],
      "description": "trident emblem"
    },
    {
      "codes": [
        "0x1F4DB"
      ],
      "description": "name badge"
    },
    {
      "codes": [
        "0x1F530"
      ],
      "description": "Japanese symbol for beginner"
    },
    {
      "codes": [
        "0x2B55"
      ],
      "description": "heavy large circle"
    },
    {
      "codes": [
        "0x2705"
      ],
      "description": "white heavy check mark"
    },
    {
      "codes": [
        "0x2611",
        "0xFE0F"
      ],
      "description": "ballot box with check"
    },
    {
      "codes": [
        "0x2714",
        "0xFE0F"
      ],
      "description": "heavy check mark"
    },
    {
      "codes": [
        "0x2716",
        "0xFE0F"
      ],
      "description": "heavy multiplication x"
    },
    {
      "codes": [
        "0x274C"
      ],
      "description": "cross mark"
    },
    {
      "codes": [
        "0x274E"
      ],
      "description": "cross mark button"
    },
    {
      "codes": [
        "0x2795"
      ],
      "description": "heavy plus sign"
    },
    {
      "codes": [
        "0x2796"
      ],
      "description": "heavy minus sign"
    },
    {
      "codes": [
        "0x2797"
      ],
      "description": "heavy division sign"
    },
    {
      "codes": [
        "0x27B0"
      ],
      "description": "curly loop"
    },
    {
      "codes": [
        "0x27BF"
      ],
      "description": "double curly loop"
    },
    {
      "codes": [
        "0x303D",
        "0xFE0F"
      ],
      "description": "part alternation mark"
    },
    {
      "codes": [
        "0x2733",
        "0xFE0F"
      ],
      "description": "eightspoked asterisk"
    },
    {
      "codes": [
        "0x2734",
        "0xFE0F"
      ],
      "description": "eightpointed star"
    },
    {
      "codes": [
        "0x2747",
        "0xFE0F"
      ],
      "description": "sparkle"
    },
    {
      "codes": [
        "0x203C",
        "0xFE0F"
      ],
      "description": "double exclamation mark"
    },
    {
      "codes": [
        "0x2049",
        "0xFE0F"
      ],
      "description": "exclamation question mark"
    },
    {
      "codes": [
        "0x2753"
      ],
      "description": "question mark"
    },
    {
      "codes": [
        "0x2754"
      ],
      "description": "white question mark"
    },
    {
      "codes": [
        "0x2755"
      ],
      "description": "white exclamation mark"
    },
    {
      "codes": [
        "0x2757"
      ],
      "description": "exclamation mark"
    },
    {
      "codes": [
        "0x3030",
        "0xFE0F"
      ],
      "description": "wavy dash"
    },
    {
      "codes": [
        "0x00A9",
        "0xFE0F"
      ],
      "description": "copyright"
    },
    {
      "codes": [
        "0x00AE",
        "0xFE0F"
      ],
      "description": "registered"
    },
    {
      "codes": [
        "0x2122",
        "0xFE0F"
      ],
      "description": "trade mark"
    },
    {
      "codes": [
        "0x0023",
        "0xFE0F",
        "0x20E3"
      ],
      "description": ""
    },
    {
      "codes": [
        "0x002A",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "keycap"
    },
    {
      "codes": [
        "0x0030",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "0 keycap 0"
    },
    {
      "codes": [
        "0x0031",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "1 keycap 1"
    },
    {
      "codes": [
        "0x0032",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "2 keycap 2"
    },
    {
      "codes": [
        "0x0033",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "3 keycap 3"
    },
    {
      "codes": [
        "0x0034",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "4 keycap 4"
    },
    {
      "codes": [
        "0x0035",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "5 keycap 5"
    },
    {
      "codes": [
        "0x0036",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "6 keycap 6"
    },
    {
      "codes": [
        "0x0037",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "7 keycap 7"
    },
    {
      "codes": [
        "0x0038",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "8 keycap 8"
    },
    {
      "codes": [
        "0x0039",
        "0xFE0F",
        "0x20E3"
      ],
      "description": "9 keycap 9"
    },
    {
      "codes": [
        "0x1F51F"
      ],
      "description": "keycap 10"
    },
    {
      "codes": [
        "0x1F4AF"
      ],
      "description": "hundred points"
    },
    {
      "codes": [
        "0x1F520"
      ],
      "description": "input latin uppercase"
    },
    {
      "codes": [
        "0x1F521"
      ],
      "description": "input latin lowercase"
    },
    {
      "codes": [
        "0x1F522"
      ],
      "description": "input numbers"
    },
    {
      "codes": [
        "0x1F523"
      ],
      "description": "input symbols"
    },
    {
      "codes": [
        "0x1F524"
      ],
      "description": "input latin letters"
    },
    {
      "codes": [
        "0x1F170",
        "0xFE0F"
      ],
      "description": "A button blood type"
    },
    {
      "codes": [
        "0x1F18E"
      ],
      "description": "AB button blood type"
    },
    {
      "codes": [
        "0x1F171",
        "0xFE0F"
      ],
      "description": "B button blood type"
    },
    {
      "codes": [
        "0x1F191"
      ],
      "description": "CL button"
    },
    {
      "codes": [
        "0x1F192"
      ],
      "description": "COOL button"
    },
    {
      "codes": [
        "0x1F193"
      ],
      "description": "FREE button"
    },
    {
      "codes": [
        "0x2139",
        "0xFE0F"
      ],
      "description": "information"
    },
    {
      "codes": [
        "0x1F194"
      ],
      "description": "ID button"
    },
    {
      "codes": [
        "0x24C2",
        "0xFE0F"
      ],
      "description": "circled M"
    },
    {
      "codes": [
        "0x1F195"
      ],
      "description": "NEW button"
    },
    {
      "codes": [
        "0x1F196"
      ],
      "description": "NG button"
    },
    {
      "codes": [
        "0x1F17E",
        "0xFE0F"
      ],
      "description": "O button blood type"
    },
    {
      "codes": [
        "0x1F197"
      ],
      "description": "OK button"
    },
    {
      "codes": [
        "0x1F17F",
        "0xFE0F"
      ],
      "description": "P button"
    },
    {
      "codes": [
        "0x1F198"
      ],
      "description": "SOS button"
    },
    {
      "codes": [
        "0x1F199"
      ],
      "description": "UP button"
    },
    {
      "codes": [
        "0x1F19A"
      ],
      "description": "VS button"
    },
    {
      "codes": [
        "0x1F201"
      ],
      "description": "Japanese here button"
    },
    {
      "codes": [
        "0x1F202",
        "0xFE0F"
      ],
      "description": "Japanese service charge button"
    },
    {
      "codes": [
        "0x1F237",
        "0xFE0F"
      ],
      "description": "Japanese monthly amount button"
    },
    {
      "codes": [
        "0x1F236"
      ],
      "description": "Japanese not free of charge button"
    },
    {
      "codes": [
        "0x1F22F"
      ],
      "description": "Japanese reserved button"
    },
    {
      "codes": [
        "0x1F250"
      ],
      "description": "Japanese bargain button"
    },
    {
      "codes": [
        "0x1F239"
      ],
      "description": "Japanese discount button"
    },
    {
      "codes": [
        "0x1F21A"
      ],
      "description": "Japanese free of charge button"
    },
    {
      "codes": [
        "0x1F232"
      ],
      "description": "Japanese prohibited button"
    },
    {
      "codes": [
        "0x1F251"
      ],
      "description": "Japanese acceptable button"
    },
    {
      "codes": [
        "0x1F238"
      ],
      "description": "Japanese application button"
    },
    {
      "codes": [
        "0x1F234"
      ],
      "description": "Japanese passing grade button"
    },
    {
      "codes": [
        "0x1F233"
      ],
      "description": "Japanese vacancy button"
    },
    {
      "codes": [
        "0x3297",
        "0xFE0F"
      ],
      "description": "Japanese congratulations button"
    },
    {
      "codes": [
        "0x3299",
        "0xFE0F"
      ],
      "description": "Japanese secret button"
    },
    {
      "codes": [
        "0x1F23A"
      ],
      "description": "Japanese open for business button"
    },
    {
      "codes": [
        "0x1F235"
      ],
      "description": "Japanese no vacancy button"
    },
    {
      "codes": [
        "0x25AA",
        "0xFE0F"
      ],
      "description": "black small square"
    },
    {
      "codes": [
        "0x25AB",
        "0xFE0F"
      ],
      "description": "white small square"
    },
    {
      "codes": [
        "0x25FC",
        "0xFE0F"
      ],
      "description": "black medium square"
    },
    {
      "codes": [
        "0x25FD"
      ],
      "description": "white mediumsmall square"
    },
    {
      "codes": [
        "0x25FE"
      ],
      "description": "black mediumsmall square"
    },
    {
      "codes": [
        "0x2B1B"
      ],
      "description": "black large square"
    },
    {
      "codes": [
        "0x2B1C"
      ],
      "description": "white large square"
    },
    {
      "codes": [
        "0x1F536"
      ],
      "description": "large orange diamond"
    },
    {
      "codes": [
        "0x1F537"
      ],
      "description": "large blue diamond"
    },
    {
      "codes": [
        "0x1F538"
      ],
      "description": "small orange diamond"
    },
    {
      "codes": [
        "0x1F539"
      ],
      "description": "small blue diamond"
    },
    {
      "codes": [
        "0x1F53A"
      ],
      "description": "red triangle pointed up"
    },
    {
      "codes": [
        "0x1F53B"
      ],
      "description": "red triangle pointed down"
    },
    {
      "codes": [
        "0x1F4A0"
      ],
      "description": "diamond with a dot"
    },
    {
      "codes": [
        "0x1F518"
      ],
      "description": "radio button"
    },
    {
      "codes": [
        "0x1F532"
      ],
      "description": "black square button"
    },
    {
      "codes": [
        "0x1F533"
      ],
      "description": "white square button"
    },
    {
      "codes": [
        "0x26AA"
      ],
      "description": "white circle"
    },
    {
      "codes": [
        "0x26AB"
      ],
      "description": "black circle"
    },
    {
      "codes": [
        "0x1F534"
      ],
      "description": "red circle"
    },
    {
      "codes": [
        "0x1F535"
      ],
      "description": "blue circle"
    }
  ],
  "flags": [
    {
      "codes": [
        "0x1F3C1"
      ],
      "description": "chequered flag"
    },
    {
      "codes": [
        "0x1F6A9"
      ],
      "description": "triangular flag"
    },
    {
      "codes": [
        "0x1F38C"
      ],
      "description": "crossed flags"
    },
    {
      "codes": [
        "0x1F3F4"
      ],
      "description": "black flag"
    },
    {
      "codes": [
        "0x1F3F4",
        "0x200D",
        "0x2620",
        "0xFE0F"
      ],
      "description": "pirate flag"
    },
    {
      "codes": [
        "0x1F1E6",
        "0x1F1E8"
      ],
      "description": "Ascension Island"
    },
    {
      "codes": [
        "0x1F1E7",
        "0x1F1E6"
      ],
      "description": "Bosnia  Herzegovina"
    },
    {
      "codes": [
        "0x1F1E8",
        "0x1F1E6"
      ],
      "description": "Canada"
    },
    {
      "codes": [
        "0x1F1E9",
        "0x1F1EA"
      ],
      "description": "Germany"
    },
    {
      "codes": [
        "0x1F1EA",
        "0x1F1E6"
      ],
      "description": "Ceuta  Melilla"
    },
    {
      "codes": [
        "0x1F1EB",
        "0x1F1EE"
      ],
      "description": "Finland"
    },
    {
      "codes": [
        "0x1F1EC",
        "0x1F1E6"
      ],
      "description": "Gabon"
    },
    {
      "codes": [
        "0x1F1ED",
        "0x1F1F0"
      ],
      "description": "Hong Kong SAR China"
    },
    {
      "codes": [
        "0x1F1EE",
        "0x1F1E8"
      ],
      "description": "Canary Islands"
    },
    {
      "codes": [
        "0x1F1EF",
        "0x1F1EA"
      ],
      "description": "Jersey"
    },
    {
      "codes": [
        "0x1F1F0",
        "0x1F1EA"
      ],
      "description": "Kenya"
    },
    {
      "codes": [
        "0x1F1F1",
        "0x1F1E6"
      ],
      "description": "Laos"
    },
    {
      "codes": [
        "0x1F1F2",
        "0x1F1E6"
      ],
      "description": "Morocco"
    },
    {
      "codes": [
        "0x1F1F3",
        "0x1F1E6"
      ],
      "description": "Namibia"
    },
    {
      "codes": [
        "0x1F1F4",
        "0x1F1F2"
      ],
      "description": "Oman"
    },
    {
      "codes": [
        "0x1F1F5",
        "0x1F1E6"
      ],
      "description": "Panama"
    },
    {
      "codes": [
        "0x1F1F6",
        "0x1F1E6"
      ],
      "description": "Qatar"
    },
    {
      "codes": [
        "0x1F1F7",
        "0x1F1EA"
      ],
      "description": "Runion"
    },
    {
      "codes": [
        "0x1F1F8",
        "0x1F1E6"
      ],
      "description": "Saudi Arabia"
    },
    {
      "codes": [
        "0x1F1F9",
        "0x1F1E6"
      ],
      "description": "Tristan da Cunha"
    },
    {
      "codes": [
        "0x1F1FA",
        "0x1F1E6"
      ],
      "description": "Ukraine"
    },
    {
      "codes": [
        "0x1F1FB",
        "0x1F1E6"
      ],
      "description": "Vatican City"
    },
    {
      "codes": [
        "0x1F1FC",
        "0x1F1EB"
      ],
      "description": "Wallis  Futuna"
    },
    {
      "codes": [
        "0x1F1FD",
        "0x1F1F0"
      ],
      "description": "Kosovo"
    },
    {
      "codes": [
        "0x1F1FE",
        "0x1F1EA"
      ],
      "description": "Yemen"
    },
    {
      "codes": [
        "0x1F1FF",
        "0x1F1E6"
      ],
      "description": "South Africa"
    },
    {
      "codes": [
        "0x1F3F4",
        "0xE0067",
        "0xE0062",
        "0xE0065",
        "0xE006E",
        "0xE0067",
        "0xE007F"
      ],
      "description": "England"
    }
  ]
}

export default emoji