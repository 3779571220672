import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import '../../css/transitions.css'
import mapStateToProps from '../../states/pages'
import { publicUrl } from "../../configuration";


const CircleWidget = (props) => {
  return (
    <CSSTransition
      classNames="circle"
      in={props.isMounted}
      timeout={300}
      unmountOnExit
      mountOnEnter
    >
      <CircleContainer onClick={props.onClick} isMinimized={props.isMinimized}>
        <CircleWidgetImage src={publicUrl + props.imgCircle} alt="icon" />
      </CircleContainer>
    </CSSTransition>
  )
}

/********** region STYLE */

const CircleContainer = styled.div`
  position: fixed;
  z-index: 100000;
  display: ${props => props.isMinimized ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.previewWidget.background};
  ${props => props.theme.previewWidget.pos.bottom
  ? `bottom: ${props.theme.previewWidget.pos.bottom};`
  : ''};
  ${props => props.theme.previewWidget.pos.right
  ? `right: ${props.theme.previewWidget.pos.right};`
  : ''};
  ${props => props.theme.previewWidget.pos.top
  ? `top: ${props.theme.previewWidget.pos.top};`
  : ''};
  ${props => props.theme.previewWidget.pos.left
  ? `left: ${props.theme.previewWidget.pos.left};`
  : ''};
  border-radius: ${props => props.theme.previewWidget.borderRadius};
  height: ${props => props.theme.previewWidget.height};
  width: ${props => props.theme.previewWidget.width};
  cursor: pointer;
  
  & img {
    vertical-align: middle;
    height: ${props => props.theme.previewWidget.img.height};
    margin: ${props => props.theme.previewWidget.img.margin};
  }
`

const CircleWidgetImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto !important;
  width: ${props => props.theme.previewWidget.img.large.size};
  height: ${props => props.theme.previewWidget.img.large.size};
`

/********** endregion */

export default connect(mapStateToProps)(CircleWidget)
