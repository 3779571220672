import React, { Component } from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import uuid from 'uuid'
import moment from 'moment'
import imgWaiting from '../../images/waiting.svg'
import { getEvents, getCurrentOperator } from '../../reducers/chat'
import { getShowAttachmentBox } from '../../reducers/pages'
import { getUserId } from '../../reducers/user'
import { publicUrl } from '../../configuration'
import AttachmentBox from './AttachmentBox'
import imgLink from '../../images/link.svg'


const mapStateToProps = state => ({
  events : getEvents(state),
  userID : getUserId(state),
  operator : getCurrentOperator(state),
  showAttachmentBox : getShowAttachmentBox(state)
})

class WidgetMessageBox extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollToBottom()
  }

  scrollToBottom = () => {
    const messageBox = document.getElementById('messageBox')
    messageBox.scrollTop = messageBox.scrollHeight
  }

  eventHandler = event => {
    if (event.type === 'system' && event.subtype === "media stream closed") {
      return (
        <MessageSystem key={uuid()}>
          Media Call terminata
          <TimeStamp>{moment(event.timestamp).format('HH:mm')}</TimeStamp>
        </MessageSystem>
      )
    }
    if (event.type === 'link') {
      return (
          <MessageLink key={uuid()}>
            <ImgLink src={publicUrl + imgLink} alt='link-icon' />
            <Link href={event.link} target='_blank'>{event.title}</Link>
          </MessageLink>
      )
    }
    if (event.type === 'system' && event.subtype === 'new attachment') {
      return(
        <MessageSystem key={uuid()}>
          File inviato con successo
          <TimeStamp>{moment(event.timestamp).format('HH:mm')}</TimeStamp>
        </MessageSystem>
      )
    }
    if (event.type === 'system') {
      return
    }
    if (event.user && event.user.ID === this.props.userID) {
      // My message
      return (
        <MessageUser key={uuid()} onClick={this.scrollToBottom}>
        {event.text}
        {!event.received && <Waiting src={publicUrl + imgWaiting} alt='waiting' />}
        <TimeStamp>{moment(event.timestamp).format('HH:mm')}</TimeStamp>
      </MessageUser>
      )
    } else if ( event.user && this.props.operator && event.user.ID === this.props.operator.ID) {
      // Operator message
      return (
        <MessageOperator key={uuid()}>
        {event.text}
        <TimeStamp>{moment(event.timestamp).format('HH:mm')}</TimeStamp>
        </MessageOperator>
      )
    }
    else {
      // Spy
      return (
        <MessageOperator key={uuid()} spy>
          {event.text}
          <TimeStamp>{moment(event.timestamp).format('HH:mm')}</TimeStamp>
        </MessageOperator>
      )
    }
  }

  render() {
    return (
      <>
        <WidgetMessageBoxStyled id={'messageBox'}>
          {this.props.events && this.props.events.map(event =>
            this.eventHandler(event)
          )}
        </WidgetMessageBoxStyled>
        {this.props.showAttachmentBox && <AttachmentBox />}
      </>
    )
  }
}


/********** region ~~~~~ STYLE ~~~~~ */

const WidgetMessageBoxStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${props => props.theme.messageBox.padding};
  overflow-y: auto;
`;

const Message = styled.div`
  position: relative;
  margin-top: ${props => props.theme.messageBox.message.marginTop};
  padding: ${props => props.theme.messageBox.message.padding};
  font-size: ${props => props.theme.messageBox.message.fontSize};
  max-width: ${props => props.theme.messageBox.message.maxWidth};
  min-width: ${props => props.theme.messageBox.message.minWidth};
  border-radius: ${props => props.theme.messageBox.message.borderRadius};
  line-height: ${props => props.theme.messageBox.message.lineHeight};
  color: ${props => props.theme.messageBox.message.color};
  opacity: ${props => props.theme.messageBox.message.opacity};
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

`;

const MessageOperator = styled(Message)`
  background: ${props => props.spy ? props.theme.messageBox.message.supervisorBackground : props.theme.messageBox.message.operatorBackground};
  align-self: flex-start;
  
  &:after {
    content: "";
    position: absolute;
    margin-top: -12px;
    margin-right: -5px;
    border-right: 11px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: ${props => props.spy ? '12px solid ' + props.theme.messageBox.message.supervisorBackground : '12px solid ' + props.theme.messageBox.message.operatorBackground};
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -13px;
    top: 10px;
  }
`;

const MessageUser = styled(Message)`
  background: ${props => props.theme.messageBox.message.customerBackground};
  align-self: flex-end;
  
  &:after {
    content: "";
    position: absolute;
    margin-top: -12px;
    margin-left: -5px;
    border-left: 12px solid transparent;
    border-right: 13px solid transparent;
    border-bottom: ${props => '12px solid' + props.theme.messageBox.message.customerBackground};
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: -13px;
    top: 10px;
  }
`;

const MessageSystem = styled(Message)`
  opacity: 0.8;
  background: #717171;
  align-self: center;
  min-width: 200px;
  text-align: center;
`;

const MessageLink = styled(Message)`
  opacity: 0.8;
  background: #FFFFFF;
  align-self: center;
  min-width: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 11px;
  border: 1px solid #a4a4a4
`;

const ImgLink = styled.img`
  height: 28px;
  width: 28px;
  margin-right: 12px;
`;

const Link = styled.a`
  text-decoration: none;
  color: #000000;
`;

const Waiting = styled.img`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 12px;
`;

const TimeStamp = styled.div`
  position: absolute;
  bottom: 1px;
  right: 6px;
  opacity: 0.8;
  font-size: 0.65rem;
  color: ${props => props.theme.messageBox.message.timeStampColor};
`;

/*********** endregion */

export default connect(mapStateToProps)(WidgetMessageBox)
