import { combineReducers } from 'redux'
import { CLOSE_CHAT, SET_USER_ID, CUSTOMER_ONLINE_RESPONSE_RECEIVED } from '../actions/types'

const userId = (state = {}, {type, data}) => {
  switch (type) {
    case SET_USER_ID :
      return data
    case CLOSE_CHAT :
      return null
    case CUSTOMER_ONLINE_RESPONSE_RECEIVED:
      return data.userID
    default :
      return state
  }
}

export const getUserId = state => state.user.userId

export default combineReducers({
  userId
})
