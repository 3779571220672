const isPersonal = process && process.env.REACT_APP_IS_PERSONAL === 'true'
const isDevel = process && process.env.NODE_ENV === 'development'

const socketAddress = isPersonal
  ? 'https://opencommunicationchatpers.bytewise.it' // Non toccare
  : 'https://chatoc.consulcesi.com'

const socketPort = "443"

const attachmentsAuth = isPersonal
  ? 'https://opencommunicationpers.bytewise.it/chats/file/upload' // Non toccare
  : 'https://chatoc.consulcesi.com/chats/file/upload'

const attachmentsApi = isPersonal
  ? 'https://opencommunicationpers.bytewise.it/resources' // Non toccare
  : 'https://chatoc.consulcesi.com/resources'

const translationsUrl = 'https://chatoc.consulcesi.com/translations'

const publicUrlCustomer = 'https://chatoc.consulcesi.com'

const janusHost = isPersonal
  ? 'wss://opencommunicationpers.bytewise.it/januschat' // Non toccare
  : 'wss://chatoc.consulcesi.com/januschat'

const turnHost = 'turnconsrr.opencommunication.cloud:443?transport=tcp'
const turnUser = 'itsmtest'
const turnPassword = 'ItSM.Turn.T3st'

export const socket = {address : socketAddress, port: socketPort}
export const widget = {defaultTimer : 5}
export const attachments = { authUrl: attachmentsAuth, apiUrl: attachmentsApi }
export const translations = { hostname : translationsUrl }
export const publicUrl = isDevel ? '' : publicUrlCustomer
export const janus = { host : janusHost }
export const turn = { host : turnHost,  user : turnUser,  password : turnPassword }

export default {
  socket, widget, translations, publicUrl, janus, turn, attachments
}
