
export const handleOwnEvent = (events, tempID, timestamp) => {
  const index = events.findIndex(event => event.uuid === tempID)
  const event = Object.assign({}, events[index])
  event.received = true
  event.timestamp = timestamp
  const newEvents = [
    ...events.slice(0, index),
    event,
    ...events.slice(index + 1)
  ]
  return newEvents
}

export const clearlocalStorage = () => {
  localStorage.removeItem('departmentID')
  localStorage.removeItem('departmentName')
  localStorage.removeItem('chatID')
  localStorage.removeItem('userID')
  localStorage.removeItem('minimized')
  localStorage.removeItem('active')
  localStorage.removeItem('sessionUser')
  localStorage.removeItem('initialized')
  localStorage.removeItem('closed')
  localStorage.removeItem('proactiveChatStarted')
  localStorage.removeItem('privacy')
  localStorage.removeItem('page')
  localStorage.removeItem('status')
  localStorage.removeItem('chatStatus')
}

export const filllocalStorage = (departmentId, departmentName, chatId) => {
  localStorage.setItem('departmentID', departmentId)
  localStorage.setItem('departmentName', departmentName)
  localStorage.setItem('chatID', chatId)
}

export const clearForCorruptedResumedChat = () => {
  localStorage.removeItem('departmentID')
  localStorage.removeItem('departmentName')
  localStorage.removeItem('chatID')
  localStorage.removeItem('sessionUser')
  localStorage.removeItem('initialized')
  localStorage.removeItem('privacy')
}
