import React, { Component } from 'react'
import styled from 'styled-components'
import {ButtonAccept} from '../common'


class QuestionFreeText extends Component {

  state = {
    answer : ''
  }

  next = () => {
    this.props.nextQuestion(this.state.answer)
  }

  render() {
    return(
      <QuestionContainer>
        <Question>{this.props.question}</Question>
        <AnswerBox value={this.state.answer} onChange={(e) => this.setState({answer : (e.target.value)})}/>
        <ButtonAccept onClick={this.next}>Avanti</ButtonAccept>
      </QuestionContainer>
    )
  }
}

// region STYLE

const QuestionContainer = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  margin-top: 30px;
`

const Question = styled.div`
  opacity: 0.9;
`

const AnswerBox = styled.textarea`
  width: 100% !important;
  height: 150px !important;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
`

//endregion

export default QuestionFreeText