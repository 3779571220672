import * as at from './types'

export const updateSession = (user, department, mandatory) => ({type : at.UPDATE_SESSION, data: {user, department, mandatory}})

export const updateSessionUser = (user) => ({type : at.UPDATE_SESSION_USER, data: user})

export const setShowFullWidget = showFullWidget => {
  localStorage.setItem('active', showFullWidget)
  return {type : at.SET_SHOW_FULL_WIDGET, data : showFullWidget}
}

export const setIsMinimized = isMinimized => {
  localStorage.setItem('minimized', isMinimized)
  return {type : at.SET_IS_MINIMIZED, data : isMinimized}
}

export const setShowIntroWidget = showIntroWidget => ({type : at.SET_SHOW_INTRO_WIDGET, data : showIntroWidget})

export const setShowCircleWidget = showCircleWidget => ({type : at.SET_SHOW_CIRCLE_WIDGET, data : showCircleWidget})

export const setShowInformationForm = showInformationForm => ({type : at.SET_SHOW_INFORMATION_FORM, data : showInformationForm})

export const setShowPrivacyForm = showPrivacyForm => ({type : at.SET_SHOW_PRIVACY_FORM, data : showPrivacyForm})

export const addEvent = event => ({type : at.ADD_EVENT, data : event})

export const setOperatorWriting = operatorWriting => ({type : at.SET_OPERATOR_WRITING, data : operatorWriting})

export const setDepartmentPrivacyMessage = departmentPrivacyMessage => ({type: at.SET_DEPARTMENT_PRIVACY_MESSAGE, data : departmentPrivacyMessage})

export const departmentClosed = (openings) => ({type: at.DEPARTMENT_CLOSED, data: openings})

export const setShowVideoChatRequest = (showVideoChatRequest) => ({type: at.SET_SHOW_VIDEO_CHAT_REQUEST, data: showVideoChatRequest})

export const setShowAudioChatRequest = (showAudioChatRequest) => ({type: at.SET_SHOW_AUDIO_CHAT_REQUEST, data: showAudioChatRequest})

export const setShowCobrowseRequest = (showCobrowseRequest) => ({type: at.SET_SHOW_COBROWSE_REQUEST, data: showCobrowseRequest})

export const setShowVideoChat = (showVideoChat) => ({type : at.SET_SHOW_VIDEO_CHAT, data : showVideoChat})

export const setShowAudioChat = (showAudioChat) => ({type : at.SET_SHOW_AUDIO_CHAT, data : showAudioChat})

export const setShowCobrowse = showCobrowse => ({type : at.SET_SHOW_COBROWSE, data : showCobrowse})

export const setWebRtcPeer = webRtcPeer => ({type: at.SET_WEB_RTC_PEER, data: webRtcPeer})

export const setShowAttachmentBox = showAttachmentBox => ({type : at.SET_SHOW_ATTACHMENT_BOX, data : showAttachmentBox})

export const closeChat = () => ({type : at.CLOSE_CHAT, data: window['@user']})



/********** CHECKED ********/

export const setPage = (page) => ({type: at.SET_PAGE, data: page})

export const setStatus = (status) => ({type: at.SET_STATUS, data: status})

export const setChatStatus = status => ({type: at.SET_CHAT_STATUS, data: status})

export const setInitialized = (initialized) => ({type: at.SET_INITIALIZED, data: initialized})
