import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { publicUrl } from '../../configuration'
import imgSharing from '../../images/sharing.svg'
import { mediaRequestAccepted, closeMediaCall } from '../../actions/socketActions'
import { ButtonRefuse } from '../common'

const mapDispatchToProps = dispatch => ({
  mediaRequestAccepted : (accept, type) => dispatch(mediaRequestAccepted(accept, type)),
  closeMediaCall : () => dispatch(closeMediaCall())
})

class MediaCobrowse extends Component {

  componentDidMount() {
    this.props.mediaRequestAccepted(true, 'cobrowse')
  }

  closeMedia = () => {
    this.props.closeMediaCall()
  }

  render() {
    return(
      <>
        <CobrowseContainer>
          <CobrowseImageContainer>
            <CobrowseImage src={publicUrl + imgSharing} alt='sharing' />
          </CobrowseImageContainer>
          <TitleContainer>
            <Title>Screen Sharing</Title>
          </TitleContainer>
          <audio autoPlay id='audio-operator' />
          <ButtonContainer >
            <ButtonRefuse
              onClick={this.closeMedia}
              size={'medium'} >
              Termina Screen Sharing
            </ButtonRefuse>
          </ButtonContainer>
        </CobrowseContainer>
      </>
    )
  }
}

/********** region ~~~~~ STYLE ~~~~~ */

const CobrowseContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 10px 20px;
`

const CobrowseImageContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 0px 0px;
`

const TitleContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const CobrowseImage = styled.img `
  width: 55px;
  height: 55px;
`

const Title = styled.div `
  font-weight: bold;
  color: #000000;
  opacity: 0.7;
`

const ButtonContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 150px 0 0 0;
`

/********** endregion */

export default connect(null, mapDispatchToProps)(MediaCobrowse)

