import React from 'react'
import styled from 'styled-components'

const Input = ({ disabled, placeholder, value, onChange, keyInput, width }) => {

  return(
    <InformationFormInput
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      key={keyInput}
      width={width}
    />
  )
}

/********** region ~~~~~ STYLE ~~~~~ */

const InformationFormInput = styled.input `
    height: ${props => props.theme.information.input.height};
    padding: ${props => props.theme.information.input.padding};
    border-radius: ${props => props.theme.information.input.borderRadius};
    box-shadow: ${props => props.theme.information.input.boxShadow};
    border: ${props => props.theme.information.input.border};
    line-height: ${props => props.theme.information.input.lineHeight};
    margin: ${props => props.theme.information.input.margin};
    opacity: ${props => props.theme.information.input.opacity};
    width: ${props => props.width ? props.width : 'auto'};
    box-sizing: border-box;
`

/********** endregion */

export { Input }
