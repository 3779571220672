import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'
import {TranslationContext} from '../../helpers'
import {getProactiveChatStarted} from '../../reducers'
import {setPage, setChatStatus} from '../../actions/commonActions'
import {CHAT} from '../../constants/pages'
import {WAITING} from '../../constants/chatStatuses'


const mapStateToProps = state => ({
  proactiveChatStarted: getProactiveChatStarted(state)
})

const PrivacyMessageForm = ({departmentPrivacyMessage, acceptPrivacyForm, proactiveChatStarted, setPage, setChatStatus}) => {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.addEventListener('keypress', handleEnterKeyPress)
    if (proactiveChatStarted || !departmentPrivacyMessage) {
      setChatStatus(WAITING)
      setPage(CHAT)
    } else {
      setLoading(false)
    }
    return () => {
      window.removeEventListener('keypress', handleEnterKeyPress)
    }
  }, [])

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      acceptPrivacyForm()
    }
  }

  return (
    <TranslationContext.Consumer>
      {translation =>
        <>
          {loading && null}
          {!loading &&
          <>
            { departmentPrivacyMessage &&
              <PrivacyMessageText>
                <div dangerouslySetInnerHTML={{__html: departmentPrivacyMessage}}/>
              </PrivacyMessageText>
            }
            <ButtonContainer>
              <ButtonAccept onClick={acceptPrivacyForm}>
                {translation.privacy.button}
              </ButtonAccept>
            </ButtonContainer>
          </>
          }
        </>
      }
    </TranslationContext.Consumer>
  )
}

/* region STYLE */

const PrivacyMessageText = styled.div`
  overflow-y: auto;
  all: inherit;
  box-sizing: border-box;
  padding: ${props => props.theme.privacy.privacyBox.padding};
  height: ${props => props.theme.privacy.privacyBox.height};
  font-size: ${props => props.theme.privacy.privacyBox.fontSize};
  opacity: ${props => props.theme.privacy.privacyBox.opacity};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.theme.privacy.buttonAcceptContainer.height};;
`

const ButtonAccept = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.justifyContent};
  border-radius: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.borderRadius};
  background: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.background};
  color: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.color};
  font-size: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.fontSize};
  width: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.width};
  height: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.height}; 
  padding: ${props => props.theme.privacy.buttonAcceptContainer.buttonAccept.padding}; 
`

/* endregion */

export default connect(mapStateToProps, {setPage, setChatStatus})(PrivacyMessageForm)
