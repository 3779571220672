export const DEFAULT = 'default'
export const INFO = 'information'
export const DEPARTMENT_LIST = 'deplist'
export const DEPARTMENT_CLOSED = 'depclosed'
export const PRIVACY = 'privacy'
export const LOADING = 'loading'
export const CHAT = 'chat'
export const FEEDBACK = 'feedback'
export const TRANSCRIPTION = 'transcription'
export const ERROR = 'error'
