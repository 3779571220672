import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getSessionDepartment, getSessionMandatory, getSessionUser, getProactiveChatStarted, getInitialized } from '../../reducers'
import { setShowInformationForm, updateSession, setPage } from '../../actions/commonActions'
import { initSocket } from '../../actions/socketActions'
import InformationForm from './InformationForm'
import { DEPARTMENT_LIST } from '../../constants/pages'


const mapStateToProps = state => ({
  sessionUser : getSessionUser(state),
  sessionDepartment : getSessionDepartment(state),
  sessionMandatory : getSessionMandatory(state),
  proactiveChatStarted: getProactiveChatStarted(state),
  initialized: getInitialized(state)
})

const InformationFormContainer = ({sessionUser, sessionMandatory, setPage, updateSession, sessionDepartment, proactiveChatStarted, initialized}) => {

  const [user, setUser] = useState(Object.assign({}, sessionUser))
  const [showInfoBox, setShowInfoBox] = useState(false)
  const [fieldError, setFieldError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initialized && (proactiveChatStarted || checkAllFieldsAreSet())) {
      setPage(DEPARTMENT_LIST)
    } else {
      setLoading(false)
    }
  }, [])

  const handleChange = fieldName => event => {
    const changedUser = Object.assign({}, user)
    changedUser[fieldName] = event.target.value
    setUser(changedUser)
  }

  const checkMandatorySubmit = () => sessionMandatory.every(mandatoryField => !!user[mandatoryField] && user[mandatoryField].trim().length > 0)

  const checkAllFieldsAreSet = () => Object.values(user).every(field => !!field)

  const checkFieldFormat = () => {
    if (!(/^[a-zà-ú ']+$/i.test(user.name))) {
      return 'Formato nome non corretto (Deve contenere solo caratteri)'
    }
    if (!(/^[a-zà-ú ']+$/i.test(user.surname))) {
      return 'Formato cognome non corretto (Deve contenere solo caratteri)'
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!(re.test(String(user.email).toLowerCase()))) {
      return 'Formato mail non corretto.'
    }
    return false
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!checkMandatorySubmit()) {
      setShowInfoBox(true)
      return
    }
    const errorFieldFormatMessage = checkFieldFormat()
    if (errorFieldFormatMessage) {
      setShowInfoBox(true)
      setFieldError(errorFieldFormatMessage)
      return
    }
    localStorage.setItem('sessionUser', JSON.stringify(user))
    setShowInfoBox(false)
    updateSession(user, sessionDepartment, sessionMandatory)
    setPage(DEPARTMENT_LIST)
    //this.props.initSocket(window['@department'])
  }

  const isMandatory = field => sessionMandatory.includes(field)

  return (
    <>
      { loading && null}
      { !loading && <InformationForm
        user={user}
        oldUser={sessionUser}
        showInfoBox={showInfoBox}
        isMandatory={isMandatory}
        onChange={handleChange}
        onSubmit={handleSubmit}
        fieldError={fieldError}
      />}
    </>
  )
}

export default connect(mapStateToProps, {
  updateSession,
  setShowInformationForm,
  initSocket,
  setPage
})(InformationFormContainer)
