import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import mapStateToProps from '../../states/pages'
import { publicUrl } from '../../configuration'
import imgSpinner from '../../images/Spinner-1s-200px.svg'


const WidgetHeader = ({ imgHeader, title, subtitle }) => {

  return (
    <InformationFormHeaderStyled>
      <InformationImage src={imgHeader ? publicUrl + imgHeader : publicUrl + imgSpinner} />
      <InformationContainer>
        <InformationTitle hasSubtitle={subtitle}>{title}</InformationTitle>
        <InformationSubtitle>{subtitle}</InformationSubtitle>
      </InformationContainer>
    </InformationFormHeaderStyled>
  )

}

/* region STYLE */

const InformationFormHeaderStyled = styled.div `
  position: relative;
  display: flex;
  height: ${props => props.theme.layout.header.height};
  background: ${props => props.theme.layout.header.background};
`

const InformationImage = styled.img `
  width: ${props => props.theme.layout.header.avatar.width};
  margin: ${props => props.theme.layout.header.avatar.margin};
`

const InformationContainer = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-right: 15px;
  color: ${props => props.theme.layout.header.information.color};
`

const InformationTitle = styled.div `
  font-size: ${props => 
  props.hasSubtitle ? 
    props.theme.layout.header.information.title.fontSize :
    props.theme.layout.header.information.title.fontSizeOperator};
  font-weight: ${props => props.theme.layout.header.information.fontWeight};
  opacity: ${props => props.theme.layout.header.information.opacity};
  line-height: ${props => props.theme.layout.header.information.lineHeight};
`

const InformationSubtitle = styled.div `
  font-size: ${props => props.theme.layout.header.information.subtitle.fontSize};
  opacity: ${props => props.theme.layout.header.information.subtitle.opacity};
`

/* endregion */

export default connect(mapStateToProps)(WidgetHeader)
