import React, { Component } from 'react'
import styled from 'styled-components'
import { publicUrl } from '../configuration';
import imgPlugin from '../images/plugin.svg'
import imgClose from '../images/closeAlert.svg'

class AlertTemplate extends Component {
  render () {
    // the style contains only the margin given as offset
    // options contains all alert given options
    // message is the alert message...
    // close is a function that closes the alert
    const { message, close } = this.props

    return (
      <AlertContainer>
        <ImagePlugin src={publicUrl + imgPlugin} alt='plugin' />
        <Message>{message}</Message>
        <ImageClose src={publicUrl + imgClose} onClick={close} alt='close' />
      </AlertContainer>
    )
  }
}

/********** region STYLE */

const AlertContainer = styled.div`
  font-family: 'Open Sans',sans-serif !important;
  background: #133553;
  display: flex;
  align-items: center;
  margin-top: 20px;
  min-width: 300px;
  max-width: 1000px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 0px 47px -6px rgba(0,0,0,0.75);
`;

const Message = styled.div`
  flex: 1;
  margin: 0px 30px;
  color: #FFFFFF;
  opacity: 0.95;
  font-size: 0.95rem;
`;

const ImagePlugin = styled.img`
  width: 35px;
  height: 35px;
`;

const ImageClose = styled.img`
  width: 25px;
  height: 25px;
`;

/********** endregion */

export default AlertTemplate