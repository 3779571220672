import React from 'react'
import styled from 'styled-components'
import imgInfo from '../images/exlamationmark.svg'
import { publicUrl } from '../configuration'

const InfoBoxStyled = styled.div `
    background: ${props => props.theme.general.infoMessage.background};
    color: #FFFFFF;
    font-size: 0.85rem;
    height: 50px;
    position: absolute;
    display: flex;
    padding: 0px 15px;
    align-items: center;
    bottom: 0px;
    right: 15px;
    left: 15px;
    border-radius: 5px;
    
    & > img {
      width: 25px;
      margin-right: 15px;
    }
`

const InfoBox = (props) => {

  return (
    <InfoBoxStyled>
      <img src={publicUrl + imgInfo} alt='info-box'/>
      {props.info}
      </InfoBoxStyled>
  )
}

export default InfoBox